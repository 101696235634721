import TymberAPIConfig from "src/core/api/config";
import "axios-progress-bar/dist/nprogress.css";
import {version} from "src/core/version";
import TymberRouter from "src/core/common/tymberRouter";
import routes from "src/core/common/routes";
import TymberPathResolver from "src/core/common/pathResolver";
import ComponentsConfig from "src/core/common/components/config";
import * as ErrorTracking from "src/core/analytics/errorReporting";
import {GeolocationConfig} from "src/core/api/geolocation";
import {Deliveries} from "src/core/deliveries/api";
import deliveriesInterceptor from "src/core/deliveries/interceptor";
import productsInterceptor from "@menu/utils/interceptor";
import * as TymberAuthentication from "src/core/authentication/api";
import {Authentication} from "src/core/authentication";
import SEOConfig from "src/core/seo/config";
import Shop from "src/core/shops/shop";
import {init as initLogger} from "src/core/common/logger";
import {FeatureToggles} from "src/core/common/featureToggles";
import "react-loading-skeleton/dist/skeleton.css";
import {isClient} from "src/server/utils/isClient";
import {getThemeConfig} from "src/themes/utils/utils";

isClient && initLogger();

export default class TymberApp {
  init(env) {
    console.log(version);

    this.env = env;

    routes.setPrefix("/:shop");

    this.initRouter(env);

    TymberAPIConfig.addInitListener(() => {
      this.addDeliveriesInterceptor();
      this.addProductsInterceptor();
    });
    TymberAPIConfig.init({
      storeId: env.getEcommerceId(),
      kioskId: env.getKioskId(),
      baseUrl: env.getTymberEcommerceHost(),
      authorization: TymberAPIConfig.getAuthorizationToken(),
      router: this.router,
      fcm: {
        webKey: env.getFcmWebKey(),
        apiKey: env.getFcmApiKey(),
        projectId: env.getFcmProjectId(),
        messagingSenderId: env.getFcmMessagingSenderId(),
        appId: env.getFcmAppId(),
        active: env.getFcmActive(),
      },
      ordersEndpointV5: env.getOrdersEndpointV5(),
    });

    SEOConfig.init({
      router: this.router,
    });
    ComponentsConfig.init({
      router: this.router,
    });

    GeolocationConfig.setConfig({
      deliveryAddressSearchBBox: env.getDeliveryAddressSearchBBox(),
      deliveryAddressSearchProximity: env.getDeliveryAddressSearchProximity(),
      mapBoxAccessToken: env.getMapBoxAccessToken(),
      country: env.getDefaultCountry(),
    });

    Shop.init();

    this.initialized = true;
  }
  initClient(site) {
    const {env} = site;

    TymberAPIConfig.setAppMode(this.getAppModeHeader(FeatureToggles.getInstance(site)));

    routes.loadPageOptions(getThemeConfig(site.getBaseTheme()?.name)?.pageOptions);
    this.initRouter(env);

    Deliveries.init(this.router, site).then(() => {
      TymberAuthentication.init(env, this.router, FeatureToggles.getInstance(site));

      import("src/core/checkout/api").then(mod => {
        mod.init(env, Deliveries, Authentication, this.router);
      });

      const releaseLevel = env.getReleaseLevel();
      ErrorTracking.init({apiKey: env.getSentryDsn(), releaseLevel});
    });

    void this.loadIntegrations(site.getOptions());
    this.initializedClient = true;
  }

  async loadIntegrations(siteOptions) {
    const {default: IntegrationsLoader} = await import(
      "src/integrations/IntegrationLoader"
    );
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => {
        void IntegrationsLoader.init(siteOptions);
      });
    } else {
      setTimeout(() => {
        void IntegrationsLoader.init(siteOptions);
      }, 0);
    }
  }

  initRouter(env) {
    const basePath = env.getBasePath();
    if (!this.router) {
      this.router = new TymberRouter(
        routes,
        new TymberPathResolver(routes, this.getGlobalParams(), basePath),
        basePath
      );
    }
  }
  addDeliveriesInterceptor() {
    if (this.removeDeliveriesInterceptor) this.removeDeliveriesInterceptor();
    this.removeDeliveriesInterceptor =
      TymberAPIConfig.addInterceptor(deliveriesInterceptor);
  }
  addProductsInterceptor() {
    if (this.removeProductsInterceptor) this.removeProductsInterceptor();
    this.removeProductsInterceptor = TymberAPIConfig.addInterceptor(productsInterceptor);
  }

  getGlobalParams = () => {
    const defaults = ["mkt_source", "promo"];
    if (!isClient) {
      return defaults;
    } else {
      const params = new URLSearchParams(window.location.search);

      const utmParams = [...Array.from(params.keys())].filter(
        k => typeof k === "string" && k.startsWith("utm")
      );

      return [...utmParams, ...defaults];
    }
  };

  getAppModeHeader(featureToggles) {
    if (featureToggles.getInStoreExperienceEventFlow()) return "in-store-experience";
    if (featureToggles.deliveriesOnly()) return "omni-delivery";
    if (featureToggles.kioskMode()) return "kiosk";
    if (featureToggles.pickupOnly()) return "pickup";
    return "default";
  }
}
