import React from "react";
import styled, {useTheme} from "styled-components";
import {ProductInfo} from "@ui";
import {PageHead} from "src/core/seo/components/Head";
import EmptyPlaceholderImage from "src/core/common/assets/empty-placeholder.png";
import BrandRecommendations from "@menu/components/BrandRecommendations";
import Separator from "src/core/common/components/elements/Separator";
import media from "src/themes/utils/media";
import Link from "src/core/common/components/modules/Link";
import styles, {
  separatorStyles,
} from "src/themes/baseTheme/components/ProductDetail/styles";
import ProductDetailSchema from "src/core/seo/components/ProductDetailSchema";
import useSite from "src/core/sites/hooks/useSite";
import {getFlowerTypeIndicatorProps} from "src/themes/colorful/elements/flowerType";
import useProductDetail from "src/core/common/hooks/useProductDetail";

function ColorFulProductDetail({productId, onAddItem}) {
  const {
    product,
    meta,
    onEmptyPlaceholderButtonClickHandler,
    showSkeleton,
    showProductDetailRecommendations,
    quantityPickerProps,
    productInfoProps,
  } = useProductDetail({productId, onAddItem});

  const theme = useTheme();
  const site = useSite();

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site, theme);

  const colorfulProductInfoProps = {
    ...productInfoProps,
    mainTags: {
      ...productInfoProps.mainTags,
      flowerTypeIndicatorProps,
    },
  };

  return (
    <>
      <Container style={{height: meta.error ? "100%" : "auto"}}>
        {!product && meta.error && meta.error.status === 404 && (
          <ProductInfo
            hasEmptyPlaceholder
            emptyPlaceholderProps={{
              onEmptyPlaceholderButtonClickHandler,
              emptyPlaceholderImage: EmptyPlaceholderImage,
            }}
          />
        )}
        {showSkeleton && <ProductInfo isSkeleton />}
        {product && (
          <>
            <PageHead
              priority={1}
              title={product.getName()}
              description={product.getDescription()}
              image={product.getMainImage()}
              schemas={<ProductDetailSchema product={product} />}
            />
            <ProductInfo
              styles={styles(theme, site)}
              LinkComponent={Link}
              quantityPicker={quantityPickerProps}
              {...colorfulProductInfoProps}
            />
          </>
        )}
      </Container>
      {showProductDetailRecommendations && product && product.getBrandName() && (
        <>
          <CustomSeparator styles={separatorStyles(theme, site)} />
          <BrandRecommendationsContainer>
            <BrandRecommendations product={product} />
          </BrandRecommendationsContainer>
        </>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const BrandRecommendationsContainer = styled.div`
  ${media.up("lg")} {
    margin-top: 24px;
  }

  ${media.down("md")} {
    padding-bottom: 130px;
  }
`;

const CustomSeparator = styled(Separator)`
  background-color: ${({styles}) => styles.backgroundColor};
  width: 100%;
  height: 2px;
  max-height: 2px;
`;

export default ColorFulProductDetail;
