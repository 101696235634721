import React from "react";
import merge from "lodash/merge";
import {ProductCard as ProductCardUI, CartIconActions} from "@ui";
import productCardStyles from "@themes/default/components/ProductCard/styles.js";
import useTheme from "@mock/hooks/useTheme.js";
import useProductCard from "@mock/hooks/useProductCard.js";
import {getFlowerTypeIndicatorProps} from "@themes/default/themeStyles/flowerType";

export default function ProductCard(props) {
  const {site, actionProps, cartProps, flowerTypeIndicatorProps, ...productCardProps} =
    useProductCard(props);
  const {product, ...otherProps} = props;
  const defaultFlowerTypeIndicatorProps = getFlowerTypeIndicatorProps(
    site.getUiConfiguration?.()
  );
  const flowerTypeIndicator = merge(
    {},
    flowerTypeIndicatorProps,
    defaultFlowerTypeIndicatorProps
  );
  const theme = useTheme();

  return (
    <ProductCardUI
      potencyTagsPosition="top"
      displayShadow={true}
      {...otherProps}
      {...productCardProps}
      styles={productCardStyles(theme, {mode: props.mode}, site)}
      flowerTypeIndicatorProps={flowerTypeIndicator}
      ActionsComponent={props => <CartIconActions {...props} {...actionProps} />}
    />
  );
}
