import {useRouter} from "next/router";
import {useEffect} from "react";
import {validateKiosk} from "src/apps/kiosk/utils";
import {isClient} from "src/server/utils/isClient";

export function useKioskValidation(env) {
  const {shop: kioskId} = useRouter().query;

  const id = sanitizeKioskId(kioskId);
  const kioskSlug = getKioskSlug(kioskId);
  useEffect(() => {
    if (id) {
      validateKiosk({
        basePath: env.getTymberEcommerceHost(),
        kioskId: id,
        storeId: env.getEcommerceId(),
      }).catch(() => {
        window.location.href = "/?retry=true";
      });
    }
  }, [env, id]);

  return {kioskId: id, kioskSlug: kioskSlug};
}

function sanitizeKioskId(path) {
  let _path = getKioskSlug(path);
  return (_path || "")
    .split("-")
    .filter(part => part !== "")
    .slice(-1)[0];
}

function getKioskSlug(path) {
  let _path = path;
  if (!_path && isClient) {
    _path = window.location.pathname
      .split("/")
      .filter(part => part !== "")
      .slice(0, 1)[0];
  }
  return _path;
}
