import {ActionTypes} from "@menu/state/actions";

const initialGroupState = {
  data: [],
  loading: false,
  error: null,
  type: null,
  slug: null,
  hasMore: true,
  showSkeleton: true,
  totalCount: null,
  lastFilters: null,
};

export default function reducer(state = initialGroupState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_PRODUCTS:
      const reset =
        !sameFilters(state.lastFilters, action.payload.filters) ||
        action.payload.type !== state.type ||
        action.payload.slug !== state.slug;

      return {
        type: action.payload.type,
        slug: action.payload.slug,
        error: null,
        data: reset ? [] : state.data,
        hasMore: true,
        totalCount: reset || state.data.length === 0 ? null : state.totalCount,
        showSkeleton: state.data.length === 0 || reset,
        loading: true,
        lastFilters: action.payload.filters,
      };
    case ActionTypes.REQUEST_PRODUCTS_SUCCESSFUL:
      const totalCount = action.payload.data.getTotalCount();
      const newData = [...state.data, ...action.payload.data.getElements()];
      return {
        ...state,
        loading: false,
        error: null,
        hasMore: newData.length < totalCount,
        totalCount: totalCount,
        showSkeleton: false,
        data: newData,
      };
    case ActionTypes.REQUEST_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
}

const sameFilters = (filters1, filters2) => {
  return filters1?.toString() === filters2?.toString();
};
