import Page from "src/core/common/models/page";
import PaymentOption from "src/core/common/models/paymentOption";
import MarketingSource from "src/core/common/models/marketingSource";
import Cache from "src/core/common/cache";
import dayjs from "dayjs";
import DeliverySlot from "src/core/common/models/deliverySlot";
import DeliverySlotGroup from "src/core/common/models/deliverySlotGroup";
import ProductBrand from "src/core/common/models/productBrand";
import {PromotionalBanners} from "src/core/common/models/promotionalBanners";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import makeSocialLink from "src/core/common/models/socials";
import makePages from "src/core/common/models/pages";
import {deserialise} from "kitsu-core";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import ProductFiltersCatalog from "src/core/common/models/productFiltersCatalog";
import get from "lodash/get";
import {SideMenuItem} from "src/core/common/components/collections/menu/sideMenuItems";

export const makePage = (responseData, factory) =>
  new Page({
    meta: responseData.meta,
    objects: responseData.data.map(element => factory(element, responseData)),
  });

export const makePaymentOptions = attrs => new PaymentOption(attrs);

export const makeMarketingSources = attrs => new MarketingSource(attrs);

export const makeBrand = ({data}) => new ProductBrand(data);

export const makePromotionalBanners = attrs => new PromotionalBanners(attrs);

export const makeProduct = response =>
  new Product(populateRelations(response.data, response.included));

export const makeSocials = response => {
  return makeSocialLink(response.data);
};

export const makePagesResponse = response => {
  const pagesData = deserialise(response).data;
  return makePages(pagesData);
};

class WPSideMenuItem extends SideMenuItem {
  get label() {
    return this.obj.title;
  }

  get linkTo() {
    return this.obj.url;
  }

  get external() {
    return true;
  }

  get requiresAuthentication() {
    return false;
  }

  get requiresDelivery() {
    return false;
  }
}

export const makeAboutItems = response => {
  const cache = new Cache(90000000);
  const data = response.data.map(d => new WPSideMenuItem(d));
  cache.put("", data);
  return data;
};

export const getParams = pageSize => {
  const now = dayjs();
  return {
    "filter[since]": now.format("YYYY-MM-DD"),
    "filter[has_available_slots]": true,
    "page[size]": pageSize,
  };
};

export const makeDeliverySlotGroups = ({data}) => {
  return data.map(obj => {
    const {slots, date} = obj.attributes;
    const deliverySlots = slots.map(s => {
      const end_time = `${date}T${s.end_time}`;
      const start_time = `${date}T${s.start_time}`;
      return new DeliverySlot({...s, start_time, end_time});
    });
    return new DeliverySlotGroup(date, deliverySlots);
  });
};

export const parseFilters = filters => {
  return filters && filters.serialize ? filters.serialize() : filters || {};
};

export const makeKeyValueMap = (keyFields, props) => {
  return keyFields.reduce(
    (acc, key) => ({
      ...acc,
      [key]: get(props, key, undefined),
    }),
    {}
  );
};

export const getFiltersParams = ({
  limit,
  offset,
  order,
  showcased,
  filters,
  delivery_type,
  zip_code,
}) => {
  const parsedFilters = parseFilters(filters);
  return pickBy(
    {
      limit,
      offset,
      order,
      showcased,
      delivery_type,
      zip_code,
      ...parsedFilters,
    },
    identity
  );
};

export const getProductsListParams = ({limit, offset, order, delivery_type, filters}) => {
  const parsedFilters = parseFilters(filters);
  return pickBy({limit, offset, order, delivery_type, ...parsedFilters}, identity);
};

export const getRecommendedProductsListParams = ({limit, offset, filters}) => {
  const parsedFilters = parseFilters(filters);
  return {limit, offset, ...parsedFilters};
};

export const getOrdersListParams = ({limit, offset, order, filters}) => {
  const parsedFilters = parseFilters(filters);
  return {limit, offset, order, ...parsedFilters};
};

export const makeFiltersCatalog = data => new ProductFiltersCatalog(data);
