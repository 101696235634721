import React from "react";
import styled, {useTheme} from "styled-components";
import ReactModal from "react-modal";
import {addAlpha} from "src/themes/utils/utils";
import media from "src/themes/utils/media";
import {modalStyles} from "@themes/default/components/ProductDetail/styles";
import useSite from "src/core/sites/hooks/useSite";

function ReactModalAdapter({className, ...props}) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
}

ReactModal.setAppElement("#__next");

function withThemeOverride(Component) {
  return props => {
    const theme = useTheme();
    const site = useSite();

    const styles = modalStyles(theme, site);

    return <Component {...props} styles={styles} />; // to avoid with theme error, t property was used instead
  };
}

export const BaseThemeModal = withThemeOverride(styled(ReactModalAdapter)`
  visibility: ${({hidden}) => (hidden ? "hidden" : "visible")};
  &__content {
    position: absolute;
    max-width: 85%;
    max-height: 100%;
    top: ${({hidden}) => (hidden ? "-1000000px" : "50%")};
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    padding: 20px;
    outline: none;

    border: 0;
    z-index: ${({hidden}) => (hidden ? -1 : 11)};
    background: #fff;
    border-radius: ${({styles}) => styles.content.borderRadius.lg};
    display: flex;
    justify-content: center;

    ${media.down("md")} {
      max-width: 100%;
      position: absolute;
      top: ${({hidden}) => (hidden ? "-1000000px" : "70px")};
      left: 0;
      right: 0;
      bottom: 0;
      transform: translate(0, 0);
      margin-right: 0;
      border-radius: ${({styles}) => styles.content.borderRadius.md};
    }

    ${media.down("sm")} {
      padding: 20px 8px;
    }
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 5px solid #fff;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #f4f4f4;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
  &__overlay {
    position: fixed;
    top: ${({hidden}) => (hidden ? "-1000000px" : 0)};
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({styles}) =>
      styles.overlay?.color
        ? addAlpha(styles.overlay.color, 0.75)
        : "rgba(0, 0, 0, 0.75)"};
    z-index: ${({hidden}) => (hidden ? -1 : 10)};
  }
`);
