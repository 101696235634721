import MedLeafBasePage from "src/themes/medleaf/pages/BasePage";
import MedLeafBrandPage from "src/themes/medleaf/pages/BrandPage";
import MedLeafDealsPage from "src/themes/medleaf/pages/DealsPage";
import MedLeafProductsPage from "src/themes/medleaf/pages/ProductsPage";
import MedLeafHeader from "src/themes/medleaf/components/Header";
import MedLeafDeliveryBanner from "src/themes/medleaf/components/DeliveryBanner";
import MedLeafPromotionalBanner from "src/themes/medleaf/components/PromotionalBanner";
import MedLeafCategoryBar from "src/themes/medleaf/components/CategoryBar";
import MedLeafCategorizedProductsBrowser from "src/themes/medleaf/components/CategorizedProductsBrowser";
import MedLeafViewAllLink from "src/themes/medleaf/elements/ViewAllLink";
import MedLeafProductCard from "src/themes/medleaf/components/ProductCard";
import MedLeafBrandHeader from "src/themes/medleaf/elements/BrandHeader/BrandHeader";
import MedLeafProductDetail from "src/themes/medleaf/components/ProductDetail";

export default {
  inherits: "default",
  vars: {
    SHOWCASED_PREFETCH_LIMIT: 6,
  },
  components: {
    Header: MedLeafHeader,
    CategorizedProductsBrowser: MedLeafCategorizedProductsBrowser,
    PromotionalBanner: MedLeafPromotionalBanner,
    CategoryBar: MedLeafCategoryBar,
    DeliveryBanner: MedLeafDeliveryBanner,
    ViewAllLink: MedLeafViewAllLink,
    ProductCard: MedLeafProductCard,
    BrandHeader: MedLeafBrandHeader,
    ProductDetail: MedLeafProductDetail,
  },
  pages: {
    BrandPage: MedLeafBrandPage,
    BasePage: MedLeafBasePage,
    ProductsPage: MedLeafProductsPage,
    ProductDetailPage: MedLeafProductsPage,
    DealsPage: MedLeafDealsPage,
    HomePage: MedLeafProductsPage,
    AllCategoriesPage: MedLeafProductsPage,
    SearchPage: MedLeafProductsPage,
  },
  options: {
    pages: {
      productDetail: {
        isModal: true,
      },
    },
  },
};
