import EmptyPlaceholderImage from "src/core/common/assets/empty-placeholder.png";
import React from "react";
import styled from "styled-components";
import FilledButton from "../button/FilledButton";

function EmptyPlaceholder({title, text, buttonText, action, imageUrl}) {
  return (
    <EmptyPlaceholderContainer data-cy="noBrandsBanner">
      <img src={imageUrl || EmptyPlaceholderImage} alt={""} />
      <h1>{title}</h1>
      {text && <h2>{text}</h2>}
      {action && (
        <FilledButton
          onClick={action}
          data-cy="goToOtherBrandsButton"
          label={buttonText}
        />
      )}
    </EmptyPlaceholderContainer>
  );
}

const EmptyPlaceholderContainer = styled.div`
  box-sizing: border-box;
  padding: ${({theme}) => theme.v2.spacing(4)};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  > *:not(:last-child) {
    margin-bottom: 20px;
  }
  > h1 {
    margin-top: 0;
    font-size: ${({theme}) => theme.v2.typography.sizing["3xl"].lg};
    font-weight: ${({theme}) => theme.v2.typography.titles.weight.light};
  }
  > h2 {
    margin-top: 0;
    font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
    font-weight: ${({theme}) => theme.v2.typography.titles.weight.light};
  }
  > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
  > div {
    width: 50%;
  }
`;

export default EmptyPlaceholder;
