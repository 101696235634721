import React from "react";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import styled from "styled-components";
import {SimpleProductsList} from "@ui";
import useTheme from "@mock/hooks/useTheme.js";
import recommendedProductsListStyles from "@themes/default/components/RecommendedProductsList/styles";

export default function RecommendedProductsList({
  site,
  products,
  meta,
  title,
  linkToAll,
}) {
  const {ProductCard} = useThemeComponents();
  const theme = useTheme();
  const limit = {
    lg: 4,
    md: 4,
    sm: 4,
  };

  return (
    <div>
      {(meta.loading || products.length) > 0 && (
        <div>
          <CustomListHeader
            title={title}
            linkToAll={linkToAll}
            styles={recommendedProductsListStyles(theme, site)}
          />
          <SimpleProductsList
            styles={recommendedProductsListStyles(theme, site)}
            maxProducts={limit}
            products={products}
            skeleton={meta.loading}
            ProductCardComponent={ProductCard}
          />
        </div>
      )}
    </div>
  );
}

function ListHeader({title, linkToAll, styles}) {
  const {ViewAllLink} = useThemeComponents();
  return (
    <>
      <HeaderContainer>
        <TitleContainer styles={styles.header}>
          <Title styles={styles.header}>{title}</Title>
        </TitleContainer>

        {linkToAll && (
          <ViewAllLink
            to={linkToAll}
            defaultUnderlined={false}
            color={styles.header.viewAllColor}
          />
        )}
      </HeaderContainer>
    </>
  );
}

let CustomListHeader = styled(ListHeader)``;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

let TitleContainer = styled.div`
  background-color: ${({styles}) => styles.backgroundColor};
  padding: ${({styles}) => styles.padding || "16px"};
  transform: skewX(${({styles}) => `${Number(styles.skewness) * -1}deg`});

  > * {
    color: ${({styles}) => styles.color};
    font-weight: ${({styles}) => styles.fontWeight};
    font-size: ${({styles}) => styles.fontSize};
    transform: skewX(${({styles}) => `${styles.skewness}deg`});
  }
`;

const Title = styled.h3`
  font-family: ${({styles}) => styles.fontFamily};
`;
