import React from "react";
import BasePage from "./BasePage";
import {DealsList} from "@ui";
import styled, {useTheme} from "styled-components";
import Title from "src/core/common/components/modules/Title";
import CategoryBar from "@menu/components/CategoryBar";
import merge from "lodash/merge";
import get from "lodash/get";

export default function SenseDealsPage({rewards, meta, siteName, ...props}) {
  const _theme = useTheme();

  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.showcasedProductsList)};
  const descriptionTheme = {
    v2: merge({}, theme.v2, get(theme, "v2.overrides.showcasedProductsList.text")),
  };
  const titleTheme = {
    v2: merge({}, theme.v2, get(theme, "v2.overrides.showcasedProductsList.title")),
  };

  const TitleComponent = () => {
    return <span>Awaiting offers</span>;
  };

  const TextComponent = () => {
    return (
      <>
        <span>No deals are available at the moment!</span>
        <span>Please come back another day for great deals.</span>
      </>
    );
  };

  return (
    <BasePage {...props}>
      <CategoryBar />
      <Container>
        <DealsList
          styles={CardStyles(titleTheme, descriptionTheme)}
          skeleton={meta.loading && rewards.length === 0}
          deals={rewards}
          noResultsPlaceholderProps={{
            styles: getNoResultsStyles(titleTheme, descriptionTheme),
            TitleComponent: TitleComponent,
            TextComponent: TextComponent,
          }}
          headerProps={{
            TitleComponent: Title,
            styles: headerStyles(titleTheme, descriptionTheme),
            title: `${siteName} Deals For Everyone`,
            description:
              "Check out all of our current cannabis deals below and find a robust offering of deals and rewards is the best way to say thanks for your loyal support.",
          }}
        />
      </Container>
    </BasePage>
  );
}

const CardStyles = (titleTheme, descriptionTheme) => ({
  card: {
    root: {
      borderRadius: {
        lg: "0px",
        md: "0px",
        sm: "0px",
      },
    },
    title: {
      fontFamily: titleTheme.v2.typography.title1.family,
      color: descriptionTheme.v2.color.onBackground,
      fontWeight: titleTheme.v2.typography.title1.weight,
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
    },
    description: {
      fontFamily: descriptionTheme.v2.typography.title5.family,
      color: descriptionTheme.v2.color.onBackground,
      fontWeight: descriptionTheme.v2.typography.title5.weight,
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
    },
    required: {
      fontFamily: descriptionTheme.v2.typography.title5.family,
      color: "#6B6C6F",
      fontWeight: descriptionTheme.v2.typography.title5.weight,
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
    },
    discount: {
      fontFamily: descriptionTheme.v2.typography.title5.family,
      color: descriptionTheme.v2.color.onBackground,
      fontWeight: descriptionTheme.v2.typography.title5.weight,
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
    },
    source: {
      fontFamily: descriptionTheme.v2.typography.title5.family,
      color: "#828282",
      fontWeight: descriptionTheme.v2.typography.title5.weight,
      fontSize: {
        lg: "12px",
        md: "12px",
        sm: "12px",
      },
    },
  },
});

const getNoResultsStyles = (titleTheme, descriptionTheme) => ({
  logo: {
    backgroundColor: "#fff",
    color: titleTheme.v2.color.onBackground,
    borderRadius: "0px",
  },
  titleText: {
    fontFamily: titleTheme.v2.typography.title1.family,
    color: descriptionTheme.v2.color.onBackground,
    fontWeight: titleTheme.v2.typography.title1.weight,
    fontSize: {
      lg: "20px",
      md: "20px",
      sm: "20px",
    },
  },
  detailText: {
    fontFamily: descriptionTheme.v2.typography.title5.family,
    color: descriptionTheme.v2.color.onBackground,
    fontWeight: descriptionTheme.v2.typography.title5.weight,
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "12px",
    },
  },
});

const headerStyles = (titleTheme, descriptionTheme) => ({
  title: {
    fontFamily: titleTheme.v2.typography.title1.family,
    color: titleTheme.v2.color.onBackground,
    fontWeight: titleTheme.v2.typography.title1.weight,
    fontSize: {
      lg: titleTheme.v2.typography.title1.size.lg,
      md: titleTheme.v2.typography.title1.size.md,
      sm: titleTheme.v2.typography.title1.size.md,
    },
  },
  description: {
    root: {
      fontFamily: descriptionTheme.v2.typography.title5.family,
      fontSize: {
        lg: descriptionTheme.v2.typography.title5.size.lg,
        md: descriptionTheme.v2.typography.title5.size.md,
        sm: descriptionTheme.v2.typography.title5.size.md,
      },
    },
    text: {
      color: descriptionTheme.v2.color.onBackground,
      fontWeight: descriptionTheme.v2.typography.title5.weight,
    },
  },
});

const Container = styled.div`
  margin-top: ${({theme}) => theme.v2.spacing(4)};
`;
