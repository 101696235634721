import merge from "lodash/merge";

export default Clazz => {
  Object.assign(Clazz.prototype, themeProps);

  return Clazz;
};

const themeProps = {
  getInStoreProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.inStore)};

    return {
      root: {
        backgroundColor: theme.v2.color.primary,
        fontFamily: theme.v2.typography.bodyText2.family,
        color: theme.v2.color.onPrimary,
        mainTextFontSize: theme.v2.typography.bodyText1.size,
        secondaryTextFontSize: theme.v2.typography.bodyText2.size,
        mainTextFontWeight: theme.v2.typography.bodyText1.weight,
        secondaryTextFontWeight: theme.v2.typography.bodyText2.weight,
        mainTextColor: theme.v2.color.base.grey["900"],
        secondaryTextColor: theme.v2.color.base.grey["700"],
        titleFontSize: theme.v2.typography.title4.size,
        titleFontWeight: theme.v2.typography.title4.weight,
        subtitleFontSize: theme.v2.typography.bodyText1.size,
        subtitleFontWeight: theme.v2.typography.bodyText1.weight,
      },
      button: {
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onPrimary,
        borderRadius: theme.v2.decoration.border.radius.default,
        fontWeight: theme.v2.typography.title5.weight,
        root: {
          minWidth: "50px",
        },
      },
      menuButton: {
        backgroundColor: theme.v2.color.surface,
        color: theme.v2.color.base.grey["800"],
      },
    };
  },
};
