import React from "react";
import {PageHead} from "src/core/seo/components/Head";
import BrandDetailSchema from "src/core/seo/components/BrandDetailSchema";
import useSite from "src/core/sites/hooks/useSite";

function SeoHead({category, brand}) {
  const site = useSite();

  if (category) {
    const categoryTitleTemplate = site.getOptions().getCategoryTitleTemplate();
    return (
      <PageHead
        title={category.getName()}
        description={category.getDescription()}
        titleTemplate={categoryTitleTemplate}
      />
    );
  }

  if (brand) {
    const brandTitleTemplate = site.getOptions().getBrandTitleTemplate();
    return (
      <PageHead
        title={brand.getName()}
        description={brand.getDescription()}
        image={brand.getLogo()}
        titleTemplate={brandTitleTemplate}
        schemas={<BrandDetailSchema brand={brand} />}
      />
    );
  }

  return <PageHead />;
}

export default SeoHead;
