import React from "react";
import styled from "styled-components";
import Link from "src/core/common/components/modules/Link";
import {ChevronRight} from "@styled-icons/boxicons-solid/ChevronRight";
import useIsDesktop from "src/core/common/hooks/useIsDesktop";

export default function MedLeafViewAllLink({
  to,
  keepQuery,
  accessibilityIdentifier,
  ...props
}) {
  const isDesktop = useIsDesktop();

  return (
    <CustomLink
      aria-label={`See all ${
        to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
      } products`}
      to={to}
      keepQuery={keepQuery}
      color={props.color}
      className={props.className}
      underlined={
        props.underlined !== undefined ? props.underlined : props.defaultUnderlined
      }
    >
      <ViewAllText color={props.color}>
        <span>{isDesktop ? "See All" : ""}</span>
        <ChevronRightIcon
          color={props.color}
          role="img"
          title="See all"
          aria-label={`See all ${
            to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
          } products`}
        />
      </ViewAllText>
    </CustomLink>
  );
}

const CustomLink = styled(Link)`
  text-decoration: ${({underlined}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;

const ViewAllText = styled.span`
  display: flex;
  gap: 5px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  align-items: center;
  white-space: nowrap;
`;

const ChevronRightIcon = styled(ChevronRight)`
  color: white;
  background-color: black;
  border-radius: 20px;
  height: 20px;
  width: 20px;
`;
