import {useMemo} from "react";
import makePages from "src/core/common/models/pages";
import {usePagesResource} from "src/state/hooks/useAsyncResource";

function usePages() {
  const {data: shopPages} = usePagesResource();

  return useMemo(() => {
    if (!shopPages) return makePages([]);
    return shopPages;
  }, [shopPages]);
}

export default usePages;
