import React from 'react';
import PropTypes from 'prop-types';

export default function DealsLogo({ color }) {
  return (
    <svg
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.8768 22.7503C33.5743 22.7503 35.7518 24.9278 35.7518 27.6253C35.7518 30.3228 33.5743 32.5003 30.8768 32.5003C28.1793 32.5003 26.0018 30.3228 26.0018 27.6253C26.0018 24.9278 28.1793 22.7503 30.8768 22.7503ZM47.1268 45.5003C49.8243 45.5003 52.0018 47.6778 52.0018 50.3753C52.0018 53.0728 49.8243 55.2503 47.1268 55.2503C44.4293 55.2503 42.2518 53.0728 42.2518 50.3753C42.2518 47.6778 44.4293 45.5003 47.1268 45.5003ZM27.3343 55.2503L22.7518 50.6678L50.6693 22.7503L55.2518 27.3328L27.3343 55.2503ZM60.6143 9.26278L62.5968 21.8078L74.0043 27.6253L68.2518 39.0003L74.0368 50.3753L62.5318 56.1928L60.5493 68.7378L47.9068 66.7553L38.9043 75.7578L29.8693 66.6253L17.3243 68.7053L15.3093 56.0628L3.9668 50.2778L9.7518 38.9028L3.9993 27.6253L15.4068 21.7428L17.3893 9.32778L29.9668 11.3753L39.0018 2.25903L47.9718 11.2453L60.6143 9.26278ZM65.3268 30.4528L56.8768 26.0003L55.2518 16.6078L45.8268 17.9728L39.0018 11.3753L32.1768 17.9728L22.7518 16.6078L21.1268 26.0003L12.6768 30.4528L16.9018 39.0003L12.6768 47.5478L21.1268 52.0003L22.7518 61.3928L32.1768 60.0278L39.0018 66.6253L45.8268 60.0278L55.2518 61.3928L56.8768 52.0003L65.3268 47.5478L61.1018 39.0003L65.3268 30.4528Z"
        fill={color}
      />
    </svg>
  );
}

DealsLogo.propTypes = {
  color: PropTypes.string
};
