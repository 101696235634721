import React from "react";
import BaseProductsBrowser from "@menu/components/BaseProductsBrowser";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import AppliedFiltersSection from "src/themes/baseTheme/elements/filters/AppliedFiltersSection";
import ActionsSortFilter from "../../elements/filters/ActionsSortFilter";
import SearchProductsList from "@menu/components/SearchProductsList";

const ShowCaseProductsList = dynamic(() =>
  import("@menu/components/ShowCaseProductsList").catch(
    logModuleLoadError("ShowCaseProductsList", {critical: true})
  )
);
const ProductsList = dynamic(() =>
  import("@menu/components/ProductsList").catch(
    logModuleLoadError("ProductsList", {critical: true})
  )
);

export default function BaseThemeCategorizedProductsBrowser({
  hideFilters,
  showCaseIfNoCategory = true,
  title = null,
}) {
  return (
    <BaseProductsBrowser
      render={({category, subcategories, filters, onListUpdate}) => {
        const hasFilters = filters.hasFilters();

        return (
          <>
            {!hideFilters && hasFilters && <AppliedFiltersSection filters={filters} />}

            {showCaseIfNoCategory &&
              !filters.search &&
              !filters.category &&
              !category && (
                <div>
                  {title}
                  {!hideFilters && <ActionsSortFilter filters={filters} />}
                  <ShowCaseProductsList filters={filters} />
                </div>
              )}

            {!filters.search && (!showCaseIfNoCategory || filters.category) && (
              <ProductsList
                title={category ? category.getName() : ""}
                onListUpdate={onListUpdate}
                description={category ? category.getDescription() : undefined}
                category={category}
                subcategories={subcategories}
                filters={filters}
                hideFilters={hideFilters}
              />
            )}

            {filters.search && (
              <SearchProductsList onListUpdate={onListUpdate} filters={filters} />
            )}
          </>
        );
      }}
    />
  );
}
