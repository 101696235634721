import {useMemo} from "react";
import routes from "src/core/common/routes";
import useRouter from "src/core/common/hooks/useRouter";

export default function useSubcategories(category, categories) {
  const router = useRouter();
  const params = router.query;

  const subcategories = useMemo(() => {
    if (!category) return [];

    const subcategories = categories
      .filter(c => c.getParentCategoryId() === category.getId())
      .map(subcategory => ({
        id: subcategory.getId(),
        name: subcategory.getName(),
        isSelected: params.subcategory === subcategory.getSlug(),
        url: {
          pathname: routes.productSubcategory,
          params: {...params, subcategory: subcategory.getSlug()},
        },
      }));

    if (subcategories.length > 0) {
      subcategories.unshift({
        id: `All ${category.getName()}`,
        name: `All ${category.getName()}`,
        isSelected: !params.subcategory,
        url: {
          pathname: routes.productCategory,
          params: {...params, category: category.getSlug()},
        },
      });
    }

    return subcategories;
  }, [category, categories, params]);

  return {subcategories};
}
