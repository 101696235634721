import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import {
  ModalExternalContainer,
  ModalCloseButton,
} from "@ui/components/Modal/ModalComponents";
import ReactModalAdapter from "@ui/components/Modal/ReactModalAdapter";
import {addAlpha} from "@ui/utils/colors";
import {MODAL_ELEMENT_ID} from "@ui/components/Modal/constants";

export default function Modal({
  children,
  isOpen,
  hidden,
  ContainerComponent,
  onRequestClose,
  allowedToClose,
  closeModalOverride,
  showCloseButton = false,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const [element, setElement] = useState(null);
  useEffect(() => {
    const el = document.getElementById(MODAL_ELEMENT_ID);
    if (!el) {
      const newElement = document.createElement("div");
      newElement.id = MODAL_ELEMENT_ID;
      document.body.append(newElement);
      setElement(newElement);
    } else {
      setElement(el);
    }
  }, []);

  const closeModal = () => {
    if (allowedToClose()) {
      onRequestClose();
    }
  };

  if (!element) return null;

  return (
    <StyledModal
      isOpen={isOpen}
      hidden={hidden}
      onRequestClose={closeModalOverride || closeModal}
      styles={_styles}
    >
      <ContainerComponent>
        {showCloseButton && (
          <ModalCloseButton alt={""} onClick={closeModalOverride || closeModal} />
        )}
        {typeof children === "function" ? children({closeModal}) : children}
      </ContainerComponent>
    </StyledModal>
  );
}

const StyledModal = styled(ReactModalAdapter)`
  visibility: ${({hidden}) => (hidden ? "hidden" : "visible")};

  &__content {
    box-sizing: border-box;
    position: absolute;
    max-width: ${({styles}) => styles.content.maxWidth.lg};
    max-height: ${({styles}) => styles.content.maxHeight.lg};
    top: ${({hidden, styles}) => (hidden ? "-1000000px" : styles.content.top.lg)};
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: ${({styles}) => `translate(-50%, ${styles.content.translateY.lg})`};
    overflow: auto;
    padding: ${({styles}) => styles.content.padding.lg};
    outline: none;
    border: 0;
    z-index: ${({hidden}) => (hidden ? -1 : 11)};
    background: #fff;
    border-radius: ${({styles}) => styles.content.borderRadius.lg};
    display: flex;
    justify-content: center;

    ${media.down("md")} {
      max-width: ${({styles}) => styles.content.maxWidth.md};
      max-height: ${({styles}) => styles.content.maxHeight.md};
      position: absolute;
      top: ${({hidden, styles}) => (hidden ? "-1000000px" : styles.content.top.md)};
      left: 0;
      right: 0;
      bottom: 0;
      transform: ${({styles}) => `translate(0, ${styles.content.translateY.md})`};
      padding: ${({styles}) => styles.content.padding.md};
      margin-right: 0;
      border-radius: ${({styles}) => styles.content.borderRadius.md};
    }

    ${media.down("sm")} {
      max-width: ${({styles}) => styles.content.maxWidth.sm};
      max-height: ${({styles}) => styles.content.maxHeight.sm};
      top: ${({hidden, styles}) => (hidden ? "-1000000px" : styles.content.top.sm)};
      transform: ${({styles}) => `translate(0, ${styles.content.translateY.sm})`};
      padding: ${({styles}) => styles.content.padding.sm};
      border-radius: ${({styles}) => styles.content.borderRadius.sm};
    }

    &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 5px solid #fff;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #f4f4f4;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  &__overlay {
    position: fixed;
    top: ${({hidden}) => (hidden ? "-1000000px" : 0)};
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({styles}) => addAlpha(styles.overlay.color, styles.overlay.opacity)};
    z-index: ${({hidden}) => (hidden ? -1 : 10)};
  }
`;

const defaultStyles = {
  content: {
    top: {
      lg: "50%",
      md: "70px",
      sm: "70px",
    },
    translateY: {
      lg: "-50%",
      md: "0",
      sm: "0",
    },
    maxWidth: {
      lg: "85%",
      md: "100%",
      sm: "100%",
    },
    maxHeight: {
      lg: "100%",
      md: "100%",
      sm: "100%",
    },
    padding: {
      lg: "20px",
      md: "20px",
      sm: "20px 8px",
    },
    borderRadius: {
      lg: "4px",
      md: "4px 4px 0 0",
      sm: "4px 4px 0 0",
    },
  },
  overlay: {
    color: "#000000",
    opacity: 0.75,
  },
};

Modal.defaultProps = {
  hidden: false,
  ContainerComponent: ModalExternalContainer,
  onRequestClose: () => {},
  allowedToClose: () => true,
  styles: defaultStyles,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  isOpen: PropTypes.bool,
  hidden: PropTypes.bool,
  ContainerComponent: PropTypes.elementType,
  onRequestClose: PropTypes.func,
  allowedToClose: PropTypes.func,
  closeModalOverride: PropTypes.func,
  styles: PropTypes.shape({}),
};
