import {lazy} from "react";
import suspensify from "@ui/utils/suspensify";

export const Maintenance = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/Maintenance"))
);
export const CheckCircleMiniIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/CheckCircleMiniIcon"))
);
export const Airplane = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/Airplane"))
);
export const BoltIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/BoltIcon"))
);
export const CalendarIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/CalendarIcon"))
);
export const StoreIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/StoreIcon"))
);
export const ChainLinksIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/ChainLinks"))
);
export const CircleCheckIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/CircleCheck"))
);
export const BoldCircleCheckIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/BoldCircleCheck"))
);
export const AppStoreIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/AppStore"))
);
export const PlayStoreIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/PlayStore"))
);
export const CannabisTypeIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/Cannabis"))
);
export const ClockIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/ClockIcon"))
);
export const CloseIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/CloseIcon"))
);
export const CrossIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/CrossIcon"))
);
export const DiscountIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/DiscountIcon"))
);
export const GiveTipIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/GiveTip"))
);
export const MedicinalIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/MedicineIcon"))
);
export const RecreationalIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/CannabisIcon"))
);
export const PhoneIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/PhoneIcon"))
);
export const RoundedCloseIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/RoundedCloseIcon"))
);
export const RoundedInfoIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/RoundedInfoIcon"))
);
export const RoundedSuccessIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/RoundedSuccessIcon"))
);
export const RoundedWarningIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/RoundedWarningIcon"))
);
export const ShipmentIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/ShipmentIcon"))
);
export const WheelIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/WheelIcon"))
);
export const FiltersIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/Filters"))
);
export const MapPin = suspensify(lazy(() => import("@ui/components/IconsSVGs/MapPin")));
export const ShoppingBag = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/ShoppingBag"))
);
export const ShoppingBagAdd = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/ShoppingBagAdd"))
);
export const ShoppingBagAddPlus = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/ShoppingBagAddPlus"))
);
export const ShoppingBagWithCurve = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/ShoppingBagWithCurve"))
);
export const ShoppingBagWithSlot = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/ShoppingBagWithSlot"))
);
export const CaretDown = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/CaretDown"))
);
export const RoundedUserIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/RoundedUser"))
);
export const BoldSearchIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/BoldSearch"))
);
export const FilledUserIcon = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/FilledUser"))
);
export const AgeCheckerLogo = suspensify(
  lazy(() => import("@ui/components/IconsSVGs/AgeCheckerLogo"))
);
