import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import merge from 'lodash/merge';
import media from '@ui/utils/media';
import { ArrowUpRightIcon } from '@ui/components/Icons';
import spacing from '@ui/utils/spacing';

export default function CategoryCard({ styles, category, LinkComponent, url }) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <LinkComponent to={url}>
      <OuterContainer>
        <Container styles={_styles.root}>
          <Category styles={_styles.category}>{category}</Category>
          <ArrowUpRight styles={_styles.arrow} />
        </Container>
      </OuterContainer>
    </LinkComponent>
  );
}

const OuterContainer = styled.div`
  height: 100%;

  ${media.down('sm')} {
    max-width: calc(${() => `100% - ${spacing(2).replace('px', '')} * 2px`});
    height: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: ${({ styles }) => styles.backgroundColor};
  border-radius: ${({ styles }) => styles.borderRadius};
  box-shadow: ${({ styles }) => styles.boxShadow};
  width: 100%;
  height: ${({ styles }) => styles.height};

  ${media.up('lg')} {
    flex-direction: ${({ styles }) => styles.alignment.lg};
    padding: ${({ styles }) => styles.padding.lg};
    margin: 40px 0 0 0;
  }
  ${media.between('md', 'lg')} {
    flex-direction: ${({ styles }) => styles.alignment.md};
    padding: ${({ styles }) => styles.padding.md};
    margin: 40px 0 0 0;
  }
  ${media.down('sm')} {
    flex-direction: ${({ styles }) => styles.alignment.sm};
    padding: ${({ styles }) => styles.padding.sm};
    margin: ${({ styles }) => styles.margin.sm};
    min-width: ${({ styles }) => styles.minWidth.sm};
    height: 100%;
  }

  &:hover {
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
  }
`;

const Category = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: ${({ styles }) => styles.fontFamily};
  color: ${({ styles }) => styles.color};
  font-weight: ${({ styles }) => styles.fontWeight};
  font-style: ${({ styles }) => styles.fontStyle};
  text-transform: ${({ styles }) => styles.textTransform};

  ${media.up('lg')} {
    -webkit-line-clamp: ${({ styles }) => styles.maxLines.lg};
    font-size: ${({ styles }) => styles.fontSize.lg};
  }
  ${media.between('md', 'lg')} {
    -webkit-line-clamp: ${({ styles }) => styles.maxLines.md};
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    -webkit-line-clamp: ${({ styles }) => styles.maxLines.sm};
    padding-right: 4px;
    font-size: ${({ styles }) => styles.fontSize.sm};
  }
`;

const ArrowUpRight = styled(ArrowUpRightIcon)`
  color: ${({ styles }) => styles.color};

  ${media.up('lg')} {
    width: ${({ styles }) => styles.size.lg};
    height: ${({ styles }) => styles.size.lg};
  }
  ${media.between('md', 'lg')} {
    width: ${({ styles }) => styles.size.md};
    height: ${({ styles }) => styles.size.md};
  }
  ${media.down('sm')} {
    width: ${({ styles }) => styles.size.sm};
    height: ${({ styles }) => styles.size.sm};
  }
`;

const defaultStyles = {
  root: {
    alignment: {
      lg: 'column',
      md: 'column',
      sm: 'row'
    },
    backgroundColor: '#000',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 4px, rgb(0 0 0 / 8%) 10px 16px 40px',
    borderRadius: '10px',
    height: '100%',
    padding: {
      lg: '24px 12px 40px 32px',
      md: '24px 12px 26px 18px',
      sm: '24px 12px 26px 18px'
    },
    margin: {
      lg: '40px 0 0',
      md: '40px 0 0',
      sm: '0 0 0 16px'
    },
    minWidth: {
      sm: '300px'
    },
    maxWidth: {
      sm: '368px'
    }
  },
  category: {
    fontFamily: 'sans-serif',
    fontSize: {
      lg: '40px',
      md: '28px',
      sm: '18px'
    },
    fontWeight: '900',
    fontStyle: 'italic',
    color: '#FFF',
    textTransform: 'capitalize',
    maxLines: {
      lg: 3,
      md: 3,
      sm: 1
    }
  },
  arrow: {
    color: '#FFF',
    size: {
      lg: '50px',
      md: '40px',
      sm: '40px'
    }
  }
};

CategoryCard.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      boxShadow: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      })
    }),
    category: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      textTransform: PropTypes.string,
      maxLines: PropTypes.shape({
        lg: PropTypes.number,
        md: PropTypes.number,
        sm: PropTypes.number
      })
    }),
    arrow: PropTypes.shape({
      color: PropTypes.string,
      width: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      })
    })
  }),
  category: PropTypes.string,
  LinkComponent: PropTypes.elementType,
  url: PropTypes.object
};

CategoryCard.defaultProps = {
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({ children }) => <a href="#">{children}</a>
};
