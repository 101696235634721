import TymberApp from "src/apps/common/tymber";

const AppMap = {};

const getTymberApp = host => {
  if (!AppMap[host]) {
    AppMap[host] = new TymberApp();
  }

  return AppMap[host];
};

export default getTymberApp;
