import {TheColorFulTitle} from "src/themes/colorful/elements/Title";
import ProductDetail from "src/themes/colorful/components/ProductDetail";
import TheColorFulBrandHeader from "src/themes/colorful/elements/BrandHeader";
import {CategoryCard} from "@ui";
import TheColorFulHeader from "src/themes/colorful/components/Header";
import ColorFulShowCaseSection from "src/themes/colorful/elements/ShowCaseSection/ShowCaseSection";
import BoostShowCaseProductsList from "src/themes/baseTheme/components/ShowCaseProductsList";
import TheColorFulProductsList from "src/themes/colorful/components/ProductsList/ProductsList";
import TheColorFulProductCard from "src/themes/colorful/components/ProductCard";
import TheColorFulViewAllLink from "src/themes/colorful/elements/ViewAllLink/ViewAllLink";
import {TheColorFulModal} from "src/themes/colorful/elements/Modal";
import ColorFulCategorizedProductsBrowser from "src/themes/colorful/components/CategorizedProductsBrowser";
import TheColorFulDeliveryBanner from "src/themes/colorful/components/DeliveryBanner";
import ColourfulBrandPage from "./pages/BrandPage";
import ColourfulBasePage from "./pages/BasePage";
import ColourfulDealsPage from "./pages/DealsPage";

export default {
  inherits: "baseTheme",
  vars: {
    SHOWCASED_PREFETCH_LIMIT: 10,
  },
  components: {
    Header: TheColorFulHeader,
    ProductsList: TheColorFulProductsList,
    ProductCard: TheColorFulProductCard,
    ShowCasedProductsList: BoostShowCaseProductsList,
    Title: TheColorFulTitle,
    CategoryCard,
    DeliveryBanner: TheColorFulDeliveryBanner,
    ViewAllLink: TheColorFulViewAllLink,
    ProductDetail,
    Modal: TheColorFulModal,
    BrandHeader: TheColorFulBrandHeader,
    ShowCaseSection: ColorFulShowCaseSection,
    CategorizedProductsBrowser: ColorFulCategorizedProductsBrowser,
  },
  pages: {
    BrandPage: ColourfulBrandPage,
    BasePage: ColourfulBasePage,
    DealsPage: ColourfulDealsPage,
  },
};
