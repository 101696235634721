import React from "react";
import styled, {useTheme} from "styled-components";
import {MultiRowProductsList, ProductListHeader} from "@ui";
import styles, {
  noResultsPlaceholderStyles,
  subcategoriesStyles,
} from "src/themes/baseTheme/components/ProductsList/styles";
import {renderNodeOrComponent} from "src/core/common/utils";
import Loader from "src/core/common/components/elements/Loader";
import ActionsSortFilter from "src/themes/baseTheme/elements/filters/ActionsSortFilter";
import useSite from "src/core/sites/hooks/useSite";
import useDetailProductsList from "src/core/common/hooks/useDetailProductsList";
import Subcategories from "src/themes/colorful/components/ProductsList/Subcategories";

export default function TheColorFulProductsList({
  title,
  description,
  subcategories,
  Header,
  filters,
  hideFilters,
}) {
  const theme = useTheme();
  const site = useSite();

  const props = useDetailProductsList();

  const HeaderComponent = Header ? (
    renderNodeOrComponent(Header)
  ) : (
    <ProductListHeader
      FiltersComponent={hideFilters ? null : <ActionsSortFilter filters={filters} />}
      title={title}
      description={description}
      SubcategoriesComponent={
        <Subcategories
          subcategories={subcategories}
          styles={subcategoriesStyles(theme, site)}
        />
      }
    />
  );

  return (
    <ListContainer>
      <MultiRowProductsList
        {...props}
        styles={styles(theme, site)}
        noResultsPlaceholderProps={{
          styles: noResultsPlaceholderStyles(theme, site),
        }}
        LoadingComponent={CustomLoader}
        HeaderComponent={HeaderComponent}
      />
    </ListContainer>
  );
}

const CustomLoader = () => (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
);

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  margin-bottom: 60px;
`;
