import React from "react";
import {useTheme} from "styled-components";
import {
  BuyNowActions,
  HorizontalProductCard,
  ProductCard,
  ResponsiveProductCard,
  SaleIndicator,
} from "@ui";
import useProductCard from "src/core/common/hooks/useProductCard";
import styles from "src/themes/baseTheme/components/ProductCard/styles";
import Product from "src/core/common/models/product";
import * as ThemedComponents from "src/themes/utils/themedComponents";
import useSite from "src/core/sites/hooks/useSite";
import {getFlowerTypeIndicatorProps} from "src/themes/colorful/elements/flowerType";

export default function TheColorFulProductCard({product, ...props}) {
  const _product = product instanceof Product ? product : new Product(product);
  const {actionProps, ...productCardProps} = useProductCard({product});
  const theme = useTheme();
  const site = useSite();

  const {actionsMode} = site.getUiConfiguration().productCard;

  const onSale = _product.isOnSale();

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site, theme);

  const DesktopProductCard = (
    <div>
      <ProductCard
        {...props}
        {...productCardProps}
        styles={styles(theme, site, {mode: props.mode})}
        ActionsComponent={innerProps => (
          <BuyNowActions
            AddIconComponent={AddIconComponent}
            {...actionProps}
            {...innerProps}
            mode={props.mode || "big"}
            buyText={
              props.mode === "small"
                ? "Buy"
                : site.getUiConfiguration().productCard.buyText
            }
          />
        )}
        potencyTagsPosition="bottom"
        flowerTypeIndicatorProps={flowerTypeIndicatorProps}
        SaleIndicatorComponent={props =>
          onSale && <SaleIndicator {...props} position={"left"} />
        }
      />
    </div>
  );

  const MobileProductCard = (
    <div>
      <HorizontalProductCard
        {...props}
        {...productCardProps}
        styles={styles(theme, site, {isHorizontalInMobile: true})}
        showSizes
        potencyTagsPosition="bottom"
        flowerTypeIndicatorProps={flowerTypeIndicatorProps}
        SaleIndicatorComponent={props =>
          onSale && <SaleIndicator {...props} position={"right"} />
        }
        ActionsComponent={props => (
          <BuyNowActions
            AddIconComponent={AddIconComponent}
            mode={actionsMode}
            {...props}
            {...actionProps}
          />
        )}
      />
    </div>
  );

  return (
    <ResponsiveProductCard
      DesktopProductCard={DesktopProductCard}
      MobileProductCard={props.mode === "small" ? DesktopProductCard : MobileProductCard}
    />
  );
}

const AddIconComponent = () => {
  const theme = useTheme();

  const ShoppingCartIcon = ThemedComponents.get(theme.v1.icons.shopping);

  return <ShoppingCartIcon style={{height: "22px", width: "22px"}} color={"#fff"} />;
};
