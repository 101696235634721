import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import OptionRow from "@ui/components/CheckoutOptionPicker/OptionRow";
import NewCreditCardForm from "@ui/components/CheckoutPaymentMethod/CreditCard/NewCreditCard";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";
import CreditCardLabelComponent from "@ui/components/CheckoutPaymentMethod/CreditCardLabelComponent";

export default function CreditCardPanel({
  creditCards,
  NewCreditCardFormComponent = NewCreditCardForm,
  creditCardFormProps = {},
  disabled,
  onAddNewCard,
  styles,
  errorStyles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const [addNewCardSelected, setAddNewCardSelected] = useState(false);

  const hasCreditCards = creditCards.length > 0;

  const onCreditCardClick = (onClick, isExpired) => {
    if (!disabled && !isExpired) {
      setAddNewCardSelected(false);
      onClick();
    }
  };

  const _onAddNewCard = () => {
    if (!disabled) {
      onAddNewCard && onAddNewCard();
      setAddNewCardSelected(true);
    }
  };

  return (
    <Container>
      {!hasCreditCards &&
        renderNodeOrComponent(NewCreditCardFormComponent, {
          ...creditCardFormProps,
          disabled,
          styles: _styles.newCreditCardForm,
        })}
      {hasCreditCards && (
        <div>
          <OptionsContainer styles={_styles.optionPicker.container}>
            {creditCards.map(card => (
              <OptionRow
                key={card.value}
                disabled={disabled || card.isExpired}
                selected={card.selected && !addNewCardSelected}
                onClick={() => onCreditCardClick(card.onClick, card.isExpired)}
                LabelComponent={<CreditCardLabelComponent styles={_styles} card={card} />}
                action={card.action}
                styles={_styles.optionPicker.optionRow}
              />
            ))}
            <OptionRow
              disabled={disabled}
              selected={addNewCardSelected}
              onClick={_onAddNewCard}
              label="Add new card"
              styles={{
                ..._styles.optionPicker.optionRow,
                row: {
                  ..._styles.optionPicker.optionRow.row,
                  borderRadius: _styles.optionPicker.optionRow.row.lastChildRadius,
                },
              }}
            />
          </OptionsContainer>
          {addNewCardSelected &&
            renderNodeOrComponent(NewCreditCardFormComponent, {
              ...creditCardFormProps,
              disabled,
              errorStyles: errorStyles,
              styles: {
                marginTop: _styles.newCreditCardForm.gap,
                ..._styles.newCreditCardForm,
              },
            })}
        </div>
      )}
    </Container>
  );
}

const Container = styled.div``;

const OptionsContainer = styled.div`
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};

  > *:not(:last-child) {
    border-bottom: ${({styles}) => styles.border};
  }
`;

const defaultStyles = {
  newCreditCardForm: {
    gap: "14px",
  },
};

CreditCardPanel.defaultProps = {
  creditCards: [],
  styles: defaultStyles,
};

CreditCardPanel.propTypes = {
  creditCardFormProps: PropTypes.shape({
    cardInputProps: PropTypes.object,
    cardNameInputProps: PropTypes.object,
    cardExpirationDateProps: PropTypes.object,
    cardCvcProps: PropTypes.object,
    cardPostalCodeProps: PropTypes.object,
  }),
  onAddNewCard: PropTypes.func,
  NewCreditCardFormComponent: PropTypes.elementType,
  disabled: PropTypes.bool,
  creditCards: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      selected: PropTypes.bool,
      expiryDate: PropTypes.string,
      isExpired: PropTypes.bool,
      onClick: PropTypes.func,
      action: PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        ActionComponent: PropTypes.element,
        onClick: PropTypes.func,
      }),
    })
  ),
  styles: PropTypes.shape({}),
};
