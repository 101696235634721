import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import widthLimited from "@ui/components/Decorators/widthLimited";
import HorizontalList from "@ui/components/ProductsList/HorizontalList";
import merge from "lodash/merge";
import spacing from "@ui/utils/spacing";
import {DisplayOnly} from "@ui/components/Media";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";

function SimpleProductsList({HeaderComponent, skeleton, maxProducts, loading, ...props}) {
  const _styles = merge({}, defaultStyles, props.styles);
  return (
    <OuterContainer $loading={loading}>
      {HeaderComponent &&
        renderNodeOrComponent(HeaderComponent, {
          skeleton,
          styles: _styles.header,
        })}
      <Container>
        {["lg", "md", "sm"].map(dim => (
          <DisplayOnly dims={[dim]} key={dim} fullWidth={true}>
            <HorizontalList
              skeleton={skeleton}
              {...props}
              maxProducts={maxProducts[dim]}
              styles={_styles}
            />
          </DisplayOnly>
        ))}
      </Container>
    </OuterContainer>
  );
}

const defaultStyles = {
  list: {
    justifyContent: "flex-start",
  },
  element: {
    maxWidth: {
      lg: "250px",
      md: "250px",
      sm: "250px",
    },
    minWidth: {
      lg: "150px",
      md: "150px",
      sm: "180px",
    },
  },
  header: {},
};

const Container = widthLimited(styled.div.attrs(() => ({
  className: "simple-products-list simple-products-list__container",
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin: 0 auto;
`);
const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: ${spacing(4)};
  opacity: ${({$loading}) => ($loading ? 0.6 : 1)};
`;

SimpleProductsList.propTypes = {
  styles: PropTypes.shape({
    list: PropTypes.shape({
      justifyContent: PropTypes.string,
      flexDirection: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    element: PropTypes.shape({
      maxWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      minWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      brandName: PropTypes.string,
      brandUrl: PropTypes.string,
      productDetailUrl: PropTypes.string,
      sizes: PropTypes.arrayOf(PropTypes.string),
      potencyTags: PropTypes.arrayOf(PropTypes.string),
      onSale: PropTypes.bool,
      flowerType: PropTypes.shape({
        icon: PropTypes.elementType,
        color: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  maxProducts: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
    }),
  ]),
  skeleton: PropTypes.bool,
  ProductCardComponent: PropTypes.elementType,
  loading: PropTypes.bool,
  HeaderComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
};
SimpleProductsList.defaultProps = {
  styles: defaultStyles,
};

export default SimpleProductsList;
