import React, {useEffect, useMemo, useState} from "react";
import "src/index.css";
import GlobalStyles from "src/core/common/components/GlobalStyles";
import dynamic from "next/dynamic";
import {SiteHead} from "src/core/seo/components/Head";
import {ModalContextProvider} from "src/core/common/components/modules/modal/modalContext";
import {makeAppProvider} from "src/apps/common/appFactory";
import {logModuleLoadError} from "src/core/common/utils";
import Dialog from "src/core/notifications/components/Dialog";
import TymberToast from "src/core/notifications/components/TymberToast";
import SmartBanner from "src/integrations/mobileApp/SmartBanner";
import useScrollRestoration from "src/core/common/hooks/useScrollRestoration";
import {useHistoryManager} from "src/core/common/hooks/useHistoryManager";
import {getHome} from "src/server/preloader/homePage";

const AgeGate = dynamic(() =>
  import("src/core/user/components/AgeGate").catch(
    logModuleLoadError("AgeGate", {critical: true})
  )
);

const MobileAppDownload = dynamic(() =>
  import("src/integrations/mobileApp/MobileAppDownload").catch(
    logModuleLoadError("MobileAppDownload", {critical: true})
  )
);

const IdentityVerificationMain = dynamic(() =>
  import(
    "src/integrations/identityVerification/components/IdentityVerificationMain"
  ).catch(logModuleLoadError("IdentityVerificationMain", {critical: true}))
);

function TymberApp({router, pageProps, initialProps, Component}) {
  const [requiredProps, setRequiredProps] = useState(initialProps?.props || pageProps);

  useDisableNextData();

  useEffect(() => {
    if (!requiredProps?.site) {
      const {shop} = router.query;
      getHome(shop, window.location.hostname, {}).then(setRequiredProps);
    }
  }, [requiredProps?.site]);

  if (router.pathname.indexOf("maintenance") > -1) {
    return <Component />;
  }

  if (!requiredProps?.site) {
    return <div />;
  }

  return <WorkingApp pageProps={requiredProps} Component={Component} router={router} />;
}

function WorkingApp({Component, pageProps, router}) {
  const {shop, host, siteGroupName, site} = pageProps;
  const AppProvider = makeAppProvider(shop, host);

  useScrollRestoration(router);
  useHistoryManager(router);

  useEffect(() => {
    import("src/serviceWorker").then(mod => {
      mod.unregisterAll();
    });
  }, []);

  const _initialSite = useMemo(() => {
    return site;
    // eslint-disable-next-line
  }, []);

  if (!_initialSite) {
    return <Component {...pageProps} />;
  }

  return (
    <AppProvider
      siteGroupName={siteGroupName}
      host={{host: host}}
      initialSite={_initialSite}
    >
      <SiteHead />
      <GlobalStyles />
      <TymberToast />
      <Dialog />
      <AgeGate />
      <SmartBanner />
      <MobileAppDownload />
      <ModalContextProvider>
        <Component {...pageProps} />
        <IdentityVerificationMain />
      </ModalContextProvider>
    </AppProvider>
  );
}

function useDisableNextData() {
  useEffect(() => {
    if (!window.fetch) return;
    const originalFetch = window.fetch;

    window.fetch = async function (...args) {
      const [resource] = args;

      if (resource.includes?.("_next/data")) {
        return new Response(JSON.stringify({}), {
          status: 200,
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      return originalFetch(...args);
    };
  }, []);
}

export default TymberApp;
