import axios from "axios";

export function validateKiosk({basePath, kioskId, storeId}) {
  const url = `${basePath}api/v1/store/kiosk/`;
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      "X-Store": storeId,
      "X-Kiosk": kioskId,
    },
  };
  return axios.get(url, options);
}

export function getKiosks({basePath, storeId}) {
  const url = `${basePath}api/v1/store/kiosks/`;
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      "X-Store": storeId,
    },
  };
  return axios.get(url, options);
}
