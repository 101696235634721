import React from "react";
import SenseBasePage from "./BasePage";
import BrandProductsBrowser from "@menu/components/BrandProductsList";
import useBrand from "src/menu/hooks/useBrand";
import {PageHead} from "src/core/seo/components/Head";
import useRouter from "src/core/common/hooks/useRouter";
import {htmlToText} from "html-to-text";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import BrandDetailSchema from "src/core/seo/components/BrandDetailSchema";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";

const AdBanner = dynamic(() =>
  import("@themes/default/components/AdBanner").catch(logModuleLoadError("adBanner"))
);

export default function SenseBrandPage(props) {
  const router = useRouter();
  const brandSlug = router.query["brand"];
  const [brand] = useBrand(brandSlug);
  const {showAdBanner} = useAdBanner();

  return (
    <SenseBasePage {...props}>
      {brand && (
        <PageHead
          title={brand.getName()}
          description={htmlToText(brand.getDescription())}
          image={brand.getLogo()}
          schemas={<BrandDetailSchema brand={brand} />}
        />
      )}
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <BrandProductsBrowser showFilters showSort />
    </SenseBasePage>
  );
}
