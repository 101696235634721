import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DisplayOnly } from '@ui/components/Media';
import DealCard from '@ui/components/DealCard';
import range from 'lodash/range';
import widthLimited from '@ui/components/Decorators/widthLimited';
import DealsListHeader from '@ui/components/DealsList/DealsListHeader';
import NoResultsPlaceholder from '@ui/components/NoResultsPlaceholder';
import DealsLogo from '@ui/components/DealsList/DealsLogo';
import merge from 'lodash/merge';

function DealsList({
  styles,
  skeleton,
  DealCardComponent,
  deals,
  headerProps,
  noResultsPlaceholderProps
}) {
  const _styles = merge({}, defaultStyles, styles);
  const _deals = skeleton ? range(3) : deals;
  const columns = {
    lg: skeleton ? chunk(range(12), 4) : chunk(deals || [], 4),
    md: skeleton ? chunk(range(9), 3) : chunk(deals || [], 3)
  };

  const showNoResults = !skeleton && (!deals || deals.length === 0);

  return (
    <div>
      <DealsListHeader {...headerProps} />
      {!showNoResults && (
        <Container>
          {['lg', 'md'].map((dim) => (
            <DisplayOnly dims={[dim]} key={dim}>
              <Row>
                {columns[dim].map((column, index) => (
                  <Column key={index}>
                    {renderDealCards(
                      column,
                      skeleton,
                      DealCardComponent,
                      _styles.card
                    )}
                  </Column>
                ))}
              </Row>
            </DisplayOnly>
          ))}
          <DisplayOnly dims={['sm']}>
            <Column>
              {renderDealCards(
                _deals,
                skeleton,
                DealCardComponent,
                _styles.card
              )}
            </Column>
          </DisplayOnly>
        </Container>
      )}
      {showNoResults && (
        <NoResultsPlaceholder
          LogoIconComponent={DealsLogo}
          {...noResultsPlaceholderProps}
        />
      )}
    </div>
  );
}

function renderDealCards(deals, skeleton, DealCardComponent, styles) {
  return deals.map((deal, index) =>
    skeleton ? (
      <DealCardComponent key={index} skeleton={skeleton} styles={styles} />
    ) : (
      <DealCardComponent
        key={index}
        title={deal.title}
        description={deal.description}
        discount={deal.discount}
        required={deal.required}
        source={deal.source}
        styles={styles}
      />
    )
  );
}

function chunk(deals, numPartitions) {
  const partitions = range(numPartitions).map(() => []);
  let currentPartition = 0;
  for (let i = 0; i < deals.length; i++) {
    partitions[currentPartition++].push(deals[i]);

    if (currentPartition >= numPartitions) currentPartition = 0;
  }

  return partitions;
}

const Container = widthLimited(styled.div`
  margin: 32px auto;
`);

const Row = styled.div`
  display: flex;
  width: 100%;
  column-gap: 16px;
  > div {
    flex: 1;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const defaultStyles = {};

DealsList.defaultProps = {
  styles: defaultStyles,
  DealCardComponent: DealCard,
  headerProps: {},
  noResultsPlaceholderProps: {}
};

DealsList.defaultPropTypes = {
  styles: { control: 'object' }
};

DealsList.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.object),
  DealCardComponent: PropTypes.elementType,
  styles: PropTypes.object,
  headerProps: PropTypes.object,
  skeleton: PropTypes.bool,
  noResultsPlaceholderProps: PropTypes.shape(NoResultsPlaceholder.propTypes)
};

export default DealsList;
