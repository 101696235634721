import React from "react";
import {DealsList} from "@ui";
import styled, {useTheme} from "styled-components";
import Title from "src/core/common/components/modules/Title";
import CategoryBar from "@menu/components/CategoryBar";
import get from "lodash/get";
import MedLeafBasePage from "src/themes/medleaf/pages/BasePage";
import DeliveryBanner from "src/core/deliveries/components/DeliveryBanner";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

export default function MedLeafDealsPage({rewards, meta, siteName, ...props}) {
  const theme = useTheme();

  const toggles = useFeatureToggles();

  const TitleComponent = () => {
    return <span>Awaiting offers</span>;
  };

  const TextComponent = () => {
    return (
      <>
        <span>No deals are available at the moment!</span>
        <span>Please come back another day for great deals.</span>
      </>
    );
  };

  return (
    <MedLeafBasePage {...props}>
      <CategoryBar />
      {!toggles.kioskMode() && <DeliveryBanner />}
      <Container>
        <DealsList
          skeleton={meta.loading && rewards.length === 0}
          deals={rewards}
          noResultsPlaceholderProps={{
            styles: getNoResultsStyles(theme),
            TitleComponent: TitleComponent,
            TextComponent: TextComponent,
          }}
          headerProps={{
            TitleComponent: Title,
            title: `${siteName} Deals For Everyone`,
            description:
              "Check out all of our current cannabis deals below and find a robust offering of deals and rewards is the best way to say thanks for your loyal support.",
          }}
        />
      </Container>
    </MedLeafBasePage>
  );
}

const getNoResultsStyles = theme => ({
  logo: {
    backgroundColor: "#fff",
    color: get(theme.v1.colors.primary, "#000"),
  },
  titleText: {
    fontFamily: theme.v1.font.family,
    color: get(theme.v1.colors.primaryText, "#000"),
    fontSize: {
      lg: "20px",
      md: "20px",
      sm: "20px",
    },
  },
  detailText: {
    fontFamily: theme.v1.font.secondaryFamily,
    color: get(theme.v1.colors.primaryText, "#000"),
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "12px",
    },
  },
});

const Container = styled.div`
  margin-top: ${({theme}) => theme.v2.spacing(4)};
`;
