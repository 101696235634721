import React from 'react';
import styled from 'styled-components';
import spacing from '@ui/utils/spacing';
import media from '@ui/utils/media';
import TranslucentOnHover from '@ui/components/Decorators/TranslucentOnHover';
import QuantityPicker from '@ui/components/ProductCard/Actions/BuyNowActions/QuantityPicker';
import get from 'lodash/get';
import PropTypes from 'prop-types';

function BigScreenBuyNowActions({
  styles,
  buyText,
  onAdd,
  showQuantityPicker,
  price,
  hasWeightSizes,
  discountedPrice,
  mode,
  ...props
}) {
  const _onAdd = showQuantityPicker ? null : onAdd;
  const addToCartButtonPosition = styles.addToCartButton?.position;
  const color =
    addToCartButtonPosition === 'right'
      ? styles.buyText.color
      : styles.root.color;

  const quantityPickerStyles = {
    root: {
      color: color,
      fontSize: styles.buyText.fontSize.lg,
      fontWeight: styles.buyText.fontWeight
    },
    button: {
      border: `1px solid ${color}`,
      borderRadius: styles.root.borderRadius,
      backgroundColor: styles.root.backgroundColor
    }
  };
  return (
    <TranslucentOnHover opacityOnHover={0.9} disableEffect={showQuantityPicker}>
      <Container
        mode={mode}
        styles={styles.root}
        addToCartButtonPosition={styles.addToCartButton?.position}
        role={'button'}
        aria-pressed={'false'}
        onClick={addToCartButtonPosition !== 'right' ? _onAdd : null}
      >
        {showQuantityPicker ? (
          <QuantityPicker
            {...props}
            mode={mode}
            hasWeightSizes={hasWeightSizes}
            styles={quantityPickerStyles}
          />
        ) : (
          <BuyText
            styles={styles.buyText}
            onClick={addToCartButtonPosition === 'right' ? _onAdd : null}
            addToCartButtonPosition={styles.addToCartButton?.position}
          >
            {buyText}{' '}
          </BuyText>
        )}
        <PricesContainer styles={styles.prices}>
          <Price crossed={!!discountedPrice} styles={styles.prices}>
            {price}
          </Price>
          {discountedPrice && (
            <Price styles={styles.prices}>{discountedPrice}</Price>
          )}
        </PricesContainer>
      </Container>
    </TranslucentOnHover>
  );
}

const Container = styled.div.attrs(() => ({
  className: 'buy-now-actions buy-now-actions__container',
  'data-keep-cart': 'true'
}))`
  * {
    user-select: none;
  }

  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  border: ${({ styles }) => styles.border};
  border-radius: ${({ styles }) =>
    get(styles.borderRadius, 'lg', styles.borderRadius)};
  background-color: ${({ styles }) => styles.backgroundColor};
  color: ${({ styles }) => styles.color};
  text-transform: ${({ styles }) => styles.textTransform};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};

  ${media.up('lg')} {
    height: ${({ styles }) => styles.height.lg};
    padding: ${({ styles, addToCartButtonPosition }) =>
      addToCartButtonPosition === 'right' ? '' : styles.padding.lg};
    ${({ mode }) => (mode === 'small' ? 'display: none' : '')};
    direction: ${({ addToCartButtonPosition }) =>
      addToCartButtonPosition === 'right' ? 'rtl' : 'ltr'};
  }
  ${media.down('md')} {
    height: ${({ styles }) => styles.height.md};
    padding: ${({ styles, addToCartButtonPosition }) =>
      addToCartButtonPosition === 'right' ? '' : styles.padding.md};
    ${({ mode }) => (mode !== 'big' ? 'display: none' : '')};
    direction: ${({ addToCartButtonPosition }) =>
      addToCartButtonPosition === 'right' ? 'rtl' : 'ltr'};
  }

  ${media.down('sm')} {
    height: ${({ styles }) => styles.height.sm};
    padding: ${({ styles, addToCartButtonPosition }) =>
      addToCartButtonPosition === 'right' ? '' : styles.padding.sm};
    ${({ mode }) => (mode !== 'big' ? 'display: none' : '')};
    direction: ${({ addToCartButtonPosition }) =>
      addToCartButtonPosition === 'right' ? 'rtl' : 'ltr'};
  }
`;

const BuyText = styled.span.attrs(() => ({
  className: 'buy-now-actions buy-now-actions__buy-text',
  'data-keep-cart': 'true'
}))`
  ${media.up('lg')} {
    font-size: ${({ styles }) => styles.fontSize.lg};
  }
  font-weight: ${({ styles }) => styles.fontWeight};
  border: ${({ addToCartButtonPosition }) =>
    addToCartButtonPosition === 'right' ? 'solid' : 'none'};
  border-color: ${({ styles }) => styles.borderColor};
  border-width: ${({ styles }) => styles.borderWidth};
  border-radius: ${({ styles }) => styles.borderRadius};
  padding: ${({ styles, addToCartButtonPosition }) =>
    addToCartButtonPosition === 'right' ? styles.padding : ''};
  color: ${({ styles }) => styles.color};
  cursor: pointer;

  &:hover {
    opacity: ${({ onClick }) => (!onClick ? '1.0' : 0.9)};
  }
  &:active {
    opacity: 1;
    filter: ${({ onClick }) =>
      !onClick ? 'brightness(100%)' : 'brightness(90%)'};
  }
`;

const PricesContainer = styled.div.attrs(() => ({
  className: 'buy-now-actions buy-now-actions__prices-container',
  'data-keep-cart': 'true'
}))`
  display: flex;
  align-items: ${({ styles }) => styles.side};
  flex-direction: ${({ styles }) => styles.alignment};
  > span {
    margin-left: ${spacing(1)};
  }
`;
const Price = styled.span.attrs(({ crossed }) => ({
  className:
    'buy-now-actions buy-now-actions__price' +
    (crossed ? ' buy-now-actions__price--crossed' : ''),
  'data-keep-cart': 'true'
}))`
  text-decoration: ${({ crossed }) => (crossed ? 'line-through' : 'none')};
  opacity: ${({ crossed }) => (crossed ? '0.75' : '1')};

  ${media.up('lg')} {
    font-size: ${({ crossed, styles }) =>
      crossed ? styles.crossed.fontSize.lg : styles.fontSize.lg};
    font-weight: ${({ crossed, styles }) =>
      crossed ? styles.crossed.fontWeight : styles.fontWeight};
    color: ${({ crossed, styles }) =>
      crossed ? styles.crossed.color : styles.color};
  }
`;
BigScreenBuyNowActions.propTypes = {
  styles: PropTypes.shape({
    addToCartButton: PropTypes.shape({
      position: PropTypes.string,
      alignment: PropTypes.oneOf(['row', 'column'])
    })
  })
};

BigScreenBuyNowActions.defaultProps = {
  styles: {},
  buyText: 'Buy Now',
  hasWeightSizes: false,
  addToCartButton: {
    position: 'left'
  }
};

export default BigScreenBuyNowActions;
