import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import {ArrowRightAlt} from "@styled-icons/material/ArrowRightAlt";
import media from "@ui/utils/media";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import widthLimited from "@ui/components/Decorators/widthLimited";

export default function SearchPanel({
  isOpen,
  loading,
  search,
  searchItems,
  onGroupClickHandler,
  resultsCount,
  limit,
  seeAllLink,
  LinkComponent,
  styles,
}) {
  if (!isOpen) return null;

  const _styles = merge({}, defaultStyles, styles);

  const sanitizedText = text => ({__html: text});

  return (
    <Container styles={_styles.root} hasResults={resultsCount > 0}>
      <InnerContainer>
        <GroupsContainer loading={loading} styles={_styles.root}>
          {searchItems.map(
            item =>
              item.count > 0 && (
                <Group>
                  <GroupName
                    onClick={() => {
                      onGroupClickHandler(item.label.toLowerCase());
                    }}
                    styles={_styles.groupName}
                  >
                    {item.label} ({item.count})
                  </GroupName>
                  <Items>
                    {item.options.slice(0, limit).map(option => (
                      <Item disable={loading}>
                        <LinkComponent to={!loading && option.url}>
                          {option.image && (
                            <Image
                              source={option.image}
                              width={_styles.image.width}
                              height={_styles.image.height}
                              styles={_styles.image}
                              alt={option.title}
                            />
                          )}
                          <Text>
                            {option.title && (
                              <Title styles={_styles.title}>{option.title}</Title>
                            )}
                            <Description
                              styles={_styles.description}
                              dangerouslySetInnerHTML={sanitizedText(option.description)}
                            />
                          </Text>
                        </LinkComponent>
                      </Item>
                    ))}
                  </Items>
                </Group>
              )
          )}
        </GroupsContainer>
        {!loading && (
          <SeeAll
            loading={loading}
            hasResults={resultsCount > 0}
            styles={{..._styles.seeAll, backgroundColor: _styles.root.backgroundColor}}
          >
            {resultsCount > 0 ? (
              <LinkComponent to={!loading && seeAllLink} keepQuery>
                <span>See all {resultsCount} results </span>
                <CustomArrowRightAlt styles={_styles.seeAll} />
              </LinkComponent>
            ) : (
              <span>Sorry, nothing has been found for "{search}".</span>
            )}
          </SeeAll>
        )}
      </InnerContainer>
    </Container>
  );
}

const HEADER_HEIGHT = "87px";
const Container = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__container",
}))`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({styles}) => styles.backgroundColor};
  box-sizing: border-box;
  padding: 32px 0;

  ${media.down("md")} {
    width: 100%;
  }

  ${media.down("sm")} {
    padding: ${({hasResults}) => (hasResults ? "32px 0" : 0)};
    overflow-y: scroll;
    width: 100%;
    height: ${({hasResults}) =>
      hasResults ? `calc(100dvh - ${HEADER_HEIGHT})` : "unset"};
    padding-bottom: ${({hasResults}) => (hasResults ? "64px" : "unset")};
  }
`;

const InnerContainer = widthLimited(
  styled.div.attrs(() => ({
    className: "search search__search-panel search__search-panel__container",
  }))`
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
  `
);

const GroupsContainer = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__groups-container",
}))`
  opacity: ${({loading}) => loading && "0.1"};
  display: flex;
  gap: 40px;

  ${media.down("sm")} {
    flex-direction: column;
    gap: 32px;
  }
`;

const Group = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__group",
}))`
  display: flex;
  flex-direction: column;
`;

const GroupName = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__group-name",
}))`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};
  color: ${({styles}) => styles.color};
  text-transform: none;

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Items = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__group__items",
}))`
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding-top: 18px;

  ${media.down("sm")} {
    gap: 22px;
  }
`;

const Item = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__item",
}))`
  max-width: 300px;
  text-transform: none;
  cursor: ${({disable}) => (disable ? "default" : "pointer")};
  transition: transform 0.2s;

  > a {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
  }

  :hover {
    transform: ${({disable}) => !disable && "scale(1.1)"};
  }
`;

const Image = styled(ConfigurableImage).attrs(() => ({
  className: "search search__search-panel search__search-panel__item__image",
}))`
  width: ${({styles}) => styles.width};
  height: ${({styles}) => styles.height};
  border-radius: ${({styles}) => styles.borderRadius};
`;

const Text = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__item__text",
}))`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Title = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__item__text__title",
}))`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Description = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__item__text__description",
}))`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};
  color: ${({styles}) => styles.color};

  max-height: 100px;

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    -webkit-line-clamp: 2;
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  * {
    margin: 0;
  }

  * {
    display: inline;
  }

  *::after {
    content: " \\A\\A";
    white-space: pre;
  }
`;

const SeeAll = styled.div.attrs(() => ({
  className: "search search__search-panel search__search-panel__see-all",
}))`
  opacity: ${({loading}) => loading && "0.1"};
  align-self: center;
  padding-top: 6px;
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};
  color: ${({styles}) => styles.color};
  text-transform: none;
  cursor: ${({loading, hasResults}) => (loading || !hasResults ? "default" : "pointer")};
  transition: transform 0.2s;

  :hover {
    transform: ${({loading, hasResults}) => !loading && hasResults && "scale(1.2)"};
  }

  > a {
    text-decoration: none;
    color: ${({styles}) => styles.color};
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    padding: 24px;
    font-size: ${({styles}) => styles.fontSize.sm};
    position: ${({hasResults}) => (hasResults ? "fixed" : "unset")};
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    transform: ${({hasResults}) => (hasResults ? "translate(-50%, 0)" : "unset")};
    left: 50%;
    background: linear-gradient(
      to bottom,
      rgba(255, 0, 0, 0) 0%,
      ${({styles}) => styles.backgroundColor} 35%
    );
  }
`;

const CustomArrowRightAlt = styled(ArrowRightAlt).attrs(() => ({
  className: "search search__search-panel search__search-panel__see-all__arrow-right",
}))`
  color: ${({styles}) => styles.color};
  width: ${({styles}) => styles.fontSize.lg};

  ${media.down("md")} {
    width: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    width: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  root: {
    width: {
      lg: "calc(100% - 64px)",
      md: "calc(100% - 32px)",
      sm: "calc(100% - 32px)",
    },
    padding: {
      lg: "36px 32px 44px 32px",
      md: "36px 16px 44px 16px",
      sm: "28px 16px 4px 16px",
    },
  },
  groupName: {
    fontFamily: "sans-serif",
    fontWeight: 700,
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "18px",
    },
    color: "#000",
  },
  image: {
    width: "80px",
    height: "80px",
    borderRadius: "8px",
  },
  title: {
    fontFamily: "sans-serif",
    fontWeight: 500,
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    color: "#000",
  },
  description: {
    fontFamily: "sans-serif",
    fontWeight: 400,
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    color: "#000",
  },
  seeAll: {
    fontFamily: "sans-serif",
    fontWeight: 500,
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    color: "#000",
  },
};

SearchPanel.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  search: PropTypes.string,
  searchItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      count: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    })
  ),
  onGroupClickHandler: PropTypes.func,
  resultsCount: PropTypes.number,
  limit: PropTypes.number,
  seeAllLink: PropTypes.string,
  LinkComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      width: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      padding: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      backgroundColor: PropTypes.string,
    }),
    groupName: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
    }),
    image: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    title: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
    }),
    description: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
    }),
  }),
};
