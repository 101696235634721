import React, {useEffect, useRef, useState, lazy, Suspense} from "react";
import Separator from "@ui/components/Separator/Separator";
import styled from "styled-components";
import {merge} from "lodash";
import PropTypes from "prop-types";
// import PlaceholderImg from "./placeholder.png";
const SignatureCanvas = lazy(() => import("react-signature-canvas"));

function Signature({styles, errorStyles, hasError, onChange}) {
  const _styles = merge({}, defaultStyles, styles);

  const containerRef = useRef(null);
  const signatureRef = useRef(null);
  const [signatureCanvasWidth, setSignatureCanvasWidth] = useState(100);
  // const [displayPlaceholder, setDisplayPlaceholder] = useState(true);

  useEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setSignatureCanvasWidth(rect.width);
    }
  }, [containerRef.current]);

  const onBeginSignature = () => {
    // setDisplayPlaceholder(false);
  };

  const onEndSignature = () => {
    onChange && onChange(signatureRef.current.toDataURL());
  };

  const onClear = () => {
    signatureRef.current?.clear();
    // setDisplayPlaceholder(true);
    onChange && onChange(undefined);
  };

  return (
    <Container styles={_styles.root} hasError={hasError} errorStyles={errorStyles}>
      <SignatureContainer ref={containerRef}>
        {/*{displayPlaceholder && <img src={PlaceholderImg} alt={"Your signature"} />}*/}
        <Suspense fallback={<div />}>
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            onBegin={onBeginSignature}
            onEnd={onEndSignature}
            canvasProps={{width: signatureCanvasWidth, height: 100}}
          />
        </Suspense>
      </SignatureContainer>
      <Separator />
      <ClearButton type="button" styles={_styles.clearButton} onClick={onClear}>
        Clear
      </ClearButton>
    </Container>
  );
}

const SignatureContainer = styled.div`
  position: relative;
  height: 100px;
  width: 100%;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    max-width: 200px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: flex-start;
  border-radius: ${({styles}) => styles.borderRadius};
  border: ${({styles}) => styles.border};
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  border-color: ${({hasError, errorStyles}) => (hasError ? errorStyles.color : "")};
`;
const ClearButton = styled.button`
  font-family: ${({styles}) => styles.fontFamily};
  border: 0;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;

const defaultStyles = {
  root: {
    borderRadius: "4px",
    border: "1px solid #D9D9D9",
  },
  clearButton: {
    fontFamily: "sans-serif",
  },
};

Signature.propTypes = {
  hasError: PropTypes.bool,
  errorStyles: PropTypes.shape({
    color: PropTypes.string,
  }),
  styles: PropTypes.shape({
    root: PropTypes.shape({
      borderRadius: PropTypes.string,
      border: PropTypes.string,
    }),
    clearButton: PropTypes.shape({
      fontFamily: PropTypes.string,
    }),
  }),
};

export default Signature;
