import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import Tippy from "@tippyjs/react";
import media from "@ui/utils/media";
import "./tippy.css";

function Tooltip({styles, message, placement, hasArrow, children, className}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <TooltipRoot
      content={message}
      hasArrow={hasArrow}
      placement={placement}
      styles={_styles.root}
    >
      <Content className={className}>{children}</Content>
    </TooltipRoot>
  );
}

const TooltipRoot = styled(Tippy)`
  background-color: #0c2015;
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  .tippy-arrow {
    display: ${({hasArrow}) => (hasArrow ? "block" : "none")};
    color: #0c2015;
  }
`;

const Content = styled.span`
  display: flex;
  width: fit-content;
  align-items: center;
`;

const defaultStyles = {
  root: {
    backgroundColor: "#0C2015",
    fontFamily: "sans-serif",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
};

Tooltip.defaultProps = {
  styles: defaultStyles,
  hasArrow: true,
  placement: "top",
};

Tooltip.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  placement: PropTypes.string,
  hasArrow: PropTypes.bool,
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.shape({
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
    }),
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontStyle: PropTypes.string,
    color: PropTypes.string,
  }),
};

export default Tooltip;
