import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useRewards from "src/core/user/hooks/useRewards";
import useSite from "src/core/sites/hooks/useSite";
import get from "lodash/get";
import {formatPrice} from "src/core/common/price";

export default function useDealsPage() {
  const [, authApi] = useAuthentication();
  const [meta, rewards] = useRewards({user: authApi.userProfile()});

  const site = useSite();
  const siteName = site.getName()?.replace(/-/g, " ");
  const words = siteName?.split(" ").filter(word => word !== "") || [];

  const capitalizedSiteName = words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");

  const getDiscount = reward => {
    const discountType = get(reward, "obj.attributes.discount_type");
    const discountObj = get(reward, "obj.attributes.reward_good");

    let discount = "";
    if (discountType === "%") {
      discount = `${discountObj.value}%`;
    }
    if (discountType === "$") {
      discount = formatPrice(discountObj);
    }

    return discount;
  };

  const _rewards = rewards?.getElements()
    ? rewards.getElements().map(r => ({
        title: get(r, "name", ""),
        description: get(r, "description", ""),
        discount: getDiscount(r),
        required: get(r, "obj.attributes.points_required", ""),
        source: get(r, "obj.attributes.source", ""),
      }))
    : [];

  return {
    meta,
    rewards: _rewards,
    siteName: capitalizedSiteName,
  };
}
