import React from "react";
import {SiteEnv} from "src/core/common/models/siteEnv";
import {useKioskValidation} from "src/apps/kiosk/useKioskValidation";
import KiosksList from "src/apps/kiosk/KioskList";
import BaseAppProvider from "src/apps/common/BaseAppProvider";
import {initializeSiteCache, sitesCache} from "src/apps/common/sitesCache";
import useSiteInitialization from "src/apps/common/useSiteInitialization";

function KioskAppProvider({initialSite, host, siteGroupName, children}) {
  const identifier = host;

  let kioskEnv;
  if (sitesCache[identifier]) kioskEnv = sitesCache[identifier].env;
  else if (initialSite) kioskEnv = new SiteEnv(initialSite.env);
  const {kioskId, kioskSlug} = useKioskValidation(kioskEnv);

  initializeSiteCache({identifier, initialSite, siteGroupName, kioskId});

  const {site} = useSiteInitialization({kioskId, identifier, initialSite, siteGroupName});

  const routeParams = {shop: kioskSlug};

  if (!kioskId) {
    return <KiosksList env={site.env} />;
  }

  return (
    <BaseAppProvider
      appIdentifier={identifier}
      site={site}
      host={host}
      routeParams={routeParams}
    >
      {children}
    </BaseAppProvider>
  );
}

export default KioskAppProvider;
