import React from "react";
import {BrandHeader} from "@ui";
import DetailProductsList from "@themes/default/components/DetailProductsList/index.js";
import useDetailProductsList from "@mock/hooks/useDetailProductsList";

export default function BrandProductsList(props) {
  const listProps = useDetailProductsList();
  return (
    <DetailProductsList
      data-cy="brandProductsList"
      {...listProps}
      Header={<BrandHeader {...props} styles={() => {}} />}
    />
  );
}
