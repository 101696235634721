import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";

function Text({
  text,
  styles,
  variant,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  letterSpacing,
  lineHeight,
  textAlign,
  textTransform,
  color,
  backgroundColor,
}) {
  const _styles = merge(
    {},
    {
      fontFamily: fontFamily,
      fontSize: fontSize,
      fontStyle: fontStyle,
      fontWeight: fontWeight,
      letterSpacing: letterSpacing,
      lineHeight: lineHeight,
      textAlign: textAlign,
      textTransform: textTransform,
      backgroundColor: backgroundColor,
      color: color,
    },
    styles
  );

  const CreateTextComponent = () => {
    if (hasBackgroundColor(_styles)) {
      _styles.padding = "10px 8px";
      _styles.WebkitBoxDecorationBreak = "clone";
    }

    // Text align doesn't work with  span ??
    const headerStyles = _styles.textAlign ? {textAlign: _styles.textAlign} : {};
    headerStyles.margin = 0;

    return React.createElement(
      variant,
      {style: headerStyles},
      <CustomSpan style={_styles}>{text}</CustomSpan>
    );
  };

  return CreateTextComponent();
}

function hasBackgroundColor(styles) {
  if (!styles || !styles.backgroundColor) return false;

  const noBackgroundValues = ["none", "transparent"];
  const bg = styles.backgroundColor.trim();

  return bg && !noBackgroundValues.includes(bg);
}

const CustomSpan = styled.span`
  font-size: ${({style}) => style.fontSize && style.fontSize.lg};
  letter-spacing: ${({style}) => style.letterSpacing};
  line-height: ${({style}) => style.lineHeight && style.lineHeight.lg};

  ${media.down("md")} {
    font-size: ${({style}) => style.fontSize && style.fontSize.md};
    letter-spacing: ${({style}) => style.letterSpacing};
    line-height: ${({style}) => style.lineHeight && style.lineHeight.md};
  }

  ${media.down("sm")} {
    font-size: ${({style}) => style.fontSize && style.fontSize.sm};
    letter-spacing: ${({style}) => style.letterSpacing};
    line-height: ${({style}) => style.lineHeight && style.lineHeight.sm};
  }
`;

Text.defaultProps = {
  variant: "h2",
  fontStyle: "normal",
  fontWeight: 700,
  letterSpacing: 0,
  lineHeight: 1.4,
  textAlign: "center",
  textTransform: "uppercase",
  text: "",
  color: "white",
  backgroundColor: "red",
  styles: {
    fontSize: {
      sm: "5px",
      md: "10px",
      lg: "25px",
    },
    lineHeight: {
      lg: spacing(4),
      md: spacing(2),
      sm: spacing(1),
    },
  },
};

Text.defaultPropTypes = {
  styles: {control: "object"},
  variant: {
    control: "select",
    options: ["h1", "h2", "h3", "h4", " span", "p"],
  },
  fontFamily: {control: "text"},
  fontSize: {control: {type: "number"}},
  fontStyle: {
    control: "select",
    options: ["normal", "italic"],
  },
  fontWeight: {
    control: "select",
    options: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  letterSpacing: {control: {type: "number"}},
  lineHeight: {control: "text"},
  textAlign: {
    control: "select",
    options: ["center", "justify", "left", "right"],
  },
  textTransform: {
    control: "select",
    options: ["none", "capitalize", "uppercase", "lowercase"],
  },
  text: {control: "text"},
  color: {control: {type: "color"}},
  backgroundColor: {control: {type: "color"}},
};

Text.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.number,
  fontStyle: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlign: PropTypes.string,
  textTransform: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  styles: PropTypes.object,
};

export default Text;
