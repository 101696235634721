import React, {useState} from "react";
import {useTheme} from "styled-components";
import {DropdownList, DropdownMenuButton} from "@ui";
import usePages from "src/core/shops/hooks/usePages";
import useSite from "src/core/sites/hooks/useSite";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import Link from "src/core/common/components/modules/Link";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import routes from "src/core/common/routes";
import useCartState from "src/core/checkout/hooks/useCartState";
import CartModalService from "src/core/checkout/components/cart/cartModalService";
import useRouter from "src/core/common/hooks/useRouter";
import styles from "src/themes/baseTheme/components/Header/styles";
import {isMobile} from "src/core/common/utils";
import useCustomHome from "src/integrations/inStoreExperience/hooks/useCustomHome";
import inStoreIcon from "src/integrations/inStoreExperience/assets/in-store.svg";
import useFiltersCatalog from "@menu/hooks/useFiltersCatalog";
import DetailedSearchInputSection from "src/themes/baseTheme/elements/SearchInputSection/DetailedSearchInputSection";
import DefaultSideMenu from "src/core/common/components/collections/menu/side-menu/SideMenu";

export default function useHeader() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const {headerPages} = usePages();
  const site = useSite();
  const siteOptions = site.getOptions();
  const logo = site.getLogoUrl();
  const homeLinkUrl = site.getHomeLinkUrl();
  const toggles = useFeatureToggles();
  const router = useRouter();
  const theme = useTheme();
  const _styles = styles(theme, site);

  const [, setDisplayCustomHome] = useCustomHome();

  const [catalog] = useFiltersCatalog({});
  const headerMenus = site.getUiConfiguration().header.options;

  const pagesOptions = getPagesOptions(headerPages);

  const otherMenuOptions = getOtherMenuOptions(headerMenus, _styles);

  const options = [...otherMenuOptions, ...pagesOptions];

  const showShopMenu = site.getUiConfiguration().header.showShopMenu;

  const [, authApi] = useAuthentication();
  const user = authApi.userProfile();

  const loginButtonProps = {
    user: user ? {firstName: user.getFirstName()} : undefined,
    template: ({user}) => `${user.firstName}`,
    LinkComponent: Link,
    loginUrl: {pathname: user ? routes.myAccount : routes.login},
    loginIcon: site.getUiConfiguration().login.icon,
  };

  const [cartState] = useCartState();

  const cartButtonProps = {
    numberOfItemsInCart: cartState.data ? cartState.data.getNumberOfItems() : null,
    onClick: () => CartModalService.show(),
    cartIcon: site.getUiConfiguration().header.cartIcon,
  };

  let groups =
    isMobile() && siteOptions.inStoreExperienceEnabled()
      ? [getInStoreExperienceOption(setDisplayCustomHome, router)]
      : [];

  groups.push(...getShopOptions(catalog, router));

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return {
    site: site,
    logoUrl: logo,
    mobileLogoUrl: logo,
    LinkComponent: Link,
    logoLinkUrl: homeLinkUrl,
    loginButtonProps: loginButtonProps,
    cartButtonProps: cartButtonProps,
    hideLoginButton: toggles.isKiosk(),
    logoPosition: site.getUiConfiguration().header.logo.position,
    largeScreenOptions: options,
    showShopMenu: showShopMenu,
    isMenuOpen: isMenuOpen,
    setMenuOpen: setMenuOpen,
    groups: groups,
    SearchComponent: DetailedSearchInputSection,
    onClickMenuButton: openMenu,
    onClickCloseMenuButton: closeMenu,
    hideMenuButton: toggles.isKiosk(),
    sideMenu: !toggles.isKiosk() ? (
      <DefaultSideMenu logo={logo} isOpen={isMenuOpen} close={closeMenu} />
    ) : null,
  };
}

export const getPagesOptions = pages =>
  pages.map(page => {
    if (page.showInHeader) {
      return {
        url: {pathname: page.linkTo},
        label: page.label,
        external: page.external,
      };
    } else {
      return null;
    }
  });

const SHOW_OPTIONS = 15;

export const getShopOptions = (catalog, router) => {
  const categories = catalog ? catalog.categories : [];
  const types = catalog ? catalog.types : [];
  const brands = catalog ? catalog.brands : [];
  const tags = catalog ? catalog.tags : [];
  const featuredTags = tags.filter(tag => tag.isFeatured());
  const nonFeaturedTags = tags.filter(tag => !tag.isFeatured());

  return [
    {
      label: "Shop by Category",
      options: [
        {
          label: "All Categories",
          url: makeUrl("", "all-categories", router),
        },
        ...categories
          .map(category => ({
            label: category.getName(),
            url: makeUrl(category, "category", router),
          }))
          .slice(0, SHOW_OPTIONS - 1),
      ],
    },
    {
      label: "Shop by Type",
      options: types
        .map(type => ({
          label: type.getName(),
          url: makeUrl(type, "type", router),
        }))
        .slice(0, SHOW_OPTIONS),
    },
    {
      label: "Shop by Brand",
      options: brands
        .map(brand => ({
          label: brand.getName(),
          url: makeUrl(brand, "brand", router),
        }))
        .slice(0, SHOW_OPTIONS),
    },
    {
      label: "Shop by Tags",
      options: nonFeaturedTags
        .map(tag => ({
          label: tag.getTitle() || tag.getName(),
          url: makeUrl(tag, "tag", router),
        }))
        .slice(0, SHOW_OPTIONS),
    },
    {
      label: "Shop by Specials",
      options: featuredTags
        .map(tag => ({
          label: tag.getTitle() || tag.getName(),
          url: makeUrl(tag, "tag", router),
        }))
        .slice(0, SHOW_OPTIONS),
    },
  ];
};

export const getOtherMenuOptions = (menus, styles) =>
  menus.map(menu => ({
    component: () => (
      <DropdownMenuButton
        label={menu.label}
        MenuComponent={({offsetY}) => (
          <DropdownList
            options={menu.options}
            offsetY={offsetY}
            LinkComponent={({to, children}) => (
              <a href={to} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            )}
            styles={styles}
          />
        )}
        styles={styles}
      />
    ),
  }));

export const getInStoreExperienceOption = (setDisplayCustomHome, router) => ({
  label: "In-Store Experience",
  icon: inStoreIcon,
  onClickHandler: ({close}) => {
    close();
    setDisplayCustomHome(true);
    router.push(routes.home);
  },
});

export const makeUrl = (section, type, router) => {
  switch (type) {
    case "all-categories":
      return router.resolve({
        pathname: routes.allCategories,
      });
    case "category":
      return router.resolve({
        pathname: routes.productCategory,
        params: {category: section.getSlug()},
      });
    case "type":
      return router.resolve({
        pathname: routes.allCategories,
        query: {type: section.getName()},
      });
    case "brand":
      return router.resolve({
        pathname: routes.brandDetail,
        params: {brand: section.getSlug()},
      });
    case "tag":
      return router.resolve({
        pathname: routes.allCategories,
        query: {tag: section.getName()},
      });
    default:
      return {
        pathname: routes.products,
      };
  }
};
