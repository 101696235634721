import React, {useRef} from "react";
import PropTypes from "prop-types";
import styled, {withTheme} from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";

function Sort({orderOptions, currentOrder, onChangeOrder, DropdownComponent, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <ThemedSelect
      options={orderOptions}
      onChange={onChangeOrder}
      value={currentOrder}
      Dropdown={DropdownComponent}
      styles={_styles}
    />
  );
}

const ThemedSelect = withTheme(Select);

function Select({value, onChange, options, Dropdown, styles}) {
  const wrapperRef = useRef();

  return (
    <Wrapper ref={wrapperRef} styles={styles}>
      <Dropdown options={options} value={value} onChange={onChange} placeholder="Sort" />
    </Wrapper>
  );
}

const defaultStyles = {
  root: {
    width: {
      lg: "250px",
      sm: "200px",
    },
    control: {
      padding: `0 ${spacing(2)}`,
      borderColor: "black",
      borderWidth: "3px",
      borderRadius: "0px",
      color: "black",
      backgroundColor: "white",
      fontFamily: "sans-serif",
      fontSize: {
        lg: "20px",
        sm: "16px",
      },
      height: {
        lg: "50px",
        sm: "50px",
      },
      fontWeight: "600",
      lineHeight: "20px",
      hover: {
        borderColor: "grey",
      },
    },
    menu: {
      margin: `${spacing(2)} 0 0 0`,
      padding: `${spacing(1)} 0`,
      borderColor: "black",
      borderWidth: "3px",
      borderRadius: "0px",
      backgroundColor: "white",
    },
    options: {
      color: "black",
      fontFamily: "sans-serif",
      fontSize: {
        lg: "20px",
        sm: "16px",
      },
      fontWeight: "600",
      hover: {
        backgroundColor: "#F6F6F6",
        color: "#3b4342",
      },
      selected: {
        backgroundColor: "#F6F6F6",
        color: "#3b4342",
      },
    },
    arrow: {
      borderColor: "#000 transparent transparent",
      open: {
        borderColor: "transparent transparent #000",
      },
    },
  },
};

const Wrapper = styled.div.attrs(() => ({
  className: "sort__wrapper",
  "data-keep-cart": "true",
}))`
  width: ${({styles}) => styles.root.width.lg};

  ${media.down("sm")} {
    width: ${({styles}) => styles.root.width.sm};
  }

  & .Dropdown-root {
    position: relative;
    box-sizing: border-box;
  }
  & .Dropdown-control {
    padding: ${({styles}) => styles.root.control.padding};
    border: ${({styles}) =>
        styles.root.control.borderWidth?.lg || styles.root.control.borderWidth}
      solid ${({styles}) => styles.root.control.borderColor};
    cursor: pointer;

    ${media.down("sm")} {
      border: ${({styles}) =>
          styles.root.control.borderWidth?.sm || styles.root.control.borderWidth}
        solid ${({styles}) => styles.root.control.borderColor};
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.2);
      font-size: ${({styles}) => styles.root.control.fontSize.sm};
      height: ${({styles}) => styles.root.control.height.sm};
    }
    color: ${({styles}) => styles.root.control.color};
    background-color: ${({styles}) => styles.root.control.backgroundColor};
    font-family: ${({styles}) => styles.root.control.fontFamily};
    font-size: ${({styles}) => styles.root.control.fontSize.lg};
    font-weight: ${({styles}) => styles.root.control.fontWeight};
    line-height: ${({styles}) => styles.root.control.lineHeight};
    height: ${({styles}) => styles.root.control.height?.lg || "50px"};
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: ${({styles}) => styles.root.control.borderRadius};
    transition: 100ms border-color ease-out;
  }
  & .Dropdown-control:hover {
    border-color: ${({styles}) => styles.root.control.hover.borderColor};
  }
  & .Dropdown-menu {
    margin: ${({styles}) => styles.root.menu.margin};
    padding: ${({styles}) => styles.root.menu.padding};
    border: solid;
    border-color: ${({styles}) => styles.root.menu.borderColor};
    border-width: ${({styles}) => styles.root.menu.borderWidth};
    border-radius: ${({styles}) => styles.root.menu.borderRadius};
    background-color: ${({styles}) => styles.root.menu.backgroundColor};

    ${media.down("sm")} {
      top: 0;
      transform: translateY(-110%);
    }
  }
  & .Dropdown-placeholder {
    max-width: 180px;
    ${media.down("sm")} {
      max-width: 130px;
    }
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ,
  .Dropdown-option {
    color: ${({styles}) => styles.root.options.color};
    font-family: ${({styles}) => styles.root.options.fontFamily};
    font-size: ${({styles}) => styles.root.options.fontSize.lg};
    font-weight: ${({styles}) => styles.root.options.fontWeight};

    ${media.down("sm")} {
      font-size: ${({styles}) => styles.root.control.fontSize.sm};
    }
  }
  .Dropdown-option:hover {
    background-color: ${({styles}) => styles.root.options.hover.backgroundColor};
    color: ${({styles}) => styles.root.options.hover.color};
  }
  & .Dropdown-option.is-selected {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    background-color: ${({styles}) => styles.root.options.selected.backgroundColor};
    color: ${({styles}) => styles.root.options.selected.color};
  }
  & .Dropdown-arrow {
    border-color: ${({styles}) => styles.root.arrow.borderColor};
    margin-top: 6px;
    ${media.down("sm")} {
      margin-top: 8px;
    }
    right: 16px;
  }
  & .is-open .Dropdown-arrow {
    border-color: ${({styles}) => styles.root.arrow.open.borderColor};
  }
`;

Sort.defaultProps = {
  styles: defaultStyles,
};

Sort.propTypes = {
  currentOrder: PropTypes.string,
  onChangeOrder: PropTypes.func,
  orderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  DropdownComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      width: PropTypes.shape({
        lg: PropTypes.string,
        sm: PropTypes.string,
      }),
      control: PropTypes.shape({
        padding: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          sm: PropTypes.string,
        }),
        height: PropTypes.shape({
          lg: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontFamily: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        borderColor: PropTypes.string,
        borderRadius: PropTypes.string,
        borderWidth: PropTypes.string,
        color: PropTypes.string,
        backgroundColor: PropTypes.string,
        lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hover: PropTypes.shape({
          borderColor: PropTypes.string,
        }),
      }),
      menu: PropTypes.shape({
        backgroundColor: PropTypes.string,
        margin: PropTypes.string,
        padding: PropTypes.string,
        borderRadius: PropTypes.string,
        borderColor: PropTypes.string,
        borderWidth: PropTypes.string,
      }),
      options: PropTypes.shape({
        color: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontFamily: PropTypes.string,
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hover: PropTypes.shape({
          backgroundColor: PropTypes.string,
          color: PropTypes.string,
        }),
        selected: PropTypes.shape({
          backgroundColor: PropTypes.string,
          color: PropTypes.string,
        }),
      }),
      arrow: PropTypes.shape({
        borderColor: PropTypes.string,
        open: PropTypes.shape({
          borderColor: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default Sort;
