import React from "react";
import BaseThemeBasePage from "./BasePage";
import CategorizedProductsBrowser from "@menu/components/CategorizedProductsBrowser";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import BoostProductsOrderSelector from "src/themes/baseTheme/elements/ProductsOrderSelector";
import dynamic from "next/dynamic";
import styled from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import {DisplayOnly} from "@ui";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import {logModuleLoadError} from "src/core/common/utils";

const ProductFiltersWrapper = dynamic(() =>
  import("@menu/components/ProductFilters").catch(logModuleLoadError("ProductFilters"))
);
const AdBanner = dynamic(() =>
  import("@themes/default/components/AdBanner").catch(logModuleLoadError("adBanner"))
);

export default function BaseThemeProductsPage(props) {
  const site = useSite();
  const {showAdBanner} = useAdBanner();

  const {inlineOnLargeScreen} = site.getUiConfiguration().filters;

  const mediaDimensions = {
    lg: 1440,
  };

  return (
    <BaseThemeBasePage {...props}>
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <DisplayOnly
        dims={inlineOnLargeScreen ? ["lg"] : []}
        overrideDimensions={mediaDimensions}
      >
        <BrowserWithFiltersContainer>
          <ProductFiltersWrapper
            mode={"inline"}
            header={
              <CustomHeader>
                <BoostProductsOrderSelector />
              </CustomHeader>
            }
          />
          <CategorizedProductsBrowser hideFilters={true} />
        </BrowserWithFiltersContainer>
      </DisplayOnly>
      <DisplayOnly
        dims={inlineOnLargeScreen ? ["md", "sm"] : ["lg", "md", "sm"]}
        overrideDimensions={mediaDimensions}
      >
        <CategorizedProductsBrowser />
      </DisplayOnly>
    </BaseThemeBasePage>
  );
}

const CustomHeader = styled.div`
  margin-bottom: ${({theme}) => theme.v2.spacing(8)};
`;

const BrowserWithFiltersContainer = styled.div`
  @media (max-width: 1439px) {
    display: none;
  }

  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 ${({theme}) => theme.v2.spacing(8)};

  > div:last-child {
    max-width: unset;
    width: calc(100% - 350px);
  }

  .carousel-products-list__container {
    position: relative;

    > div:first-child {
      > button {
        position: absolute;
        left: -263px;
        top: 50%;
        transform: translate3d(-50px, -50%, 0);
      }
    }

    > div:last-child {
      > button {
        position: absolute;
        right: -50px;
        top: 50%;
        transform: translate3d(50px, -50%, 0) rotate(180deg);
      }
    }
  }

  .inline-filters__container {
    padding-top: ${({theme}) => theme.v2.spacing(8)};
  }
`;
