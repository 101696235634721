import TymberSite from "src/core/common/models/tymberSite";
import HostContext from "src/core/common/hostContext";
import RouteParamsContext from "src/core/common/routeParamsContext";
import {AppLoaderProvider} from "src/core/common/appLoaderProvider";
import SiteContext from "src/core/sites/context";
import {FeatureToggles, FeatureTogglesContext} from "src/core/common/featureToggles";
import {ThemeProvider} from "styled-components";
import BreadcrumbsProvider from "src/core/common/components/BreadcrumbsContext";
import {RecoilRoot} from "recoil";
import React, {useEffect, useState} from "react";
import getTymberApp from "src/apps/common/getTymberApp";

function BaseAppProvider({host, site, appIdentifier, routeParams, children}) {
  const App = getTymberApp(appIdentifier);
  const [initialized, setInitialized] = useState(App.initialized);
  useEffect(() => {
    if (site instanceof TymberSite) {
      if (!initialized) {
        App.init(site.env);
        setInitialized(true);
      }
      App.initClient(site);
    }
  }, [App, initialized, site]);

  if (site instanceof TymberSite && initialized) {
    return [
      {Provider: HostContext.Provider, props: {value: host}},
      {Provider: RouteParamsContext.Provider, props: {value: routeParams}},
      {Provider: AppLoaderProvider, props: {site: site, app: App}},
      {Provider: SiteContext.Provider, props: {value: site}},
      {
        Provider: FeatureTogglesContext.Provider,
        props: {value: FeatureToggles.getInstance(site)},
      },
      {Provider: ThemeProvider, props: {theme: site.getTheme()}},
      {Provider: BreadcrumbsProvider, props: {}},
      {Provider: RecoilRoot, props: {}},
    ]
      .reverse()
      .reduce((acc, {Provider, props}) => {
        return <Provider {...props}>{!acc ? children : acc}</Provider>;
      }, null);
  } else {
    return null;
  }
}

export default BaseAppProvider;
