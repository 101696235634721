import axios from "axios";
import {selectorFamily, useRecoilValueLoadable} from "recoil";
import * as StoresApi from "src/core/api/shops";
import * as ShopsApi from "src/core/api/shops";
import ResourceConstants from "src/state/resourceConstants";
import {
  getFiltersParams,
  getOrdersListParams,
  getProductsListParams,
  getRecommendedProductsListParams,
  makeAboutItems,
  makeBrand,
  makeFiltersCatalog,
  makeKeyValueMap,
  makeMarketingSources,
  makePage,
  makePagesResponse,
  makePaymentOptions,
  makeProduct,
  makePromotionalBanners,
  makeSocials,
} from "src/state/utils";
import {groupLog} from "src/core/common/logger";
import * as ProductsApi from "src/core/api/products";
import useSite from "src/core/sites/hooks/useSite";
import Page from "src/core/common/models/page";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import Reward from "src/core/common/models/reward";
import * as RewardsApi from "src/core/api/rewards";
import {DeliveryTypes} from "src/core/common/models/DeliveryType";
import * as OrdersApi from "src/core/api/orders";
import SimpleOrder from "src/core/common/models/simpleOrder";

const meta = (loading, error, requested = true) => ({
  loading,
  error,
  requested,
});

const makeAsyncResourceHook = (resourceName, fetch, onFailure) => {
  const selector = selectorFamily({
    key: resourceName,
    cachePolicy_UNSTABLE: {
      // Only store the most recent set of dependencies and their values
      eviction: "most-recent",
    },
    get: props => async () => {
      if (!onFailure) {
        return fetch(props);
      } else {
        try {
          return await fetch(props);
        } catch (error) {
          return onFailure(error);
        }
      }
    },
  });

  return (props = {}) => {
    const {state, contents} = useRecoilValueLoadable(selector(props));

    return {
      data: state === "hasValue" ? contents : null,
      meta: meta(
        state === "loading",
        state === "hasError" ? contents : null,
        state === "hasValue" || state === "loading"
      ),
    };
  };
};

export const useMarketingSourcesResource = makeAsyncResourceHook(
  ResourceConstants.MARKETING_SOURCES,
  () => {
    return StoresApi.marketingSources().then(responseData =>
      makePage(responseData, (element, response) =>
        makeMarketingSources({
          ...element,
          included: response.included,
        })
      )
    );
  }
);

export const usePaymentOptionsResource = makeAsyncResourceHook(
  ResourceConstants.PAYMENT_OPTIONS,
  () => {
    return StoresApi.paymentOptions().then(responseData =>
      makePage(responseData, element => makePaymentOptions(element))
    );
  }
);

export const useAboutItemsResource = makeAsyncResourceHook(
  ResourceConstants.ABOUT_ITEMS,
  () => {
    const site = useSite();

    return axios
      .get(site.getContentHost() + ResourceConstants.PATHNAME)
      .then(makeAboutItems);
  },
  error => {
    groupLog("%cWordPress %cInvalid WP about items url", error, "#5097D5");
    return [];
  }
);

export const useBrandResource = makeAsyncResourceHook(
  ResourceConstants.BRAND,
  ({brandSlug}) => {
    if (!brandSlug) return null;
    return ProductsApi.brandDetail(brandSlug).then(makeBrand);
  }
);

export const usePromotionalBannersResource = makeAsyncResourceHook(
  ResourceConstants.PROMOTIONAL_BANNERS,
  ({prefetched}) => {
    if (prefetched) return null;

    const requests = [
      StoresApi.promotionalBanners(),
      StoresApi.promotionalBannersConfig(),
    ];

    return Promise.all(requests).then(response =>
      makePromotionalBanners({
        images: response[0].data,
        config: response[1].data,
      })
    );
  }
);

export const useProductDetailResource = makeAsyncResourceHook(
  ResourceConstants.PRODUCT_DETAIL,
  ({productId}) => {
    if (!productId) return;
    return ProductsApi.detail(productId).then(makeProduct);
  }
);

export const useSocialsResource = makeAsyncResourceHook(ResourceConstants.SOCIALS, () => {
  return ShopsApi.socials()
    .then(makeSocials)
    .catch(() => null);
});

export const usePagesResource = makeAsyncResourceHook(ResourceConstants.PAGES, () => {
  return ShopsApi.pages()
    .then(makePagesResponse)
    .catch(() => null);
});

export const useFiltersCatalogResource = makeAsyncResourceHook(
  ResourceConstants.FILTERS_CATALOG,
  ({deliveryType, ...props}) => {
    const keyValueMap = makeKeyValueMap(Object.keys(props), props);
    const params = getFiltersParams(keyValueMap);

    if (deliveryType && deliveryType.code !== DeliveryTypes.UNAVAILABLE) {
      return ProductsApi.filters(params).then(makeFiltersCatalog);
    } else {
      return null;
    }
  }
);

export const useProductsListResource = makeAsyncResourceHook(
  ResourceConstants.PRODUCTS_LIST,
  props => {
    if (props.inView === false || !props.deliveryInfo) return null;

    const keyValueMap = makeKeyValueMap(Object.keys(props), props);
    const params = getProductsListParams(keyValueMap);

    return ProductsApi.list(params).then(response => {
      return new Page({
        meta: response.meta,
        objects: response.data.map(
          element => new Product(populateRelations(element, response.included))
        ),
      });
    });
  }
);

export const useRecommendedProductsListResource = makeAsyncResourceHook(
  ResourceConstants.RECOMMMENDED_PRODUCTS_LIST,
  props => {
    const keyValueMap = makeKeyValueMap(Object.keys(props), props);
    const params = getRecommendedProductsListParams(keyValueMap);

    return ProductsApi.recommended(params).then(response => {
      return new Page({
        meta: response.meta,
        objects: response.data.map(
          element => new Product(populateRelations(element, response.included))
        ),
      });
    });
  }
);

export const useOrdersListResource = makeAsyncResourceHook(
  ResourceConstants.ORDERS_LIST,
  props => {
    if (!props.userIdentifier) return null;

    const keyValueMap = makeKeyValueMap(Object.keys(props), props);
    const params = getOrdersListParams(keyValueMap);

    return OrdersApi.list(params).then(response => {
      return new Page({
        meta: response.meta,
        objects: response.data.map(
          element => new SimpleOrder(element, response.included)
        ),
      });
    });
  }
);

export const useRewardsResource = makeAsyncResourceHook(
  ResourceConstants.AVAILABLE_REWARDS,
  () => {
    return RewardsApi.rewards().then(response => {
      return new Page({
        meta: response.meta,
        objects: response.data.map(
          element => new Reward({...element, included: response.included})
        ),
      });
    });
  }
);
