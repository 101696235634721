import {useEffect} from "react";
import Router from "next/router";
import {
  addBeforePopStateListener,
  removeBeforePopStateListener,
} from "src/core/common/hooks/useRouter";

export default function useScrollRestoration(router) {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      let shouldScrollRestore = false;
      window.history.scrollRestoration = "manual";
      restoreScrollPos(router.asPath);

      const onBeforeUnload = event => {
        saveScrollPos(router.asPath);
        delete event["returnValue"];
      };

      const onRouteChangeStart = url => {
        if (router.query.s === "2") {
          shouldScrollRestore = true;
        } else {
          savePreviousScroll();
        }

        saveScrollPos(router.asPath);
      };

      const onRouteChangeComplete = url => {
        if (router.query.s === "2" /* should use previous scroll */) {
          restorePreviousScroll();
        } else if (shouldScrollRestore) {
          shouldScrollRestore = false;

          restoreScrollPos(url.replace(router.basePath, ""));
        }
      };

      const onBeforePopState = () => {
        shouldScrollRestore = true;
      };

      window.addEventListener("beforeunload", onBeforeUnload);
      Router.events.on("routeChangeStart", onRouteChangeStart);
      Router.events.on("routeChangeComplete", onRouteChangeComplete);
      Router.events.on("routeChangeError", error => {
        onRouteChangeComplete(router.asPath);
      });

      addBeforePopStateListener(onBeforePopState, router);

      return () => {
        window.removeEventListener("beforeunload", onBeforeUnload);
        Router.events.off("routeChangeStart", onRouteChangeStart);
        Router.events.off("routeChangeComplete", onRouteChangeComplete);
        removeBeforePopStateListener(onBeforePopState, router);
      };
    }
  }, [router]);
}

function saveScrollPos(url) {
  const scrollPos = {x: window.scrollX, y: window.scrollY};
  sessionStorage.setItem(url, JSON.stringify(scrollPos));
}

const PREVIOUS_SCROLL_KEY = "previous-scroll";
function savePreviousScroll() {
  const scrollPos = {x: window.scrollX, y: window.scrollY};
  sessionStorage.setItem(PREVIOUS_SCROLL_KEY, JSON.stringify(scrollPos));
}

function restorePreviousScroll() {
  const scrollPos = JSON.parse(sessionStorage.getItem(PREVIOUS_SCROLL_KEY));
  if (scrollPos) {
    window.scrollTo(scrollPos.x, scrollPos.y);
  }
}

function restoreScrollPos(url) {
  const scrollPos = JSON.parse(sessionStorage.getItem(url));
  if (scrollPos) {
    window.scrollTo(scrollPos.x, scrollPos.y);
  }
}
