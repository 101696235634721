import React, {useState} from "react";
import VerifyAddressForm from "./VerifyAddressForm";
import * as Notifications from "src/core/notifications";
import Modal, {useCloseModal} from "src/core/common/components/modules/modal/Modal";
import {ModalBackButton} from "src/core/common/components/modules/modal/modalComponents";
import useRouter from "src/core/common/hooks/useRouter";
import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import {DeliveryTypes, makeDeliveryType} from "src/core/common/models/DeliveryType";
import UpdateAddressBuilder from "src/core/authentication/components/updateAddressBuilder";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useDeliveryAddress from "src/core/deliveries/hooks/useDeliveryAddress";
import useSelectPickup from "src/core/deliveries/hooks/useSelectPickup";
import routes from "src/core/common/routes";
import useVerifyAddress from "src/core/deliveries/hooks/useVerifyAddress";
import useShop from "src/core/shops/hooks/useShop";
import useDeliveryMode from "src/core/deliveries/hooks/useDeliveryMode";
import {useRefresh} from "src/core/common/hooks/useRefresh";
import get from "lodash/get";
import {DeliveryModes} from "src/core/common/models/deliveryMode";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useAppLoader from "src/core/common/hooks/useAppLoader";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";

export default function DeliveryModal({
  isOpen,
  closeModal,
  onSuccess = () => {},
  closeOnBack,
  defaultAddress,
  displayDeliveryMode = true,
  backToDeliveryType = false,
}) {
  const __closeModal = useCloseModal({onRequestClose: closeModal});
  const [, selectDeliveryType] = useDeliveryType();
  const {selectStoreOrPickup} = useSelectPickup({onSelect: __closeModal});
  const [builder] = useState(new UpdateAddressBuilder());
  const [, authApi] = useAuthentication();
  const router = useRouter();
  const [address, selectDeliveryAddress] = useDeliveryAddress();
  const [, , getByAddress] = useVerifyAddress();
  const [shop] = useShop();
  const [switchingStores, setSwitchingStore] = useState(false);
  const [deliveryMode, setDeliveryMode] = useDeliveryMode();
  const refresh = useRefresh();
  const toggles = useFeatureToggles();
  const {switchStore} = useAppLoader();
  const {query} = router;
  const options = useSiteOptions();

  const dtcFallbackUrl = options.getDirectToConsumerFallbackUrl();

  const isExpressDeliveryCheck =
    get(
      query,
      "deliveryMode",
      toggles.expressDeliveriesEnabled() ? DeliveryModes.EXPRESS : undefined
    ) === DeliveryModes.EXPRESS;

  function _onSuccess({address, mode}) {
    builder.setNewAddress(address);
    authApi.isLoggedIn() && authApi.updateUser(builder.build());

    const verification = getByAddress(address).data;
    if (!verification) return;

    const currentSiteHandlesAddress =
      !toggles.pickupOnly() &&
      (verification.deliversToShop(shop.data, mode) ||
        (toggles.asapDeliveriesEnabled() && mode === DeliveryModes.ASAP) ||
        (toggles.deliveriesOnly() && verification.deliversToAny(mode)));

    if (currentSiteHandlesAddress) {
      selectDeliveryType(makeDeliveryType(DeliveryTypes.DELIVERY));
      selectDeliveryAddress(address);
      if (mode !== deliveryMode || mode === DeliveryModes.EXPRESS) {
        setDeliveryMode({address, mode});
      }
      onSuccess(address);

      // Waits until mode and delivery type sagas are executed
      setTimeout(() => {
        if (!verification.deliversToShop(shop.data, mode)) {
          const anyShop = verification.getAnyDeliveryShop(mode);
          switchStore(anyShop.getId());
        } else {
          refresh();
        }
      });
    } else {
      const url = verification.getAnyDeliveryShopUrl(
        {
          deliveryType: DeliveryTypes.DELIVERY,
          deliveryMode: mode,
          address: address,
        },
        toggles
      );
      if (url) {
        setSwitchingStore(true);
        selectDeliveryAddress(address);
        router.pushExternal(url);
      }
    }
  }

  function onFailure(address = {}, mode) {
    const verification = getByAddress(address).data;
    if (!verification) return;

    const errorMessage = verification.getErrorMessage(
      address,
      shop.data,
      mode,
      dtcFallbackUrl
    );

    Notifications.error(errorMessage);
  }

  function isAllowedToClose() {
    return Boolean(address) || !toggles.deliveriesOnly();
  }

  function onBack() {
    if (isAllowedToClose()) {
      if (closeOnBack) {
        closeModal();
      } else if (isExpressDeliveryCheck) {
        closeModal ? closeModal() : __closeModal();
      } else if (backToDeliveryType) {
        router.replace(routes.deliveryType);
      } else {
        router.safeBack();
      }
    } else {
      router.push(routes.deliveryType);
    }
  }

  function onCloseModal() {
    if (isAllowedToClose()) {
      closeModal ? closeModal() : __closeModal();
    } else {
      Notifications.error("Please insert your address to continue.");
    }
  }

  return (
    <Modal isOpen={isOpen} closeModalOverride={onCloseModal}>
      <ModalBackButton onClick={onBack} />
      <VerifyAddressForm
        displayDeliveryMode={displayDeliveryMode}
        loading={switchingStores}
        onSuccess={_onSuccess}
        onFailure={onFailure}
        errorMessage={get(query, "message")}
        isExpressDeliveryCheck={isExpressDeliveryCheck}
        onSelectPickup={selectStoreOrPickup}
        defaultAddress={defaultAddress}
      />
    </Modal>
  );
}
