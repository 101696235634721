import React from "react";
import AppliedFilters from "@themes/default/components/AppliedFilters/index.js";
import SortAndFilterHeader from "@themes/default/components/SortAndFilterHeader/index.js";
import BrandProductsList from "@themes/default/components/BrandProductsList/index.js";
import SeoHead from "@mock/components/modules/SeoHead";
import useBrandProductsList from "@mock/hooks/useBrandProductsList";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";

function BrandDetailPage({children, ...props}) {
  const filtersDisplayOptions = {brandsVisible: false};
  const brandListProps = useBrandProductsList();
  const {BasePage} = useThemeComponents();
  return (
    <BasePage {...props}>
      <SeoHead brand={brandListProps.brand} />
      <AppliedFilters hideBrandFilter={true} />
      <SortAndFilterHeader filtersDisplayOptions={filtersDisplayOptions} />
      <BrandProductsList {...brandListProps} />
    </BasePage>
  );
}

export default BrandDetailPage;
