import {initStore} from "./state/store";
import config from "./config";
import Actions from "./actions";
import localStorage from "src/core/common/localStorage";

export {default as store} from "./state/store";

export async function init({backend, middlewares, router}) {
  return initStore().then(() => {
    config.setConfig({
      backend,
    });
    middlewares.forEach(middleware => {
      config.addMiddleware(middleware);
    });
    Actions.loadCart(router.query.cartId);
    window.addEventListener(
      "storage",
      () => handleLocalStorageCartChange(backend),
      false
    );
  });
}

const handleLocalStorageCartChange = backend => {
  const currentCart = Actions.getCart();
  const newCartId = localStorage.getItem(backend.getCartKey());
  if (newCartId && newCartId !== currentCart?.getId()) {
    Actions.loadCart();
  } else if (!backend.hasCart()) {
    Actions.clearCart();
  }
};

export {Actions};
