import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TranslucentOnHover from "@ui/components/Decorators/TranslucentOnHover";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";
import ConfigurableImage from "@ui/components/ConfigurableImage";

function CategoryButton({
  styles,
  LinkComponent,
  linkProps,
  selected,
  categoryName,
  categoryUrl,
  categoryImageUrl,
}) {
  const _styles = merge({}, defaultStyles, styles);
  return (
    <TranslucentOnHover>
      <OuterContainer styles={_styles.root}>
        <LinkComponent to={categoryUrl} {...linkProps}>
          <Container selected={selected} styles={_styles.root}>
            {categoryImageUrl && (
              <CategoryImage
                styles={_styles.image}
                source={categoryImageUrl}
                width={40}
                height={40}
              />
            )}
            <Label styles={_styles.root}>{categoryName}</Label>
          </Container>
        </LinkComponent>
      </OuterContainer>
    </TranslucentOnHover>
  );
}

const defaultStyles = {
  root: {
    backgroundColor: "#fff",
    color: "#000",
    selectedBackgroundColor: "#000",
    selectedColor: "#fff",
    selectedBorder: "#fff",
    fontWeight: "500",
    fontFamily: "sans-serif",
    fontStyle: "",
    opacityOnHover: 1,
    padding: {
      lg: `${spacing(1)} ${spacing(1)}`,
      md: `${spacing(1)} ${spacing(1)}`,
      sm: `${spacing(1)} ${spacing(1)}`,
    },
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "10px",
    },
    textTransform: "capitalize",
    borderRadius: "4px",
    height: {
      lg: "55px",
      md: "55px",
      sm: "55px",
    },
  },
  image: {
    size: {
      lg: "30px",
      md: "30px",
      sm: "30px",
    },
    borderRadius: "4px",
  },
};
const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
    color: ${({styles}) => styles.color};
  }

  &:hover {
    opacity: ${({styles}) => styles.opacityOnHover};
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${({styles}) => styles.padding.lg};
  height: ${({styles}) => styles.height.lg};
  box-shadow: ${({styles}) => styles.boxShadow};
  font-size: ${({styles}) => styles.fontSize.lg};

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
    height: ${({styles}) => styles.height.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  text-transform: ${({styles}) => styles.textTransform};
  background-color: ${({styles, selected}) =>
    selected ? styles.selectedBackgroundColor : styles.backgroundColor};
  color: ${({styles, selected}) => (selected ? styles.selectedColor : styles.color)};
  border: ${({styles, selected}) => (selected ? styles.selectedBorder : styles.border)};
  border-color: ${({styles, selected}) =>
    selected ? styles.selectedBorder : styles.borderColor};
  border-width: ${({styles}) => styles.borderWidth};
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  border-radius: ${({styles}) => styles.borderRadius};
  border-bottom: ${({styles}) => styles.borderBottom};
  column-gap: ${spacing(1)};
  white-space: nowrap;
`;

const CategoryImage = styled(ConfigurableImage)`
  box-sizing: border-box;
  height: ${({styles}) => styles.size.lg};
  width: ${({styles}) => styles.size.lg};

  ${media.down("md")} {
    height: ${({styles}) => styles.size.md};
    width: ${({styles}) => styles.size.md};
  }
  ${media.down("sm")} {
    height: ${({styles}) => styles.size.sm};
    width: ${({styles}) => styles.size.sm};
  }

  border-radius: ${({styles}) => styles.borderRadius};
`;

const Label = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
`;

CategoryButton.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      selectedBackgroundColor: PropTypes.string,
      selectedColor: PropTypes.string,
      selectedBorder: PropTypes.string,
      boxShadow: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      textTransform: PropTypes.string,
      border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      size: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  LinkComponent: PropTypes.elementType,
  selected: PropTypes.bool,
  categoryName: PropTypes.string,
  categoryUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  categoryImageUrl: PropTypes.string,
  linkProps: PropTypes.object,
  borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderColor: PropTypes.string,
};
CategoryButton.defaultProps = {
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({children}) => <a href={"#"}>{children}</a>,
};
export default CategoryButton;
