import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Slider from "rc-slider";
import AutosizeInput from "react-input-autosize";
import Filter from "../Elements/Filter";
import useDebounce from "@ui/utils/useDebounce";

export default function RangeFilter({name, ranges}) {
  return <Filter name={name}>{() => ranges.map(range => <Range {...range} />)}</Filter>;
}

const Range = ({
  range,
  value,
  onChange,
  name,
  unit,
  unitPosition,
  disabled,
  hideIcon,
}) => {
  const [filterRange, setFilterRange] = useState(range);
  const [minRange, maxRange] = range;
  const [min = minRange, max = maxRange] = Array.isArray(value)
    ? value
    : [minRange, maxRange];

  function onChangeFilter(newValue) {
    if (!disabled) {
      setFilterRange(newValue);
      setRangeInputState(newValue);
    }
  }

  function onChangeComplete(newValue) {
    onChange(newValue);
  }

  const [rangeInputState, setRangeInputState] = useState(range);

  function onChangeMin(evt) {
    const newMin = +evt.target.value; // Parse as float
    const [, max] = filterRange;

    setRangeInputState([newMin, max]);
  }

  function onChangeMax(evt) {
    const newMax = +evt.target.value; // Parse as integer
    const [min] = filterRange;
    setRangeInputState([min, newMax]);
  }

  function onBlurMin(evt) {
    const newMin = +evt.target.value;
    const [, max] = filterRange;
    const clampedValue = Math.min(Math.max(newMin, minRange), max);
    const clampedValueMin = clampedValue >= max ? max - 1 : clampedValue;
    setRangeInputState([clampedValueMin, max]);
  }

  function onBlurMax(evt) {
    const newMax = +evt.target.value;
    const [min] = filterRange;
    const clampedValue = Math.min(Math.max(newMax, min), maxRange);
    const clampedValueMax = clampedValue <= min ? min + 1 : clampedValue;
    setRangeInputState([min, clampedValueMax]);
  }

  const _onChange = useDebounce(onChange, 300);

  const [minFilterValue, maxFilterValue] = filterRange;
  /* eslint-disable */
  useEffect(() => {
    _onChange([minFilterValue, maxFilterValue]);
  }, [minFilterValue, maxFilterValue]);

  useEffect(() => {
    if (!minFilterValue && !maxFilterValue) {
      setFilterRange([minRange, maxRange]);
    }
  }, [minRange, maxRange]);

  useEffect(() => {
    setFilterRange([min, max]);
    setRangeInputState([min, max]);
  }, [min, max]);

  const validatedSetFilterRange = useCallback(
    range => {
      if (range[0] >= minRange && range[0] <= range[1] && range[1] <= maxRange) {
        setFilterRange(range);
      }
    },
    [range]
  );

  const debouncedSetFilterRange = useDebounce(validatedSetFilterRange, 300);

  useEffect(() => {
    debouncedSetFilterRange(rangeInputState);
  }, [rangeInputState]);

  return (
    <Filter disabled={disabled} hideIcon={hideIcon}>
      {() =>
        filterRange[0] !== null &&
        filterRange[1] !== null && (
          <RangeContainer>
            <TextFieldsContainer>
              <FromText>{name}</FromText>
              <Container>
                {unitPosition === "pre" ? unit : ""}
                <AutosizeInput
                  id="rangeFilter-min"
                  type="text"
                  value={Math.floor(rangeInputState[0])}
                  onChange={onChangeMin}
                  onBlur={onBlurMin}
                  data-cy="rangeFilter-min"
                />
                {unitPosition === "post" ? unit : ""}
              </Container>
              <Separator> To </Separator>
              <label htmlFor="rangeFilter-min" />
              <label htmlFor="rangeFilter-max" />
              <Container>
                {unitPosition === "pre" ? unit : ""}
                <AutosizeInput
                  id="rangeFilter-max"
                  type="text"
                  value={Math.floor(rangeInputState[1])}
                  onChange={onChangeMax}
                  onBlur={onBlurMax}
                  data-cy="rangeFilter-max"
                />
                {unitPosition === "post" ? unit : ""}
              </Container>
            </TextFieldsContainer>
            <CustomRange
              range
              defaultValue={range}
              value={filterRange}
              min={minRange}
              max={maxRange}
              onChange={onChangeFilter}
              onChangeComplete={onChangeComplete}
              ariaLabelGroupForHandles={["rangeFilter-min", "rangeFilter-max"]}
            />
          </RangeContainer>
        )
      }
    </Filter>
  );
};

const RangeContainer = styled.div`
  margin-top: 15px;
`;
const TextFieldsContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 8px;
  padding-bottom: 5px;
`;

const CustomRange = styled(Slider)`
  > .rc-slider-rail {
    background-color: ${({rail}) => rail};
  }

  > .rc-slider-track {
    background-color: ${({track, theme}) => track || theme.v2.color.primary};
  }

  > .rc-slider-handle {
    background-color: ${({handlers, theme}) => handlers || theme.v2.color.primary};
    border-color: ${({handlers, theme}) => handlers || theme.v2.color.primary};
  }
`;

const FromText = styled.div`
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  padding-right: 5px;
  padding-bottom: 3px;
`;

const Separator = styled.div`
  color: black;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  padding: 5px 5px 7px;
`;

const Container = styled.div`
  color: #8d8d8d;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  border-bottom: 1px solid #8d8d8d;

  #rangeFilter-min,
  #rangeFilter-max[type="text"]:focus {
    outline: none;
  }

  #rangeFilter-min,
  #rangeFilter-max {
    width: 60px;
    font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
    font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
    background-color: white;
    color: #8d8d8d;
    border: 0;
    padding-bottom: 5px;
  }
`;

const defaultStyles = {};

RangeFilter.defaultProps = {
  ranges: [],
  styles: defaultStyles,
};

RangeFilter.propTypes = {
  name: PropTypes.string,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      range: PropTypes.arrayOf(PropTypes.number),
      onChange: PropTypes.func,
      hideIcon: PropTypes.bool,
      unit: PropTypes.string,
      unitPosition: PropTypes.oneOf(["pre", "post"]),
      disabled: PropTypes.bool,
    })
  ),
};
