import boostConfig from "@themes/boost/config";
import defaultConfig from "@themes/default/config";

export default {
  inherits: "baseTheme",
  components: {
    ...boostConfig.components,
  },
  pages: {
    BasePage: defaultConfig.pages.BasePage,
    ...boostConfig.pages,
  },
};
