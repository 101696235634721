import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import {useFiltersCatalogResource} from "src/state/hooks/useAsyncResource";
import {useEffect, useState} from "react";
import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";

function useFiltersCatalog(props = {}) {
  const {data} = useServerContext();

  const [catalog, setCatalog] = useState(data[PrefetchedDataKeys.FILTERS_CATALOG]);

  const [deliveryType] = useDeliveryType();

  const {data: newCatalog, meta} = useFiltersCatalogResource({
    ...props,
    filters: clearFilters(props.filters),
    deliveryType,
  });

  useEffect(() => {
    if (newCatalog) setCatalog(newCatalog);
  }, [newCatalog]);

  return [catalog, meta];
}

const clearFilters = filters => {
  if (!filters) return null;
  return {...(filters.serialize ? filters.serialize() : filters), order: null};
};

export default useFiltersCatalog;
