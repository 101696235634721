import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import useFiltersCatalog from "src/menu/hooks/useFiltersCatalog";
import useSite from "src/core/sites/hooks/useSite";

export default function useCategoryBar() {
  const site = useSite();
  const router = useRouter();
  const params = router.query;
  const selectedCategory = params.category || "all";

  const [catalog] = useFiltersCatalog();
  const _categories = catalog
    ? catalog.categories.filter(category => category.getParentCategoryId() === null)
    : [];

  function makeCategory(category) {
    return {
      name: category.getName(),
      url: {
        pathname: routes.productCategory,
        params: {...params, category: category.getSlug()},
      },
      isSelected: category.getSlug() === selectedCategory,
      imageUrl: category.getIcon(),
    };
  }

  function getAllCategory() {
    return {
      name: "All",
      url: {
        pathname: routes.home,
      },
      isSelected: selectedCategory === "all",
    };
  }

  function isCategorySelected(category) {
    return category.isSelected;
  }

  return {
    site,
    isCategorySelected,
    categories: [
      getAllCategory(),
      ..._categories.map(category => makeCategory(category)),
    ],
    linkProps: {keepQuery: true},
  };
}
