import React, {useContext} from "react";
import BasePage from "@themes/default/pages/BasePage";
import ProductCard from "@themes/default/components/ProductCard";
import CategoryBar from "@themes/default/components/CategoryBar";
import Header from "@themes/default/components/Header";
import DeliveryBanner from "@themes/default/components/DeliveryBanner";
import PromotionalBanner from "@themes/default/components/PromotionalBanner";
import ProductDetail from "@themes/default/components/ProductDetail";
import Title from "@themes/default/components/Title";
import ViewAllLink from "@themes/default/components/ViewAllLink";
import AnnouncementMessage from "@themes/default/components/AnnouncementMessage";

export class DefaultThemeFactory {
  get BasePage() {
    return BasePage;
  }

  get ProductCard() {
    return ProductCard;
  }

  get CategoryBar() {
    return CategoryBar;
  }

  get Header() {
    return Header;
  }

  get DeliveryBanner() {
    return DeliveryBanner;
  }

  get PromotionalBanner() {
    return PromotionalBanner;
  }

  get ProductDetail() {
    return ProductDetail;
  }

  get AnnouncementMessage() {
    return AnnouncementMessage;
  }

  get Title() {
    return Title;
  }

  get ViewAllLink() {
    return ViewAllLink;
  }
}

export const ThemeComponentsContext = React.createContext(new DefaultThemeFactory());

export const useThemeComponents = () => {
  return useContext(ThemeComponentsContext);
};
