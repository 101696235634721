import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "../../utils/media";
import CartButton from "./CartButton";
import {
  InnerContainer,
  Controller,
  RemoveIconComponentContainer,
  Savings,
} from "./commonComponents";

export default function QuantityPicker({
  styles,
  hasPriceDisplay,
  savings,
  isInCart,
  isProductInStock,
  isProductValid,
  isWeightedProduct,
  loading,
  quantity,
  onRemoveItemFromCartHandler,
  onDecreaseQuantityHandler,
  onIncreaseQuantityHandler,
  onChangeQuantityHandler,
  onAddToCartHandler,
  IncreaseIconComponent,
  DecreaseIconComponent,
  RemoveIconComponent,
  cartIcon,
  addToCartText,
  quantityText,
  withBorder,
}) {
  return (
    <>
      <InnerContainer
        hasPriceDisplay={hasPriceDisplay}
        isWeightedProduct={isWeightedProduct}
        withBorder={withBorder}
        styles={styles.root}
      >
        {!isWeightedProduct && (
          <QuantityPickerContainer styles={styles.quantityPicker}>
            {quantityText ? (
              <QuantityText styles={styles.quantityPicker}>Qty:</QuantityText>
            ) : (
              ""
            )}
            <Controller styles={styles.quantityPicker}>
              {isInCart && quantity === 1 ? (
                <RemoveIconComponentContainer
                  styles={styles.quantityPicker.removeItemIcon}
                >
                  <RemoveIconComponent
                    onClick={onRemoveItemFromCartHandler}
                    size={styles.quantityPicker.removeItemIcon.size}
                  />
                </RemoveIconComponentContainer>
              ) : (
                <DecreaseIconComponent
                  onClick={onDecreaseQuantityHandler}
                  size={styles.quantityPicker.minusIconSize}
                />
              )}
            </Controller>

            {withBorder ? (
              <LabelContainer withBorder={true} styles={styles.quantityPicker}>
                <label>
                  <input
                    type={"number"}
                    value={quantity}
                    onChange={onChangeQuantityHandler}
                  />
                </label>
              </LabelContainer>
            ) : (
              <label>
                <input
                  type={"number"}
                  value={quantity}
                  onChange={onChangeQuantityHandler}
                />
              </label>
            )}
            <Controller styles={styles.quantityPicker}>
              <IncreaseIconComponent
                onClick={onIncreaseQuantityHandler}
                size={styles.quantityPicker.plusIconSize}
              />
            </Controller>
          </QuantityPickerContainer>
        )}
        <CartButton
          styles={styles.addToCartButton}
          hasPriceDisplay={hasPriceDisplay}
          isWeightedProduct={isWeightedProduct}
          isInCart={isInCart}
          isProductInStock={isProductInStock}
          isProductValid={isProductValid}
          loading={loading}
          onClick={onAddToCartHandler}
          cartIcon={cartIcon}
          addToCartText={addToCartText}
        />
      </InnerContainer>
      {savings && <Savings styles={styles.savings}>You are saving {savings}</Savings>}
    </>
  );
}

QuantityPicker.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      flexDirection: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    quantityPicker: PropTypes.shape({
      quantityBorder: PropTypes.string,
      quantityBorderRadius: PropTypes.string,
      quantityTextFontSize: PropTypes.string,
      quantityTextWeight: PropTypes.string,
      quantityTextColor: PropTypes.string,
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      borderRadius: PropTypes.string,
      quantitySize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      minusIconSize: PropTypes.string,
      plusIconSize: PropTypes.string,
      quantityText: PropTypes.object,
    }),
    addToCartButton: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      border: PropTypes.string,
      borderColor: PropTypes.string,
      borderWidth: PropTypes.string,
      borderRadius: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      padding: PropTypes.string,
    }),
    savings: PropTypes.shape({
      fontSize: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
  }),
  hasPriceDisplay: PropTypes.bool,
  savings: PropTypes.string,
  isInCart: PropTypes.bool,
  isProductInStock: PropTypes.bool,
  isProductValid: PropTypes.bool,
  isWeightedProduct: PropTypes.bool,
  loading: PropTypes.bool,
  quantity: PropTypes.number,
  onChangeQuantityHandler: PropTypes.func,
  onIncreaseQuantityHandler: PropTypes.func,
  onDecreaseQuantityHandler: PropTypes.func,
  onRemoveItemFromCartHandler: PropTypes.func,
  onAddToCartHandler: PropTypes.func,
  IncreaseIconComponent: PropTypes.elementType,
  DecreaseIconComponent: PropTypes.elementType,
  RemoveIconComponent: PropTypes.elementType,
  cartIcon: PropTypes.string,
  addToCartText: PropTypes.string,
  quantityText: PropTypes.bool,
  withBorder: PropTypes.bool,
};

const QuantityPickerContainer = styled.div.attrs(() => ({
  className: "quantity-picker quantity-picker__container",
}))`
  display: flex;
  align-items: center;
  width: auto;
  max-width: 130px;
  height: 100%;
  background-color: ${({styles}) => styles.backgroundColor};
  border-radius: ${({styles}) => styles.borderRadius};

  ${media.down("sm")} {
    max-width: none;
    flex: 1;
    width: 130px;
  }

  input {
    margin: 0;
    outline: 0;
    border: 0;
    width: 100%;
    text-align: center;
    background-color: ${({styles}) => styles.backgroundColor};
    font-family: ${({styles}) => styles.quantityFamily};
    color: ${({styles}) => styles.quantityColor};
    font-weight: ${({styles}) => styles.quantityWeight};

    ${media.up("lg")} {
      font-size: ${({styles}) => styles.quantitySize.lg};
    }
    ${media.between("md", "lg")} {
      font-size: ${({styles}) => styles.quantitySize.md};
    }
    ${media.down("sm")} {
      font-size: ${({styles}) => styles.quantitySize.sm};
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const QuantityText = styled.div.attrs(() => ({
  className: "quantity-picker quantity-picker__text",
}))`
  font-size: ${({styles}) => styles.quantityTextFontSize};
  font-weight: ${({styles}) => styles.quantityTextWeight};
  color: ${({styles}) => styles.quantityTextColor};
  text-transform: uppercase;
`;

const LabelContainer = styled.div.attrs(() => ({
  className: "quantity-picker quantity-label__container",
}))`
  border: ${({styles, withBorder}) => (withBorder ? styles.quantityBorder : "none")};
  border-radius: ${({styles, withBorder}) =>
    withBorder ? styles.quantityBorderRadius : 0};
  padding: 5px 10px 5px 5px;
`;
