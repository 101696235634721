import React from "react";
import useRouter from "src/core/common/hooks/useRouter";
import BaseAppProvider from "src/apps/common/BaseAppProvider";
import {initializeSiteCache} from "src/apps/common/sitesCache";
import useSiteInitialization from "src/apps/common/useSiteInitialization";

function AppProvider({initialSite, host, siteGroupName, children}) {
  const {shop} = useRouter().query;

  const identifier = shop ? `${host}-${shop}` : host;

  initializeSiteCache({identifier, initialSite, siteGroupName});

  const {site} = useSiteInitialization({identifier, initialSite, siteGroupName});

  const routeParams = {shop: shop};

  return (
    <BaseAppProvider
      appIdentifier={identifier}
      site={site}
      host={host}
      routeParams={routeParams}
    >
      {children}
    </BaseAppProvider>
  );
}

export default AppProvider;
