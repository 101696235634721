import autoBind from "auto-bind";

export class SiteEnv {
  static fromPrototype(siteEnv, otherObj) {
    return new SiteEnv({...siteEnv.envObj, ...otherObj});
  }

  constructor(envObj) {
    this.envObj = envObj;
    autoBind(this);
  }

  getTymberEcommerceHost() {
    return this.getEnvValue("TYMBER_ECOMMERCE_HOST");
  }

  getTymberAppLevel() {
    if (this.getEnvValue("TYMBER_ECOMMERCE_HOST").includes("qa")) {
      return "tymber-staging";
    }
    return "tymber";
  }

  getReleaseLevel() {
    return this.getEnvValue("RELEASE_LEVEL");
  }

  getSentryDsn() {
    return this.getEnvValue("SENTRY_DSN");
  }

  getAtatusApiKey() {
    return this.getEnvValue("ATATUS_API_KEY");
  }

  getMapBoxAccessToken() {
    return this.getEnvValue("MAP_BOX_ACCESS_TOKEN");
  }

  getDefaultCountry() {
    return this.getEnvValue("DEFAULT_COUNTRY");
  }

  getDeliveryAddressSearchBBox() {
    return (
      this.getEnvValue("DELIVERY_ADDRESS_BOUNDING_BOX") || "-125.05,32.36,-113.78,42.19"
    );
  }

  getDeliveryAddressSearchProximity() {
    return this.getEnvValue("DELIVERY_ADDRESS_PROXIMITY") || "-120.72,36.98";
  }

  getDefaultDeliveryMode() {
    return this.getEnvValue("DEFAULT_DELIVERY_MODE");
  }

  getAppMode() {
    return this.getEnvValue("MODE");
  }

  getEcommerceId() {
    return (
      this.getEnvValue("TYMBER_ECOMMERCE_ID") ||
      this.getEnvValue("TYMBER_ECOMMERCE_SITE_ID")
    );
  }

  getKioskId() {
    return this.getEnvValue("TYMBER_KIOSK_ID");
  }

  getDefaultUserPassword() {
    return this.getEnvValue("DEFAULT_USER_PASSWORD");
  }

  getDefaultUserEmail() {
    return this.getEnvValue("DEFAULT_USER_EMAIL");
  }

  getAllowDocuments() {
    return (
      this.getEnvValue("ALLOW_DOCUMENTS") === "true" ||
      !this.getEnvValue("ALLOW_DOCUMENTS")
    );
  }

  getForceDeliveryTypeSelection() {
    return this.getEnvValue("FORCE_DELIVERY_TYPE_SELECTION");
  }

  getContentHost() {
    return this.getEnvValue("CONTENT_HOST");
  }

  getAnnouncementScheduleForLater() {
    return this.getEnvValue("ANNOUNCEMENT_SCHEDULE_FOR_LATER");
  }

  getSchedulePageSize() {
    return this.getEnvValue("SCHEDULE_PAGE_SIZE") || 2;
  }

  getEnabledFilters() {
    return this.getEnvValue("ENABLED_FILTERS") !== "false";
  }

  getPriceFilterVisible() {
    return this.getEnvValue("PRICE_FILTER_VISIBLE") !== "false";
  }

  getAllowTips() {
    return this.getEnvValue("ALLOW_TIPS") !== "false";
  }

  getDeliveriesPath() {
    return this.getEnvValue("DELIVERIES_PATH");
  }

  getAllowedCountryPhoneNumbers() {
    const envValue = this.getEnvValue("ALLOWED_COUNTRY_PHONE_NUMBERS");
    if (envValue) {
      return envValue.split(",").map(entry => entry.toLowerCase());
    } else {
      return undefined;
    }
  }

  getDefaultProductOrder() {
    return this.getEnvValue("DEFAULT_PRODUCT_ORDER");
  }

  getCategoryDefaultProductOrder(category) {
    const fallback = this.getDefaultProductOrder();

    if (typeof category !== "string") return fallback;

    const formattedCategory = category.toUpperCase().replace(/ /g, "_");
    return this.getEnvValue(`DEFAULT_PRODUCT_ORDER_${formattedCategory}`) || fallback;
  }

  getBasePath() {
    return this.getEnvValue("BASE_PATH") || "";
  }

  getTransactionTypeText() {
    return this.getEnvValue("TRANSACTION_TYPE_TEXT") || "Credit/Debit Card Fee";
  }

  getDisableCoupons() {
    return this.getEnvValue("DISABLE_COUPONS");
  }

  getDisableRewards() {
    return this.getEnvValue("DISABLE_REWARDS");
  }

  getFcmWebKey() {
    return this.getEnvValue("FCM_WEB_KEY");
  }

  getFcmApiKey() {
    return this.getEnvValue("FCM_API_KEY");
  }

  getFcmProjectId() {
    return this.getEnvValue("FCM_PROJECT_ID");
  }

  getFcmMessagingSenderId() {
    return this.getEnvValue("FCM_MESSAGING_SENDER_ID");
  }

  getFcmActive() {
    return this.getEnvValue("FCM_ACTIVE");
  }

  getFcmAppId() {
    return this.getEnvValue("FCM_APP_ID");
  }

  getShowRecommendedProductsInCart() {
    const value = this.getEnvValue("SHOW_RECOMMENDED_PRODUCTS_IN_CART");
    return !value || value === "true";
  }

  getFiltersModeOnLargeScreen() {
    return this.getEnvValue("FILTERS_MODE_ON_LARGE_SCREEN");
  }

  getOrdersEndpointV5() {
    return this.getEnvValue("ORDERS_ENDPOINT_V5") === "true";
  }

  getPoweredByName() {
    return this.getEnvValue("POWERED_BY");
  }

  getHidePoweredBy() {
    return this.getEnvValue("HIDE_POWERED_BY");
  }

  getAllowPickupCheckoutNotes() {
    return this.getEnvValue("ALLOW_PICKUP_CHECKOUT_NOTES");
  }

  getStrongholdOnBoardingEnabled() {
    return this.getEnvValue("STRONGHOLD_ONBOARDING_ENABLED");
  }

  getOrderBrandsAlphabeticallyInFilters() {
    return this.getEnvValue("ORDER_BRANDS_ALPHABETICALLY_IN_FILTERS");
  }

  getEnvValue = envVarName => {
    return (
      this.getEnvValueWithPrefix(envVarName, "REACT_APP") ||
      this.getEnvValueWithPrefix(envVarName, "NEXT_PUBLIC")
    );
  };

  getEnvValueWithPrefix = (envVarName, prefix) => {
    const ecommerceId =
      this.envObj[`${prefix}_TYMBER_ECOMMERCE_ID`] ||
      this.envObj[`${prefix}_TYMBER_ECOMMERCE_SITE_ID`] ||
      "";
    const reactAppVarName = `${prefix}_${envVarName}`;
    const transformedEcommerceId = ecommerceId.replace(/-/g, "_");
    const reactAppVarNameWithUuid = `${reactAppVarName}_${transformedEcommerceId}`;

    return this.envObj[reactAppVarNameWithUuid] || this.envObj[reactAppVarName];
  };

  cloneWith(envObj) {
    return new SiteEnv({
      ...this.envObj,
      ...envObj,
    });
  }

  serialize() {
    return this.envObj;
  }
}
