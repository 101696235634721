import React from "react";
import styled from "styled-components";
import Link from "@mock/components/modules/Link";

export default function Subcategories({subcategories, styles}) {
  return (
    <Container>
      {subcategories.map(subcategory => (
        <Link to={subcategory.url} keepQuery>
          <CategoryButton
            key={subcategory.id}
            selected={subcategory.isSelected}
            styles={styles}
          >
            {subcategory.name}
          </CategoryButton>
        </Link>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  flex-flow: nowrap;
`;

const CategoryButton = styled.div`
  font-family: ${({styles}) => styles.root.fontFamily};
  font-size: ${({styles}) => styles.root.fontSize};
  font-weight: ${({styles}) => styles.root.fontWeight};
  color: ${({selected, styles}) =>
    selected ? styles.selected.color : styles.unselected.color};
  background-color: ${({selected, styles}) =>
    selected ? styles.selected.backgroundColor : styles.unselected.backgroundColor};
  padding: 12px;
  border-radius: ${({styles}) => styles.root.borderRadius};
  white-space: nowrap;
`;
