import React from "react";
import styled from "styled-components";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";
import range from "lodash/range";
import PropTypes from "prop-types";
import CategoryCard from "../CategoryCard/CategoryCard";
import merge from "lodash/merge";
import CategoryHeader from "../CategoryHeader/CategoryHeader";

function HorizontalList({
  className,
  skeleton,
  styles,
  maxProducts,
  products,
  ProductCardComponent,
  CategoryCardComponent,
  categoryCardProps = {},
  showHeader = false,
  headerProps = {},
  showLoader = false,
  dim,
  LoadingComponent,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <div>
      <List styles={_styles.list} className={className}>
        {!skeleton &&
          products.slice(0, maxProducts).map((element, index) => (
            <ListElement
              key={`product-${index}`}
              maxProducts={maxProducts}
              styles={{
                smFlexDirection: _styles.list.flexDirection.sm,
                ..._styles.element,
              }}
            >
              {element.isCategoryCard && dim !== "sm" && (
                <CategoryCardComponent {...categoryCardProps} />
              )}
              {!element.isCategoryCard && (
                <ProductCardComponent
                  {...element}
                  product={element}
                  showHeader={showHeader}
                  isFirst={index === 0}
                  headerProps={headerProps}
                />
              )}
            </ListElement>
          ))}
        {showLoader ? LoadingComponent : null}
        {skeleton &&
          range(maxProducts).map(n => (
            <ListElement
              key={`product-${n}`}
              maxProducts={maxProducts}
              styles={{
                smFlexDirection: _styles.list.flexDirection.sm,
                ..._styles.element,
              }}
            >
              <ProductCardComponent skeleton />
            </ListElement>
          ))}
      </List>
    </div>
  );
}

export const List = styled.ul.attrs(() => ({
  className: "simple-products-list simple-products-list__list",
}))`
  width: 100%;

  list-style-type: none;
  margin: -${spacing(2)};
  padding: ${({styles}) => styles.padding};

  display: flex;
  justify-content: ${({styles}) => styles.justifyContent};
  align-items: center;

  column-gap: ${spacing(2)};
  row-gap: ${spacing(2)};

  overflow-x: auto;
  flex-direction: ${({styles}) => styles.flexDirection.lg};

  ${media.down("md")} {
    flex-direction: ${({styles}) => styles.flexDirection.md};
  }

  ${media.down("sm")} {
    flex-direction: ${({styles}) => styles.flexDirection.sm};
    column-gap: ${({styles}) => styles.columnGap};
  }
`;
export const ListElement = styled.li.attrs(() => ({
  className: "horizontal-products-list horizontal-products-list__element",
}))`
  width: calc((100% / ${({maxProducts}) => maxProducts}) - ${spacing(2)});
  > a {
    text-decoration: none;
  }

  ${media.up("lg")} {
    max-width: ${({styles}) => styles.maxWidth.lg};
    min-width: ${({styles}) => styles.minWidth.lg};
  }
  ${media.down("md")} {
    max-width: ${({styles}) => styles.maxWidth.md};
    min-width: ${({styles}) => styles.minWidth.md};
  }
  ${media.down("sm")} {
    max-width: ${({styles}) => styles.maxWidth.sm};
    min-width: ${({styles}) => styles.minWidth.sm};
    box-sizing: border-box;
    width: ${({styles}) => styles.smFlexDirection === "column" && "100%"};
  }
`;

const defaultStyles = {
  list: {
    flexDirection: {
      lg: "row",
      md: "row",
      sm: "row",
    },
    padding: spacing(2),
    columnGap: spacing(0.75),
  },
};

HorizontalList.defaultProps = {
  CategoryCardComponent: CategoryCard,
  CategoryHeaderComponent: CategoryHeader,
  styles: defaultStyles,
};

HorizontalList.propTypes = {
  className: PropTypes.string,
  skeleton: PropTypes.bool,
  styles: PropTypes.object,
  maxProducts: PropTypes.number,
  products: PropTypes.array,
  ProductCardComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  showCategoryCard: PropTypes.bool,
  CategoryCardComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  categoryHeaderProps: PropTypes.object,
  showHeader: PropTypes.bool,
  headerProps: PropTypes.object,
  dim: PropTypes.string,
  categoryCardProps: PropTypes.object,
  showLoader: PropTypes.bool,
  LoadingComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
};

export default HorizontalList;
