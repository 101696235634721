import React from "react";
import "react-smartbanner/dist/main.css";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";
import {isAndroid, isIOS, isMobile} from "src/core/common/utils";
import Head from "next/head";
import {addImgixParams} from "@ui";
import useSite from "src/core/sites/hooks/useSite";
import {isClient} from "src/server/utils/isClient";

export default function SmartBanner() {
  const options = useSiteOptions();
  const appStoreUrl = options.appStoreUrl();
  const playStoreUrl = options.googlePlayUrl();
  const site = useSite();
  const logo = site.getLogoUrl();
  const appLogo = addImgixParams(logo, {width: 120, height: 120}, 3, "clip")?.href;

  const showSmartBanner = () => {
    if (!isClient || (isMobile() && (isIOS() || isAndroid()))) {
      return (
        (options.iosShowSmartBanner() && appStoreUrl) ||
        (options.androidShowSmartBanner() && playStoreUrl)
      );
    }
    return false;
  };

  if (showSmartBanner()) {
    return (
      <Head>
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1,maximum-scale=1"
        />
        <meta name="apple-itunes-app" content={`app-id=${options.appStoreAppId()}`} />
        <meta name="google-play-app" content={`app-id=${options.googlePlayAppId()}`} />
        <link rel="apple-touch-icon" href={appLogo} />
        <link rel="android-tou§ch-icon" href={appLogo} />
      </Head>
    );
  }
  return null;
}
