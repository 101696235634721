export const FilterParams = {
  CATEGORY: "category",
  SUBCATEGORY: "subcategory",
  PRODUCT_TYPE: "type",
  PRODUCT_CATEGORY: "category",
  PRODUCT_SUB_CATEGORY: "q_subcategory",
  PRODUCT_BRANDS: "brand",
  PRODUCT_TAG: "tag",
  PRODUCT_WEIGHT: "weight",
  PRODUCT_SEARCH: "q",
  PRODUCT_MIN_PRICE: "min_price",
  PRODUCT_MAX_PRICE: "max_price",
  PRODUCT_MIN_THC_POTENCY: "min_thc",
  PRODUCT_MAX_THC_POTENCY: "max_thc",
  PRODUCT_MIN_CBD_POTENCY: "min_cbd",
  PRODUCT_MAX_CBD_POTENCY: "max_cbd",
  PRODUCT_THC_UNIT: "unit_thc",
  PRODUCT_CBD_UNIT: "unit_cbd",
  PRODUCT_ON_SALE: "on_sale",
  PRODUCT_ORDER: "order",
};

//Used to pick a different layout other than the default checkbox
export const FilterTypeLayout = {
  FILTER_RANGE: "range",
  FILTER_GRID: "grid",
};

export const FilterNames = {
  TYPE: "Type",
  CATEGORY: "Category",
  SUBCATEGORY: "Subcategory",
  BRAND: "Brand",
  FEATURE_TAG: "Feature Tag",
  NON_FEATURE_TAG: "Non Feature Tag",
  WEIGHT: "Weight",
  ON_SALE: "On Sale",
  PRICE_RANGE: "Price",
  THC_RANGE: "THC",
  CBD_RANGE: "CBD",
};
