import {RELEASE_LEVELS} from "../constants";
import {window} from "browser-monads";
import {version} from "../version";
import sentryOptions from "src/core/analytics/sentryOptions";

const config = {
  releaseLevel: null,
};

export function init({apiKey, releaseLevel}) {
  config.releaseLevel = releaseLevel;
  if (process.env.NODE_ENV !== RELEASE_LEVELS.DEVELOPMENT) {
    getSentry().then(Sentry =>
      Sentry.init({
        dsn: apiKey,
        ...sentryOptions,
        environment: `${window.location.hostname}`,
        beforeSend(event, hint) {
          console.log(event);
          return event;
        },
      })
    );
  }
}

export function testError() {
  try {
    throw new Error("Test error");
  } catch (e) {
    getSentry().then(Sentry => Sentry.captureException(e));
  }
}

export function logEvent(message, level, payload) {
  getSentry().then(Sentry => {
    Sentry.setTag("version", version);
    Sentry.captureEvent({message, level: level, extra: payload});
  });
}

export function addBreadCrumb(payload) {
  getSentry().then(Sentry => Sentry.addBreadcrumb({data: payload, level: "info"}));
}

export function getSentry(callback) {
  return import("@sentry/browser").then(callback);
}
