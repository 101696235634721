import SEOConfig from "../config";
import {window} from "browser-monads";
import routes from "src/core/common/routes";
import Router from "next/router";
import {isClient} from "src/server/utils/isClient";

export function getProductCatalogUrl(ctx) {
  const params = getParams(ctx);
  const pathname = resolvePathName(routes.products, {
    shop: params.shop,
  });
  return makeUrl(pathname, ctx);
}

export function getCheckoutUrl() {
  const pathname = resolvePathName(routes.checkout, {
    shop: Router.query.shop,
  });
  return makeUrl(pathname);
}

export function getProductDetailUrl(product, ctx) {
  const category = product.getMainCategory();
  const params = getParams(ctx);
  const pathname = resolvePathName(routes.getProductDetailPath({product}), {
    shop: params.shop,
    brand: product.getBrandSlug(),
    category: category ? category.getSlug() : "",
    productId: product.getSlug(),
  });
  return makeUrl(pathname, ctx);
}

export function getProductCategoryUrl(category, ctx) {
  const params = getParams(ctx);
  const pathname = resolvePathName(routes.productCategory, {
    shop: params.shop,
    category,
  });
  return makeUrl(pathname, ctx);
}

export function getProductSubcategoryUrl(category, subcategory, ctx) {
  const params = getParams(ctx);
  const pathname = resolvePathName(routes.productSubcategory, {
    shop: params.shop,
    category,
    subcategory,
  });
  return makeUrl(pathname, ctx);
}

export function getBrandDetailUrl(brand, ctx) {
  const params = getParams(ctx);
  const pathname = resolvePathName(routes.brandDetail, {
    brand: brand.getSlug(),
    shop: params.shop,
  });
  return makeUrl(pathname, ctx);
}

export function getSiteURL(ctx) {
  const params = getParams(ctx);
  const pathname = resolvePathName(routes.home, {
    shop: params.shop,
  });
  return makeUrl(pathname, ctx);
}

function makeUrl(pathname, ctx) {
  const {domain, protocol, basePath} = getUrlInfo(ctx);

  if (basePath) {
    return `${protocol}//${domain}${basePath}${pathname}`;
  } else {
    return `${protocol}//${domain}${pathname}`;
  }
}
function getUrlInfo(ctx) {
  if (ctx && ctx.host) {
    return {
      domain: ctx.host,
      protocol: "https:",
      basePath: ctx.basePath,
    };
  } else {
    return {
      domain: window.location.hostname,
      protocol: window.location.protocol,
      basePath: SEOConfig.getRouter()?.basePath,
    };
  }
}

function resolvePathName(path, params = {}) {
  const router = SEOConfig.getRouter();
  if (!router) return "";

  const {pathname} = router.resolve({pathname: path, params});
  return pathname;
}

function getParams(ctx) {
  if (isClient) {
    return Router.query;
  } else if (ctx && ctx.router) {
    return ctx.router.query;
  } else {
    return {};
  }
}
