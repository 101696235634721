import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import OptionRow from "./OptionRow";
import Spinner from "@ui/components/Spinner";

export default function CheckoutOptionPicker({
  styles,
  data,
  loading,
  hasError,
  loadingOptions,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container hasError={hasError} styles={_styles.container}>
      {loadingOptions && <CustomSpinner />}
      {!loadingOptions && data.length === 0 && (
        <div style={{padding: "16px"}}>
          No payment methods available. Please contact support.
        </div>
      )}
      {data.map((row, index) => (
        <OptionRow
          key={`option-${index}`}
          loading={loading}
          RowComponent={row.RowComponent}
          selected={row.selected}
          LabelComponent={row.LabelComponent}
          BannerComponent={row.BannerComponent}
          banners={row.banners}
          id={row.id}
          PromotionComponent={row.PromotionComponent}
          label={row.label}
          value={row.value}
          onClick={row.onClick}
          Icon={row.icon}
          action={row.action}
          disabled={row.disabled}
          helperText={row.helperText}
          ExpandedComponent={row.ExpandedComponent}
          styles={_styles.optionRow}
          dataCy={row.dataCy}
        />
      ))}
    </Container>
  );
}

const CustomSpinner = styled(Spinner)`
  align-self: center;
  margin: 16px;
`;

const Container = styled.div.attrs(() => ({
  className: "checkout-option-picker",
}))`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  border-radius: ${({styles}) => styles.borderRadius};
  border: ${({styles, hasError}) => (hasError ? styles.error.border : styles.border)};

  > *:not(:last-child) {
    border-bottom: ${({styles}) => styles.border};
  }
`;

const defaultStyles = {
  container: {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    error: {
      border: "1px solid red",
    },
  },
};

CheckoutOptionPicker.defaultProps = {
  data: [],
  styles: defaultStyles,
};

CheckoutOptionPicker.defaultPropTypes = {
  data: {control: "array"},
  styles: {control: "object"},
};

CheckoutOptionPicker.propTypes = {
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      selected: PropTypes.bool,
      RowComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
      LabelComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
      BannerComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
      banners: PropTypes.arrayOf(PropTypes.object),
      id: PropTypes.any,
      PromotionComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
      label: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      action: PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        action: PropTypes.func,
      }),
      disabled: PropTypes.bool,
      helperText: PropTypes.string,
      ExpandedComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
    })
  ),
  styles: PropTypes.shape({
    container: PropTypes.shape({
      border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      error: PropTypes.shape({
        border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    optionRow: PropTypes.shape({
      row: PropTypes.shape({
        gap: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        padding: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
      }),
      checkbox: PropTypes.shape({
        width: PropTypes.string,
        height: PropTypes.string,
        borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        selectedWidth: PropTypes.string,
        selectedHeight: PropTypes.string,
        selectedBorder: PropTypes.string,
      }),
      label: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontStyle: PropTypes.string,
        color: PropTypes.string,
        selectedColor: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      value: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontStyle: PropTypes.string,
        color: PropTypes.string,
        selectedColor: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      actionButton: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontStyle: PropTypes.string,
        color: PropTypes.string,
        textTransform: PropTypes.string,
      }),
    }),
  }),
};
