import dynamic from "next/dynamic";
import React from "react";

const ProductFiltersWrapper = dynamic(() => import("@menu/components/ProductFilters"));

export default function ActionsSortFilter({
  filtersDisplayOptions,
  onChangeFilter = () => {},
}) {
  return (
    <>
      <ProductFiltersWrapper
        mode={"drawer"}
        displayOptions={filtersDisplayOptions}
        onChangeFilter={onChangeFilter}
      />
    </>
  );
}
