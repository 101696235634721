import React from "react";
// import styled from "styled-components";
// import {ArrowRightShort} from "@styled-icons/bootstrap/ArrowRightShort";
import {ViewAllLink} from "@ui";
import useViewAllLink from "@mock/hooks/useViewAllLink";
import Link from "@mock/components/modules/Link";

// TODO: refactor to use the same ViewAllLink in both default and boost themes
export default function BoostThemeViewAllLink({
  to,
  keepQuery,
  accessibilityIdentifier,
  /*text = "View All",
  showIcon = true,
  underlined,*/
}) {
  const {isDesktop} = useViewAllLink();

  return (
    <ViewAllLink
      to={to}
      keepQuery={keepQuery}
      accessibilityIdentifier={accessibilityIdentifier}
      LinkComponent={Link}
      isDesktop={isDesktop}
    />
  );

  /*return (
    <CustomLink
      aria-label={`${text} ${
        to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
      } products`}
      to={to}
      keepQuery={keepQuery}
      underlined={underlined}
    >
      <LinkContainer>
        <ViewAllText showIcon={showIcon}>
          <span>{isDesktop || !showIcon ? text : ""}</span>
          {showIcon && (
            <ArrowRightShortIcon
              role="img"
              title={text}
              aria-label={`${text} ${
                to && to.params
                  ? to.params.category
                  : `category ${accessibilityIdentifier}`
              } products`}
            />
          )}
        </ViewAllText>
      </LinkContainer>
    </CustomLink>
  );*/
}

/*const CustomLink = styled(Link)`
  text-decoration: ${({underlined}) =>
    underlined ? "underline !important" : "none !important"};
`;

const LinkContainer = styled.div`
  border: solid;
  border-color: ${({theme}) =>
    !theme.v1.components.viewAll
      ? theme.v2.color.border
      : theme.v1.components.viewAll.borderColor ?? theme.v2.color.border};
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  border-width: ${({theme}) =>
    !theme.v1.components.viewAll
      ? "3px"
      : theme.v1.components.viewAll.borderWidth ?? "2px"};

  padding: 8px;
  display: flex;

  @media (min-width: 1025px) {
    min-width: 100px;
    height: 38px;
    padding: 8px 18px;
    border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  }
`;

const ViewAllText = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: ${({theme}) => theme.v2.typography.title5.size.lg};
  font-family: ${({theme}) => theme.v2.typography.title5.family};
  color: ${({theme}) => theme.v2.color.onSurface};
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  align-items: center;
  white-space: nowrap;
  @media (min-width: 1025px) {
    > span {
      padding-left: ${({theme, showIcon}) => (showIcon ? theme.v2.spacing(2) : 0)};
    }
  }
`;

const ArrowRightShortIcon = styled(ArrowRightShort)`
  color: ${({theme}) => theme.v2.color.onSurface};
  height: 24px;
  width: 24px;
`;*/
