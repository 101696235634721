import React from "react";
import styled from "styled-components";
import Toast from "src/core/notifications/components/Toast";

function TymberToast(props) {
  return (
    <Container>
      <Toast {...props} />
    </Container>
  );
}

const Container = styled.div`
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    font-size: ${({theme}) => theme.v2.typography.sizing.s.lg};
    font-weight: 500;
  }

  .Toastify__toast {
    border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
    min-height: 0;
    padding: ${({theme}) => theme.v2.spacing(4)};
  }
`;
export default TymberToast;
