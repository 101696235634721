import React, {useEffect} from "react";
import styled, {useTheme} from "styled-components";
import {trackProductDetailView} from "src/core/analytics/ecommerce/events";
import {FlightProductInfo} from "@ui";
import {PageHead} from "src/core/seo/components/Head";
import EmptyPlaceholderImage from "src/core/common/assets/empty-placeholder.png";
import Link from "src/core/common/components/modules/Link";
import ProductDetailSchema from "src/core/seo/components/ProductDetailSchema";
import useSite from "src/core/sites/hooks/useSite";
import styles from "./styles";
import useBreadcrumbs from "src/core/common/hooks/useBreadcrumbs";
import BrandRecommendations from "@menu/components/BrandRecommendations";
import {getFlowerTypeIndicatorProps} from "src/themes/flight/elements/flowerType";
import ProductDetailActions from "src/menu/components/ProductDetailActions";
import ProductDetailHeaderActions from "src/menu/components/ProductDetailHeaderActions";
import makeFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/makeFlowerTypeIndicator";
import useProductDetail from "src/core/common/hooks/useProductDetail";

function FlightProductDetail({productId, onAddItem}) {
  const {
    product,
    meta,
    onEmptyPlaceholderButtonClickHandler,
    showSkeleton,
    showProductDetailRecommendations,
    quantityPickerProps,
    productInfoProps,
  } = useProductDetail({productId, onAddItem});

  const theme = useTheme();
  const site = useSite();
  const _styles = styles(theme, site);

  const [, setCategory, setProduct, , setSearch] = useBreadcrumbs();

  useEffect(() => {
    setSearch(null);
  }, []);

  useEffect(() => {
    product && trackProductDetailView(product);
  }, [product]);

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site, theme);

  const mainTags = {...productInfoProps?.mainTags, flowerTypeIndicatorProps};
  const sizes = {...productInfoProps?.sizes};

  const iconType = site.getUiConfiguration().flowerTypeIndicator.iconType;
  const FlowerTypeIndicator = makeFlowerTypeIndicator(iconType);

  useEffect(() => {
    if (product) {
      setProduct(product);
      setCategory(product.getCategories());
    }
    // eslint-disable-next-line
  }, [product]);
  return (
    <Container>
      {!product && meta.error && meta.error.status === 404 && (
        <FlightProductInfo
          hasEmptyPlaceholder
          emptyPlaceholderProps={{
            onEmptyPlaceholderButtonClickHandler,
            emptyPlaceholderImage: EmptyPlaceholderImage,
          }}
        />
      )}
      {showSkeleton && <FlightProductInfo isSkeleton />}
      {product && (
        <>
          <PageHead
            priority={1}
            title={product.getName()}
            description={product.getDescription()}
            image={product.getMainImage()}
            schemas={<ProductDetailSchema product={product} />}
          />
          <FlightProductInfo
            styles={_styles}
            LinkComponent={Link}
            quantityPicker={quantityPickerProps}
            {...productInfoProps}
            FlowerTypeIndicatorComponent={FlowerTypeIndicator}
            mainTags={mainTags}
            inStock={true}
            sizes={sizes}
            promotionText={
              site.getUiConfiguration().productDetail.promotionIndicator.promotionText
            }
            NavigationActionsComponent={
              <ProductDetailHeaderActions styles={_styles.navigationActions} />
            }
            ActionsComponent={<ProductDetailActions product={product} />}
          />
        </>
      )}
      {showProductDetailRecommendations && <BrandRecommendations product={product} />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    padding: 0 32px;
  }

  > div:last-child {
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 1023px) {
      padding: 0 16px;
    }

    margin-bottom: 64px;
  }
`;

export default FlightProductDetail;
