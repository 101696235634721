import React, {useEffect} from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import {MODAL_ELEMENT_ID} from "@ui/components/Modal/constants";

export default function ReactModalAdapter({children, className, isOpen, onRequestClose}) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  //Check why this gives an error in ProductDetail
  useEffect(() => {
    ReactModal.setAppElement(MODAL_ELEMENT_ID);
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
    >
      {children}
    </ReactModal>
  );
}

ReactModalAdapter.defaultProps = {
  onRequestClose: () => {},
};

ReactModalAdapter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};
