import {useMemo} from "react";
import get from "lodash/get";
import {FilterParams, FilterNames} from "@menu/utils/constants";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";
import useAppliedFilters from "src/core/common/hooks/useAppliedFilters";
import useFiltersCatalog from "@menu/hooks/useFiltersCatalog";
import useRange from "@menu/hooks/useRange";

export default function useBaseProductFilters(onChangeFilter) {
  const options = useSiteOptions();
  const [filters, setFilter, clearFilters] = useAppliedFilters(onChangeFilter);
  const [catalog, meta] = useFiltersCatalog({
    filters: filters.serialize(),
  });
  const [priceRange] = useRange("priceRange");
  const [thcRange] = useRange("thcRange");
  const [cbdRange] = useRange("cbdRange");
  const hasOnSaleProducts = get(catalog, "hasOnSaleProducts", false);

  /* eslint-disable */
  const getRangeOptions = (range, [filterParamMin, filterParamMax]) => () => {
    return range
      ? [range.getMin(), range.getMax()]
      : [filters[filterParamMin] || 0, filters[filterParamMax] || 0];
  };

  const pricesRange = useMemo(
    getRangeOptions(priceRange, [
      FilterParams.PRODUCT_MIN_PRICE,
      FilterParams.PRODUCT_MAX_PRICE,
    ]),
    [priceRange]
  );
  const potencyCbdRange = useMemo(
    getRangeOptions(cbdRange, [
      FilterParams.PRODUCT_MIN_CBD_POTENCY,
      FilterParams.PRODUCT_MAX_CBD_POTENCY,
    ]),
    [cbdRange]
  );
  const potencyThcRange = useMemo(
    getRangeOptions(thcRange, [
      FilterParams.PRODUCT_MIN_THC_POTENCY,
      FilterParams.PRODUCT_MAX_THC_POTENCY,
    ]),
    [thcRange]
  );

  const _types = get(catalog, "types", []);
  const _categories = get(catalog, "categories", []);
  const _subcategories = get(catalog, "subcategories", []);
  const _brands = get(catalog, "brands", []);
  const _tags = get(catalog, "tags", []);
  const _weights = get(catalog, "weights", []);

  const getCheckboxOptions = options => () => {
    const optionsArray = [...options];
    optionsArray.sort((a, b) => (b.getCount() > a.getCount() ? 1 : -1));
    return optionsArray;
  };

  const types = useMemo(getCheckboxOptions(_types), [_types]);
  const featuredTags = useMemo(getCheckboxOptions(_tags), [_tags]).filter(tag =>
    tag.isFeatured()
  );
  const nonFeaturedTags = useMemo(getCheckboxOptions(_tags), [_tags]).filter(
    tag => !tag.isFeatured()
  );

  const brands = useMemo(() => {
    const brandsCopy = [..._brands];
    if (options.getOrderBrandsAlphabeticallyInFilters()) {
      brandsCopy.sort((a, b) => a.getName().localeCompare(b.getName()));
    } else {
      brandsCopy.sort((a, b) => (b.getCount() > a.getCount() ? 1 : -1));
    }
    return brandsCopy;
  }, [_brands, options]);

  const weights = useMemo(() => {
    const weightsCopy = [..._weights];
    weightsCopy.sort((a, b) => (b.getAmount() < a.getAmount() ? 1 : -1));
    return weightsCopy;
  }, [_weights]);

  const subcategories = useMemo(() => {
    const subcategoriesCopy = [..._subcategories];
    const categoriesCopy = [..._categories];

    const selectedCategorySlug = filters[FilterParams.CATEGORY];

    const selectedCategory = categoriesCopy.find(
      obj => obj.getSlug() === selectedCategorySlug
    );
    const subcategories = selectedCategory
      ? subcategoriesCopy.filter(
          obj => obj.getParentCategoryId() === selectedCategory.getId()
        )
      : [];

    subcategories.sort((a, b) => (b.getCount() > a.getCount() ? 1 : -1));

    return {
      name: selectedCategory?.getName(),
      values: subcategories,
    };
  }, [_subcategories, filters]);

  const onChangeRangeFilter = (range, filterParams) => ([min, max]) => {
    const maxValue = range ? Math.min(range.getMax(), max) : max;
    const minValue = range ? Math.max(range.getMin(), min) : min;
    setFilter(filterParams, [
      range && minValue === range.getMin() ? null : minValue,
      range && maxValue === range.getMax() ? null : maxValue,
    ]);
  };

  const onChangeCheckboxFilter = filterParams => newValue => {
    setFilter(filterParams, newValue);
  };

  const config = {
    [FilterNames.BRAND]: {
      onChange: onChangeCheckboxFilter(FilterParams.PRODUCT_BRANDS),
      options: brands,
    },
    [FilterNames.TYPE]: {
      onChange: onChangeCheckboxFilter(FilterParams.PRODUCT_TYPE),
      options: types,
    },
    [FilterNames.WEIGHT]: {
      onChange: onChangeCheckboxFilter(FilterParams.PRODUCT_WEIGHT),
      options: weights,
    },
    [FilterNames.NON_FEATURE_TAG]: {
      onChange: onChangeCheckboxFilter(FilterParams.PRODUCT_TAG),
      options: nonFeaturedTags,
    },
    [FilterNames.FEATURE_TAG]: {
      onChange: onChangeCheckboxFilter(FilterParams.PRODUCT_TAG),
      options: featuredTags,
    },
    [FilterNames.SUBCATEGORY]: {
      onChange: onChangeCheckboxFilter(FilterParams.PRODUCT_SUB_CATEGORY),
      options: subcategories,
    },
    [FilterNames.PRICE_RANGE]: {
      onChange: onChangeRangeFilter(priceRange, [
        FilterParams.PRODUCT_MIN_PRICE,
        FilterParams.PRODUCT_MAX_PRICE,
      ]),
      options: pricesRange,
    },
    [FilterNames.THC_RANGE]: {
      onChange: onChangeRangeFilter(thcRange, [
        FilterParams.PRODUCT_MIN_THC_POTENCY,
        FilterParams.PRODUCT_MAX_THC_POTENCY,
      ]),
      options: potencyThcRange,
    },
    [FilterNames.CBD_RANGE]: {
      onChange: onChangeRangeFilter(cbdRange, [
        FilterParams.PRODUCT_MIN_CBD_POTENCY,
        FilterParams.PRODUCT_MAX_CBD_POTENCY,
      ]),
      options: potencyCbdRange,
    },
  };

  const onChangeProductFilter = filterName => value =>
    config[filterName]?.onChange(value);

  const filterOptions = filterName => {
    return config[filterName]?.options;
  };
  /* eslint-enable */

  return {
    meta,
    filters,
    filterOptions,
    hasWeightFilters: !!weights?.length,
    hasOnSaleProducts,
    onChangeProductFilter,
    clearFilters,
  };
}
