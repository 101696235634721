import {DefaultModal} from "src/themes/legacy/components/Modal";
import CategorizedProductsBrowser from "src/themes/legacy/components/CategorizedProductsBrowser";
import StoreSelectionModal from "src/themes/legacy/components/StoreSelectionModal";
import DeliveryModal from "src/themes/legacy/components/DeliveryModal";
import ProductFilters from "src/themes/legacy/components/ProductFilters";
import ExpressModeSelector from "src/themes/legacy/components/ExpressModeSelector";
import {
  HybridTypeIndicator,
  IndicaTypeIndicator,
  SativaTypeIndicator,
} from "src/themes/legacy/flowerTypeIcons";

export default {
  components: {
    Modal: DefaultModal,
    CategorizedProductsBrowser,
    StoreSelectionModal,
    ExpressModeSelector,
    DeliveryModal,
    ProductFilters,
    IndicaTypeIndicator,
    SativaTypeIndicator,
    HybridTypeIndicator,
  },
};
