import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import {getSearchIconComponent} from "@ui/components/Icons";
import Input from "@ui/components/Inputs";
import Spinner from "@ui/components/Spinner";
import {CrossIcon} from "@ui/components/IconsSVGs";

export default function SearchBar({
  loading,
  search,
  searchPlaceholder,
  searchIcon,
  ClearSearchIcon,
  onFocus,
  onChangeHandler,
  onKeyUpHandler,
  onClearSearchHandler,
  onCrossClickHandler,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const SearchIcon = getSearchIconComponent(searchIcon);

  const inputEl = useRef(null);

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, [inputEl]);

  return (
    <Container styles={_styles.root}>
      <InputContainer styles={_styles.input}>
        <StyledInput
          ref={inputEl}
          placeholder={searchPlaceholder}
          value={search}
          onFocus={onFocus}
          onChange={onChangeHandler}
          onKeyUp={onKeyUpHandler}
          styles={_styles.input}
        />
        <SearchIconContainer>
          {loading && (
            <Spinner
              styles={{root: {color: _styles.input.searchIcon.color, size: "22px"}}}
            />
          )}
          {!loading && (search === "" || !ClearSearchIcon) && (
            <SearchIcon color={_styles.input.searchIcon.color} />
          )}
          {!loading && search !== "" && ClearSearchIcon && (
            <div onClick={onClearSearchHandler}>
              <ClearSearchIcon color={_styles.input.searchIcon.color} />
            </div>
          )}
        </SearchIconContainer>
      </InputContainer>
      <CloseSearch onClick={onCrossClickHandler}>
        <CrossIcon
          color={_styles.close.color}
          width={_styles.close.width}
          height={_styles.close.height}
        />
      </CloseSearch>
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "search search__search-bar search__search-bar__container",
}))`
    display: flex;
    align-items: center;
    gap: 28px;
    padding: ${({styles}) => styles.padding.lg};
    animation: grow .25s;

    @keyframes grow {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }

        ${media.down("md")} {
            padding: ${({styles}) => styles.padding.md};
        }

        ${media.down("sm")} {
            padding: ${({styles}) => styles.padding.sm};
        }
`;

const InputContainer = styled.div.attrs(() => ({
  className: "search search__search-bar search__search-bar__input-container",
}))`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledInput = styled(Input).attrs(() => ({
  className: "search search__search-bar search__search-bar__input",
}))`
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;

  padding: ${({styles}) => styles.padding.lg};
  background-color: ${({styles}) => styles.backgroundColor};
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};

  ::placeholder {
    color: ${({styles}) => styles.placeholder.color};
  }

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const SearchIconContainer = styled.div.attrs(() => ({
  className: "search search__search-bar search__search-bar__search-icon-container",
}))`
  position: relative;
  right: 40px;
  top: 3px;
`;

const CloseSearch = styled.div.attrs(() => ({
  className: "search search__search-bar search__search-bar__close-search",
}))`
  display: flex;
  cursor: pointer;
`;

const defaultStyles = {
  root: {
    padding: {
      lg: "16px 0 30px 0",
      md: "16px 0 30px 0",
      sm: "16px 0 30px 0",
    },
  },
  input: {
    backgroundColor: "#fff",
    borderRadius: 0,
    border: "1px solid #000",
    padding: {
      lg: "14px 20px",
      md: "14px 20px",
      sm: "14px 20px",
    },
    fontFamily: "sans-serif",
    fontWeight: "400",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    color: "#000",
    placeholder: {
      color: "#000",
    },
    searchIcon: {
      color: "#000",
    },
  },
  close: {
    width: "18px",
    height: "18px",
    color: "black",
  },
};

SearchBar.propTypes = {
  loading: PropTypes.bool,
  search: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  searchIcon: PropTypes.string,
  ClearSearchIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  onFocus: PropTypes.func,
  onChangeHandler: PropTypes.func,
  onKeyUpHandler: PropTypes.func,
  onClearSearchHandler: PropTypes.func,
  onCrossClickHandler: PropTypes.func,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    input: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      border: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
      placeholder: PropTypes.shape({
        color: PropTypes.string,
      }),
      searchIcon: PropTypes.shape({
        color: PropTypes.string,
      }),
    }),
    close: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
};
