import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";

export default function Tabs({tabs, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container>
      <TabsContainer styles={_styles.root}>
        {tabs.map(tab => (
          <Tab isSelected={tab.isSelected} onClick={tab.onClick} styles={_styles.tab}>
            {tab.label}
          </Tab>
        ))}
      </TabsContainer>
      <Separator styles={_styles.separator} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const TabsContainer = styled.div`
  display: flex;
  gap: ${({styles}) => styles.gap.lg};
  padding: ${({styles}) => styles.padding.lg};

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
    gap: ${({styles}) => styles.gap.md};
  }

  ${media.down("sm")} {
    justify-content: center;
    padding: ${({styles}) => styles.padding.sm};
    gap: ${({styles}) => styles.gap.sm};
  }
`;

const Tab = styled.div`
  cursor: ${({isSelected}) => (isSelected ? "default" : "pointer")};
  padding: ${({styles}) => styles.padding.lg};
  border-bottom: ${({isSelected, styles}) =>
    isSelected ? styles.border : styles.invisibleBorder};
  transition: border-bottom ease 100ms;
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-size: ${({styles}) => styles.fontSize.lg};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 44px;
    text-align: center;
    background-color: ${({isSelected, styles}) =>
      isSelected && styles.buttonSelectedBackgroundColor};
    border: ${({styles}) => styles.buttonBorder};
    border-radius: ${({styles}) => styles.buttonBorderRadius};
    color: ${({isSelected, styles}) => isSelected && styles.buttonSelectedColor};
    padding: ${({styles}) => styles.buttonPadding};
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Separator = styled.div`
  height: 1px;
  max-height: 1px;
  background-color: ${({styles}) => styles.color};

  ${media.down("sm")} {
    display: none;
  }
`;

const defaultStyles = {
  root: {
    padding: {
      lg: "0 0 0 216px",
      md: "0 0 0 216px",
      sm: 0,
    },
    gap: {
      lg: "50px",
      md: "50px",
      sm: "20px",
    },
  },
  tab: {
    padding: {
      lg: "0 0 8px",
      md: "0 0 8px",
    },
    buttonPadding: "10px 8px",
    border: "3px solid black",
    invisibleBorder: "3px solid rgba(0, 0, 0, 0)",
    buttonBorder: "1px solid black",
    buttonBorderRadius: "4px",
    buttonSelectedBackgroundColor: "#000",
    fontFamily: "sans-serif",
    fontWeight: 400,
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "14px",
    },
    color: "#000",
    buttonSelectedColor: "#000",
  },
  separator: {
    color: "#000",
  },
};

Tabs.defaultProps = {
  tabs: [],
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      isSelected: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  styles: PropTypes.shape({
    root: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    tab: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      border: PropTypes.string,
      invisibleBorder: PropTypes.string,
    }),
    separator: PropTypes.shape({
      color: PropTypes.string,
    }),
  }),
};
