import {DefaultThemeFactory} from "@themes/default/mock/hooks/useThemeComponents";
import ProductsPage from "@themes/boost/pages/ProductsPage";
import PromotionalBanner from "@themes/boost/components/PromotionalBanner";
import Title from "@themes/boost/components/Title";
import ViewAllLink from "@themes/boost/components/ViewAllLink";

export default {
  inherits: "default",
  vars: {
    SHOWCASED_PREFETCH_LIMIT: 10,
  },
  components: {
    /*ActionsSortFilter: ActionsSortFilter,
    AppliedFilters: AppliedFilters,
    BrandHeader: BrandHeader,
    Button: Button,
    CategorizedProductsBrowser: CategorizedProductsBrowser,
    CategoryBar: CategoryBar,
    DeliveryBanner: DeliveryBanner,
    Footer: Footer,
    Header: Header,
    Modal: Modal,
    ProductCard: ProductCard,
    ProductDetail: ProductDetail,
    ProductsList: ProductsList,
    RecommendedProductsList: RecommendedProductsList,
    ShowCasedProductsList: ShowCaseProductsList,
    ShowCaseSection: ShowCaseSection,*/
    PromotionalBanner: PromotionalBanner,
    // Title: Title,
    // ViewAllLink: ViewAllLink,
  },
  pages: {
    HomePage: ProductsPage,
    // ProductsPage: ProductsPage,
    // AllCategoriesPage: ProductsPage,
    // BrandPage: BrandPage,
    // ProductDetailPage: ProductsPage,
    // DealsPage: DealsPage,
  },
  options: {
    pages: {
      productDetail: {
        isModal: true,
      },
    },
  },
};

export class BoostThemeFactory extends DefaultThemeFactory {
  get ProductsPage() {
    return ProductsPage;
  }

  get PromotionalBanner() {
    return PromotionalBanner;
  }

  get Title() {
    return Title;
  }

  get ViewAllLink() {
    return ViewAllLink;
  }
}
