import styled, {keyframes} from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  display: inline-block;
  border: 2px solid #f3f3f3;
  border-top: 2px solid ${({theme}) => theme.v2.color.primary};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: ${spin} 1s linear infinite;
`;

export default Loader;
