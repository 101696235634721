import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";

//TODO: improve repeated code between customer info and payment info
export default function PaymentInfo({order, BannerComponent, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  const {paymentMethod, tip, savings, convenienceFee} = order;

  return (
    <Container styles={_styles.root}>
      <InnerContainer styles={_styles.root}>
        <Title styles={_styles.title}>Payment information</Title>
        <Info>
          {paymentMethod && (
            <Row>
              <Cell styles={_styles.info}>
                <Label styles={_styles.info.title}>Payment method</Label>
                <PaymentMethod>
                  {paymentMethod.Icon && <div>{paymentMethod.Icon}</div>}
                  <div>
                    <PaymentTitle
                      styles={_styles.paymentTitle}
                      data-cy="orderDetailPaymentInfoTitle"
                    >
                      {paymentMethod.title}
                    </PaymentTitle>
                    {paymentMethod.subtitle && (
                      <PaymentSubtitle styles={_styles.paymentSubtitle}>
                        {paymentMethod.subtitle}
                      </PaymentSubtitle>
                    )}
                    {paymentMethod.paidAt && (
                      <PaymentDate styles={_styles.paymentDate}>
                        {paymentMethod.paidAt}
                      </PaymentDate>
                    )}
                    {paymentMethod.error && (
                      <PaymentError styles={_styles.paymentError}>
                        {paymentMethod.error}
                      </PaymentError>
                    )}
                  </div>
                </PaymentMethod>
              </Cell>
              <Cell styles={_styles.info}>
                <span>&nbsp;</span>
                <span>{order.totalPrice}</span>
              </Cell>
            </Row>
          )}
          {tip && (
            <Row>
              <Cell styles={_styles.info}>
                <Label styles={_styles.info.title}>Tip</Label>
              </Cell>
              <Cell styles={_styles.info}>
                <span>{tip}</span>
              </Cell>
            </Row>
          )}
          {convenienceFee && (
            <Row>
              <Cell styles={_styles.info}>
                <Label styles={_styles.info.title}>Payment Convenience Fee</Label>
              </Cell>
              <Cell styles={_styles.info}>
                <span>{convenienceFee}</span>
              </Cell>
            </Row>
          )}
          {savings && (
            <Row>
              <Cell styles={_styles.info}>
                <Label styles={_styles.info.title}>{savings.label}</Label>
              </Cell>
              <Cell styles={_styles.info}>
                <span>{savings.displayValue}</span>
              </Cell>
            </Row>
          )}
        </Info>
        {BannerComponent && <BannerComponent maxHeight={"171px"} />}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 14px;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
`;

const InnerContainer = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__inner-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: ${({styles}) => styles.padding};
`;

const Title = styled.span.attrs(() => ({
  className: "checkout-order-info__payment-info__title",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Info = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__info",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;
`;

const Row = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__row",
  "data-keep-cart": "true",
}))`
  display: flex;
  justify-content: space-between;
  gap: 14px;
`;

const Label = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Cell = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__cell",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 6px;

  > :not(${Label}):first-child {
    font-family: ${({styles}) => styles.title.fontFamily};
    font-size: ${({styles}) => styles.title.fontSize.lg};
    font-weight: ${({styles}) => styles.title.fontWeight};
    color: ${({styles}) => styles.title.color};

    ${media.down("md")} {
      font-size: ${({styles}) => styles.title.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({styles}) => styles.title.fontSize.sm};
    }
  }

  > :not(${Label}):last-child {
    font-family: ${({styles}) => styles.text.fontFamily};
    font-size: ${({styles}) => styles.text.fontSize.lg};
    font-weight: ${({styles}) => styles.text.fontWeight};
    color: ${({styles}) => styles.text.color};

    ${media.down("md")} {
      font-size: ${({styles}) => styles.text.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({styles}) => styles.text.fontSize.sm};
    }
  }
`;

const PaymentMethod = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__payment-method",
  "data-keep-cart": "true",
}))`
  display: flex;
  gap: 8px;
`;

const PaymentTitle = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__payment-method__title",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  margin-bottom: 4px;

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const PaymentSubtitle = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__payment-method__subtitle",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  &:first-letter {
    text-transform: capitalize;
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const PaymentDate = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__payment-method__date",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  &:first-letter {
    text-transform: capitalize;
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const PaymentError = styled.div.attrs(() => ({
  className: "checkout-order-info__payment-info__payment-method__error",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  &:first-letter {
    text-transform: capitalize;
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  root: {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "14px",
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "18px",
    },
    fontWeight: 600,
    color: "#333333",
  },
  info: {
    title: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#000",
    },
    text: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#545454",
    },
  },
  paymentTitle: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#333333",
  },
  paymentSubtitle: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#545454",
  },
  paymentDate: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#545454",
  },
  paymentError: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#BA0B15",
  },
};

PaymentInfo.defaultProps = {
  order: {
    deliveryType: "",
    address: [],
    orderSpecifications: {
      type: "",
      time: "",
    },
    paymentMethod: {
      Icon: null,
      title: "",
      subtitle: "",
      paidAt: null,
    },
    tip: "",
  },
  styles: defaultStyles,
};

PaymentInfo.propTypes = {
  order: PropTypes.shape({
    deliveryType: PropTypes.string,
    address: PropTypes.arrayOf(PropTypes.string),
    orderSpecifications: PropTypes.string,
    paymentMethod: PropTypes.shape({
      Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
    tip: PropTypes.string,
  }),
  BannerComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  styles: PropTypes.shape({
    root: PropTypes.shape({}),
  }),
};
