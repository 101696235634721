import React from "react";
import {useTheme} from "styled-components";
import TymberCategoryBar from "@menu/components/TymberCategoryBar";
import styles from "src/themes/baseTheme/components/CategoryBar/styles";
import {CardCategoryButton, CategoryBar, CategoryButton, isMobile} from "@ui";
import Link from "src/core/common/components/modules/Link";
import useSite from "src/core/sites/hooks/useSite";

export default function BaseThemeCategoryBar() {
  return <TymberCategoryBar CategoryBarComponent={CategoryBarWrapper} />;
}

const CategoryBarWrapper = props => {
  const theme = useTheme();
  const site = useSite();

  const getCategoryButton = () => {
    const buttonType = site.getUiConfiguration().categoryBar?.button.type;
    if (buttonType === "card" && !isMobile()) {
      return <CardCategoryButton linkProps={{keepQuery: true}} LinkComponent={Link} />;
    }
    return <CategoryButton linkProps={{keepQuery: true}} LinkComponent={Link} />;
  };

  return (
    <CategoryBar
      {...props}
      styles={styles(theme, site, {
        buttonShadow: site.getUiConfiguration().categoryBar.button.shadow,
        overrideTheme: site.getUiConfiguration().categoryBar?.theme,
      })}
      CategoryButtonComponent={getCategoryButton()}
    />
  );
};
