import React from "react";
import {ScrollCategoryBar, ScrollCategoryBarButton} from "@ui";
import {useTheme} from "styled-components";
import styles from "src/themes/baseTheme/components/CategoryBar/styles";
import Link from "src/core/common/components/modules/Link";
import TymberCategoryBar from "@menu/components/TymberCategoryBar";
import useSite from "src/core/sites/hooks/useSite";

function SenseScrollCategoryBar() {
  return <TymberCategoryBar CategoryBarComponent={ScrollCategoryBarWrapper} />;
}

const ScrollCategoryBarWrapper = props => {
  const theme = useTheme();
  const site = useSite();
  return (
    <ScrollCategoryBar
      {...props}
      fixed={false}
      styles={styles(theme, site, {
        overrideTheme: site.getUiConfiguration().categoryBar?.theme,
      })}
      CategoryButtonComponent={props => (
        <ScrollCategoryBarButton {...props} LinkComponent={Link} />
      )}
    />
  );
};

export default SenseScrollCategoryBar;
