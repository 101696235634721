import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import Skeleton from "react-loading-skeleton";
import {ChevronDownIcon, ChevronRightIcon} from "@ui/components/Icons";

export default function CheckoutTotal({
  styles,
  checkoutTotal,
  Divider,
  DiscountIcon,
  loading,
}) {
  const _styles = merge({}, defaultStyles, styles);
  const [taxesCollapsed, setTaxesCollapsed] = useState(true); // State to manage collapsed state of taxes

  if (!Boolean(checkoutTotal)) {
    return <div />;
  }

  const {total, subtotal, discount, tax, tip, savings, convenienceFee, fee} =
    checkoutTotal;

  if (loading) {
    return (
      <div>
        <Container styles={_styles.container}>
          <Row>
            <LabelSkeleton />
            <PriceSkeleton />
          </Row>
          <Row>
            <LabelSkeleton />
            <PriceSkeleton />
          </Row>
          {(discount?.discounts || [0, 1]).map((d, index) => (
            <Row key={`discount-skeleton-${index}`} inset>
              <LabelSkeleton />
              <PriceSkeleton />
            </Row>
          ))}
          <Row>
            <LabelSkeleton />
            <PriceSkeleton />
          </Row>
          {(tax ? tax.taxes : [0, 1]).map((t, index) => (
            <Row key={`tax-skeleton-${index}`} inset>
              <LabelSkeleton />
              <PriceSkeleton />
            </Row>
          ))}
          {(fee ? fee : [0, 1]).map((f, index) => (
            <Row key={`fee-skeleton-${index}`}>
              <LabelSkeleton />
              <PriceSkeleton />
            </Row>
          ))}
        </Container>
        {Divider}
        <Row>
          <TotalLabel styles={_styles.totalLabel}>{total.label}</TotalLabel>
          <TotalValueSkeleton />
        </Row>
      </div>
    );
  }

  return (
    <div>
      <Container styles={_styles.container}>
        {subtotal && (
          <Row>
            <Label styles={_styles.label}>{subtotal.label}</Label>
            <Price styles={_styles.price}>{subtotal.value}</Price>
          </Row>
        )}
        {discount && (
          <>
            {discount.totalDiscount && (
              <Row>
                <GroupLabel styles={_styles.groupLabel}>
                  {discount.totalDiscount.label}
                </GroupLabel>
                <Price styles={_styles.price}>{discount.totalDiscount.value}</Price>
              </Row>
            )}

            {discount.discounts &&
              discount.discounts.map(d => (
                <Row key={`discount-${d.label}`} inset>
                  <Label styles={_styles.sublabel}>
                    {d.label}
                    {d.code && DiscountIcon && <DiscountIcon />}
                    {d.code && (
                      <DiscountCode styles={_styles.discountCode}>{d.code}</DiscountCode>
                    )}
                  </Label>
                  <Price styles={_styles.price}>{d.value}</Price>
                </Row>
              ))}
          </>
        )}

        {tax && (
          <>
            {tax.totalTaxes && (
              <Row>
                <GroupLabel
                  onClick={() => setTaxesCollapsed(!taxesCollapsed)}
                  styles={_styles.groupLabel}
                >
                  {taxesCollapsed ? (
                    <ChevronRightIcon
                      size={"12px"}
                      style={{padding: "0px 5px 0px 0px"}}
                    />
                  ) : (
                    <ChevronDownIcon size={"12px"} style={{padding: "0px 5px 0px 0px"}} />
                  )}
                  {tax.totalTaxes.label}
                </GroupLabel>
                <Price styles={_styles.price}>{tax.totalTaxes.value}</Price>
              </Row>
            )}

            {!taxesCollapsed && (
              <>
                {tax.taxes &&
                  tax.taxes.map(t => (
                    <Row style={{paddingLeft: "20px"}} key={`tax-${t.label}`} inset>
                      <Label styles={_styles.sublabel}>{t.label}</Label>
                      <Price styles={_styles.price}>{t.value}</Price>
                    </Row>
                  ))}
              </>
            )}
          </>
        )}

        {fee &&
          fee.map(f => (
            <Row key={`fee-${f.label}`}>
              <Label styles={_styles.sublabel}>{f.label}</Label>
              <Price styles={_styles.price}>{f.value}</Price>
            </Row>
          ))}
      </Container>
      {Divider}
      {total && (
        <Row>
          <TotalLabel styles={_styles.totalLabel}>{total.label}</TotalLabel>
          <TotalValue styles={_styles.totalValue}>
            <TotalCurrency styles={_styles.totalCurrency}>{total.currency}</TotalCurrency>{" "}
            {total.value}
          </TotalValue>
        </Row>
      )}

      {tip && <AdditionalPrice styles={_styles} {...tip} />}
      {savings && <AdditionalPrice styles={_styles} {...savings} />}
      {convenienceFee && <AdditionalPrice styles={_styles} {...convenienceFee} />}
    </div>
  );
}

const AdditionalPrice = ({styles, type = "cost", label, value}) => {
  const prefixes = {
    savings: "-",
    cost: "+",
  };

  return (
    <Row style={{marginTop: 8}}>
      <Label styles={styles.label}>{label}</Label>
      <Price styles={styles.price}>
        {prefixes[type] || ""}
        {value}
      </Price>
    </Row>
  );
};

const Container = styled.div.attrs(() => ({
  className: "checkout-total",
}))`
  display: flex;
  flex-direction: column;
  gap: ${({styles}) => styles.gap};
`;

const Row = styled.div.attrs(() => ({
  className: "checkout-total__row",
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  padding-left: 0;
`;

const Label = styled.div.attrs(() => ({
  className: "checkout-total__label",
}))`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 5px;
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const GroupLabel = styled.div.attrs(() => ({
  className: "checkout-total__group-label",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};
  cursor: pointer; /* Adding pointer cursor */

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const DiscountCode = styled.div.attrs(() => ({
  className: "checkout-total__discount-code",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};
  letter-spacing: ${({styles}) => styles.letterSpacing};
  text-transform: ${({styles}) => styles.textTransform};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Price = styled.div.attrs(() => ({
  className: "checkout-total__price",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const TotalLabel = styled.div.attrs(() => ({
  className: "checkout-total__total-label",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const TotalCurrency = styled.span.attrs(() => ({
  className: "checkout-total__total-currency",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};
  text-transform: uppercase;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const TotalValue = styled.div.attrs(() => ({
  className: "checkout-total__total-value",
}))`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};
  letter-spacing: ${({styles}) => styles.letterSpacing};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const LabelSkeleton = styled(Skeleton)`
  ${media.up("lg")} {
    width: 140px;
    height: 20px;
  }
  ${media.between("md", "lg")} {
    width: 140px;
    height: 20px;
  }
  ${media.down("sm")} {
    width: 100px;
    height: 20px;
  }
`;

const PriceSkeleton = styled(Skeleton)`
  ${media.up("lg")} {
    width: 60px;
    height: 20px;
  }
  ${media.between("md", "lg")} {
    width: 60px;
    height: 20px;
  }
  ${media.down("sm")} {
    width: 60px;
    height: 20px;
  }
`;

const TotalValueSkeleton = styled(Skeleton)`
  width: 110px;
  height: 40px;
`;

// This component only shows up on small devices, so the values should use the SM tokens.
const defaultStyles = {
  container: {
    gap: "12px",
  },
  label: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#545454",
    lineHeight: "140%",
  },
  sublabel: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#545454",
    lineHeight: "140%",
  },
  groupLabel: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#333333",
    lineHeight: "140%",
  },
  discountCode: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#545454",
    lineHeight: "150%",
    letterSpacing: "-0.2px",
    textTransform: "uppercase",
  },
  price: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#333333",
    lineHeight: "140%",
  },
  totalLabel: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "16px",
      md: "16px",
      lg: "16px",
    },
    fontWeight: "500",
    fontStyle: "normal",
    color: "#333333",
    lineHeight: "140%",
  },
  totalCurrency: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#737373",
    lineHeight: "140%",
  },
  totalValue: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "24px",
      md: "24px",
      lg: "24px",
    },
    fontWeight: "500",
    fontStyle: "normal",
    color: "#333333",
    lineHeight: "150%",
    letterSpacing: "-0.2px",
  },
};

CheckoutTotal.defaultProps = {};

CheckoutTotal.propTypes = {
  loading: PropTypes.bool,
  checkoutTotal: PropTypes.shape({
    total: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      currency: PropTypes.string,
    }),
    tip: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    subtotal: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    discount: PropTypes.shape({
      totalDiscount: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      discounts: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          code: PropTypes.string,
        })
      ),
    }),
    tax: PropTypes.shape({
      totalTaxes: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      taxes: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }),
    fee: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  Divider: PropTypes.any,
  DiscountIcon: PropTypes.any,
  styles: PropTypes.shape({
    container: PropTypes.shape({
      gap: PropTypes.string,
    }),
    label: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.string,
    }),
    sublabel: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.string,
    }),
    groupLabel: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.string,
    }),
    discountCode: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.string,
      letterSpacing: PropTypes.string,
      textTransform: PropTypes.string,
    }),
    price: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.string,
    }),
    totalLabel: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.string,
    }),
    totalCurrency: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.string,
    }),
    totalValue: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.string,
      letterSpacing: PropTypes.string,
    }),
  }),
};
