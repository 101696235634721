import {TopNavigationBar} from "@ui";
import React from "react";
import {useTheme} from "styled-components";
import styles, {
  cartButtonBadgeStyles,
  cartButtonStyles,
  loginButtonStyles,
  searchButtonStyles,
} from "src/themes/baseTheme/components/Header/styles";
import DetailedSearchInputSection from "src/themes/baseTheme/elements/SearchInputSection/DetailedSearchInputSection";
import useHeader from "src/core/common/hooks/useHeader";
import Link from "src/core/common/components/modules/Link";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import merge from "lodash/merge";
import get from "lodash/get";
import useSite from "src/core/sites/hooks/useSite";
import {SEARCH_COMPONENT_DISPLAY} from "@ui/components/TopNavigationBar/TopNavigationBar";

function TheColorFulHeader() {
  const site = useSite();
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.appBar"))};
  const toggles = useFeatureToggles();
  const {
    logoUrl,
    logoLinkUrl,
    largeScreenOptions,
    sideMenu,
    hideMenuButton,
    onClickMenuButton,
    loginButtonProps,
    cartButtonProps,
  } = useHeader();

  return (
    <>
      <TopNavigationBar
        logoUrl={logoUrl}
        mobileLogoUrl={logoUrl}
        LinkComponent={Link}
        logoLinkUrl={logoLinkUrl}
        hideMenuButton={hideMenuButton}
        hideMenuButtonOnLargerViewport={false}
        styles={styles(theme, site)}
        hideLoginButton={toggles.isKiosk()}
        largeScreenOptions={largeScreenOptions}
        largeScreenOptionsAlignment={"right"}
        logoPosition={"left"}
        searchComponentDisplay={SEARCH_COMPONENT_DISPLAY.full}
        SearchComponent={DetailedSearchInputSection}
        onClickMenuButton={onClickMenuButton}
        cartButtonProps={{
          ...cartButtonProps,
          highlight: site.getUiConfiguration().header.highlight,
          highlightIfCartHasProducts:
            site.getUiConfiguration().header.highlightIfCartHasProducts,
          hideIconInLargerViewport:
            site.getUiConfiguration().header.hideIconInLargerViewport,
          cartIcon: site.getUiConfiguration().header.cartIcon,
          cartText: site.getUiConfiguration().header.cartText,
          styles: cartButtonStyles(theme, site),
          badgeStyles: site.getUiConfiguration().header.hideIconInLargerViewport
            ? cartButtonBadgeStyles(theme, site)
            : {},
        }}
        searchButtonProps={{
          outline: site.getUiConfiguration().search.outline,
          highlight: true,
          styles: searchButtonStyles(theme, site),
        }}
        loginButtonProps={{
          ...loginButtonProps,
          outline: site.getUiConfiguration().login.outline,
          styles: loginButtonStyles(theme, site),
        }}
      />
      {sideMenu}
    </>
  );
}

export default TheColorFulHeader;
