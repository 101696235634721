import {isClient} from "src/server/utils/isClient";

class LocalStorage {
  constructor() {
    this.fallbackStorage = new MemoryStorage();
    this.localStorage = this.fallbackStorage;
    if (isClient) {
      this.localStorage = localStorage || this.fallbackStorage;
    }
  }
  getItem(key) {
    try {
      return this.localStorage.getItem(key);
    } catch (e) {
      return this.fallbackStorage.getItem(key);
    }
  }

  setItem(key, value) {
    try {
      return this.localStorage.setItem(key, value);
    } catch (e) {
      return this.fallbackStorage.setItem(key, value);
    }
  }

  removeItem(key) {
    try {
      return this.localStorage.removeItem(key);
    } catch (e) {
      return this.fallbackStorage.removeItem(key);
    }
  }
}

class MemoryStorage {
  constructor() {
    this.map = {};
  }

  getItem(key) {
    return this.map[key];
  }

  setItem(key, value) {
    this.map[key] = value;
  }

  removeItem(key) {
    this.map[key] = undefined;
    delete this.map[key];
  }
}

export default new LocalStorage();
