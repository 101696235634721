import {isClient} from "src/server/utils/isClient";
import {SiteEnv} from "src/core/common/models/siteEnv";
import makeSiteFromSiteData from "src/apps/common/makeSiteFromSiteData";
import getTymberApp from "src/apps/common/getTymberApp";
import {storeSwitcher} from "src/core/common/services/storeSwitcher";

export let sitesCache = {};
export function initializeSiteCache({identifier, initialSite, siteGroupName, kioskId}) {
  if (!sitesCache[identifier] || !isClient) {
    let env;
    if (kioskId)
      env = new SiteEnv({...initialSite.env, REACT_APP_TYMBER_KIOSK_ID: kioskId});
    else env = storeSwitcher.applyLastStore(new SiteEnv(initialSite.env));

    if (initialSite.site) {
      sitesCache[identifier] = makeSiteFromSiteData(initialSite, {siteGroupName, env});
      const App = getTymberApp(identifier);
      App.init(sitesCache[identifier].env);
    } else {
      sitesCache[identifier] = {env};
    }
  }
}
