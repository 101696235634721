import merge from "lodash/merge";

const DEFAULT_FLOWER_TYPE_INDICATOR_COLORS = {
  INDICA_COLOR: "#575990",
  SATIVA_COLOR: "#FD6F71",
  HYBRID_COLOR: "#5EC291",
};

const {INDICA_COLOR, SATIVA_COLOR, HYBRID_COLOR} = DEFAULT_FLOWER_TYPE_INDICATOR_COLORS;

export const getFlowerBackgroundColor = flowerType => {
  switch (flowerType) {
    case "Indica Dominant":
    case "Indica":
      return INDICA_COLOR;
    case "Sativa Dominant":
    case "Sativa":
      return SATIVA_COLOR;
    case "Hybrid":
      return HYBRID_COLOR;
    default:
      return;
  }
};

export const getFlowerIconColors = flowerType => {
  switch (flowerType) {
    case "Indica Dominant":
    case "Indica":
      return {
        outerBorderColor: INDICA_COLOR,
        outerBgColor: "#FFF",
        innerBgColor: INDICA_COLOR,
      };
    case "Sativa Dominant":
    case "Sativa":
      return {
        outerBorderColor: SATIVA_COLOR,
        outerBgColor: "#FFF",
        innerBgColor: SATIVA_COLOR,
      };
    case "Hybrid":
      return {
        outerBorderColor: HYBRID_COLOR,
        outerBgColor: "#FFF",
        innerBgColor: HYBRID_COLOR,
      };
    default:
      return;
  }
};

export function getFlowerTypeIndicatorProps(uiConfiguration) {
  const flowerTypeIndicatorColors = merge(
    {},
    DEFAULT_FLOWER_TYPE_INDICATOR_COLORS,
    uiConfiguration?.flowerTypeIndicator
  );
  return {
    getBackground: () => "#FFF",
    getColor: () => flowerTypeIndicatorColors.contrastColor,
    getIconColors: flowerType =>
      getFlowerIconColors(flowerType, flowerTypeIndicatorColors),
    displayAbbreviation: flowerTypeIndicatorColors.displayAbbreviation,
  };
}
