import {useCallback, useEffect, useState} from "react";
import {eventBus, refresh} from "../services/refresher";

export function useRefreshHook() {
  const [refreshState, setRefreshState] = useState(1);
  useEffect(() => {
    return eventBus.subscribe(() => {
      setRefreshState(Math.random());
    });
  }, []);

  return [refreshState];
}

export function useRefresh() {
  return useCallback(() => {
    refresh();
  }, []);
}
